<template>
          <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
  
            <!-- end:: Header -->
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor white-background"
              style="
                background: #0000 !important;
                justify-content: center;
                margin-top: 80px!important;
              ">
              <div style="flex: none" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
                <div class="loading" id="loading">Loading&#8230;</div>
                <div class="Scenary">
                  <div class="Conference" id="mainCont">
                    <div class="Screen" id="Screen" style="align-items: center">
                      <video v-if="mainScreenStreamRef != null && mainScreenStreamRef.screenShare
                        " :id="mainScreenStreamRef.storageId + '_SHARE_SCREEN_VIDEO'" muted autoPlay playsInline
                        width="640" height="480" :srcObject.prop="mainScreenStreamRef != null && mainScreenStreamRef.mediaStream
                          "></video>
  
                      <video v-if="mainScreenStreamRef != null &&
                        !mainScreenStreamRef.screenShare &&
                        !mainScreenStreamRef.mutedVideo
                        " id="ID_OF_YOUR_VIDEO_ELEMENT" muted autoPlay playsInline :srcObject.prop="mainScreenStreamRef != null && mainScreenStreamRef.mediaStream
      "></video>
  
                      <div class="zoom-actions" style="display: block" v-if="mainScreenStreamRef != null">
                        <button class="btn-mute" @click="clickToMiniMiseStream(mainScreenStreamRef)">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            stroke="#e86969">
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />
  
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#c9c0c0"
                              stroke-width="4.8">
                              <path d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path opacity="0.5"
                                d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                stroke="#808080" stroke-width="1.512" />
                            </g>
  
                            <g id="SVGRepo_iconCarrier">
                              <path d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17" stroke="#808080" stroke-width="1.512"
                                stroke-linecap="round" stroke-linejoin="round" />
                              <path opacity="0.5"
                                d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                stroke="#808080" stroke-width="1.512" />
                            </g>
                          </svg>
                        </button>
                      </div>
  
                      <div v-if="mainScreenStreamRef != null &&
                        !mainScreenStreamRef.screenShare &&
                        mainScreenStreamRef.socketId == socket.id
                        ? currentMicState
                        : mainScreenStreamRef != null &&
                        !mainScreenStreamRef.screenShare &&
                        !audioMicClose.includes(mainScreenStreamRef.socketId)
                        " style="position: absolute; top: '16%'; right: '3%'" :id="mainScreenStreamRef.socketId"
                        class="audio-icon-cont">
                        <span class="audio-icon" />
                      </div>
  
  
                      <div class="vertical-center" v-if="mainScreenStreamRef != null &&
                        !mainScreenStreamRef.screenShare &&
                        mainScreenStreamRef.mutedVideo
                        ">
                        <img :src="BASE_URL + mainScreenStreamRef.userDetails.profileImg"
                          class="r-img rounded participants-img-medium" />
                      </div>
                      <a class="name-tag d-flex" :id="mainScreenStreamRef.screenShare
                        ? ''
                        : mainScreenStreamRef.socketId + 'name-tag'
                        " v-if="mainScreenStreamRef != null">
                        <a :id="mainScreenStreamRef.socketId + '_USER_MAIN_NAME'" style="padding-top: 3px">{{
                          mainScreenStreamRef.userDetails
                          ? mainScreenStreamRef.userDetails.user_name
                          : ""
                        }}</a>
                        <a>{{
                          mainScreenStreamRef.screenShare ? " (presentation)" : ""
                        }}</a>
                        <img @click="
                          role == 'HOST' || socket.id == mainScreenStreamRef.socketId
                            ? hideRaiseHand(mainScreenStreamRef.socketId)
                            : ''
                          " v-if="mainScreenStreamRef.raiseHand" :id="mainScreenStreamRef.socketId + '_BIG_SCREEN_RAISE'"
                          class="wave" src="../../assets/media/raised-hand.png" height="25" width="25" />
                      </a>
  
                      <div>
                        <button v-if="role != 'VIEWER'" type="button" style="z-index: 999999999999999"
                          class="submenu_btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <path
                              d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                          </svg>
                        </button>
  
                        <div class="dropdown-menu">
                          <ul class="submenu" v-if="mainScreenStreamRef != null">
                            <li v-if="!mainScreenStreamRef.screenShare &&
                              (role == 'HOST' || role == 'MODERATOR') &&
                              !audioMicClose.includes(mainScreenStreamRef.socketId) &&
                              mainScreenStreamRef.socketId != socket.id
                              " :id="mainScreenStreamRef.socketId + '_AUDIO_MAIN_MUTE_ACTIONs'
      " class="dropdown-item hover-item" @click="muteMic(mainScreenStreamRef.socketId)">
                              Mute Mic
                            </li>
                            <li v-if="!mainScreenStreamRef.screenShare &&
                              (role == 'HOST' || role == 'MODERATOR') &&
                              !mainScreenStreamRef.mutedVideo &&
                              mainScreenStreamRef.socketId != socket.id &&
                              !mainScreenStreamRef.mutedVideo
                              " class="dropdown-item hover-item" :id="mainScreenStreamRef.socketId + '_VIDEO_MAIN_UNMUTE_ACTIONs'
      " @click="muteVideo(mainScreenStreamRef.socketId)">
                              Mute Camera
                            </li>
                            <li v-if="!mainScreenStreamRef.screenShare &&
                              (role == 'HOST' || role == 'MODERATOR') &&
                              mainScreenStreamRef.socketId != socket.id
                              " class="dropdown-item hover-item" @click="removeUser(mainScreenStreamRef.socketId)">
                              Remove User
                            </li>
                            <li class="dropdown-item hover-item" @click="
                              renameUser(
                                mainScreenStreamRef,
                                mainScreenStreamRef.socketId
                              )
                              ">
                              Rename User
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="Dish" id="ALL_VIDEOS">
                      <div :id="peer.storageId + '_STREAsM_VIDEO_DIV'" style="background-color: black"
                        v-for="peer in peerShow">
                        <div class="lds-ellipsis" v-if="is_stream">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
  
                        <a class="stream" v-if="!is_stream" :id="peer.storageId + '_STREsAM_VIDEO_POSITION'" :style="peer.mutedVideo == false
                          ? 'display: flex; justify-content: center;position: relative;width: inherit;'
                          : 'display: flex; justify-content: center;width: 100%;'
                          ">
                          <div v-if="!peer.screenShare && peer.socketId == socket.id
                            ? currentMicState
                            : !peer.screenShare &&
                            !audioMicClose.includes(peer.socketId)
                            " style="position: absolute; top: '16%'; right: '3%'" :id="peer.socketId"
                            class="audio-icon-cont">
                            <span class="audio-icon" />
                          </div>
  
                          <video v-if="!peer.screenShare && !peer.mutedVideo" :id="peer.socketId + 'STREAM_SRC'" muted
                            autoPlay playsInline :srcObject.prop="peer.mediaStream"></video>
  
                          <video :id="peer.socketId + 'STREAM_SCR_SRC'" v-if="peer.screenShare" style="visibility: visible"
                            muted autoPlay playsInline :srcObject.prop="peer.mediaStream"></video>
  
                          <div class="vertical-center" v-if="!peer.screenShare && peer.mutedVideo"
                            :id="peer.socketId + '_PEER_IMAGE'">
                            <img :src="BASE_URL + peer.userDetails.profileImg"
                              class="r-img rounded participants-img-medium" />
                          </div>
  
                          <div class="zoom-actions">
                            <button class="btn-mute" @click="clickToBigStream(peer)">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0" />
  
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
  
                                <g id="SVGRepo_iconCarrier">
                                  <path d="M17 7H14M17 7V10M17 7L13.5 10.5M7 17H10M7 17V14M7 17L10.5 13.5" stroke="#808080"
                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M7 7H10M7 7V10M7 7L10.5 10.5M17 17H14M17 17V14M17 17L13.5 13.5" stroke="#808080"
                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path
                                    d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                    stroke="#808080" stroke-width="1.5" />
                                </g>
                              </svg>
                            </button>
                          </div>
  
                          <a class="name-tag" :id="peer.socketId + 'peer-name-tag'"
                            v-if="!peer.screenShare && peer.userDetails.user_name">
                            <img src="../assets/media/verify.png" v-if="socket.id == peer.socketId" alt="" width="15"
                              height="15" style="margin-right: 6px" />
                            <p :id="peer.socketId + '_USER_NAME'" style="margin: auto">
                              {{ peer.userDetails.user_name }}
                            </p>
                            <img v-if="peer.raiseHand" class="wave" @click="
                              role == 'HOST' || socket.id == peer.socketId
                                ? hideRaiseHand(peer.socketId)
                                : ''
                              " src="../../assets/media/raised-hand.png" height="25" width="25" />
                          </a>
  
                          <a class="name-tag" :id="peer.socketId + '_SCREEN_USER_NAME'"
                            v-if="peer.screenShare && peer.userDetails.user_name">
                            {{ peer.userDetails.user_name }} (presentation)
                          </a>
  
  
                          <div v-if="!peer.screenShare" class="participant-actions" id="participant-actions">
                            <button @click="
                              role == 'GUEST' && peer.socketId == socket.id
                                ? muteMic(peer.socketId, true, 'mute')
                                : role == 'HOST'
                                  ? muteMic(peer.socketId, true, 'mute')
                                  : role == 'MODERATOR' &&
                                    peer.userDetails.webinarUserType != 'HOST'
                                    ? muteMic(peer.socketId, true, 'mute')
                                    : ''
                              " id="MIC" class="btn-mute" v-if="peer.socketId == socket.id
      ? currentMicState
      : !audioMicClose.includes(peer.socketId)
      ">
                              <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                class="css-i6dzq1">
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            </button>
                            <button @click="
                              role == 'GUEST' && peer.socketId == socket.id
                                ? muteMic(peer.socketId, true, 'unmute')
                                : role == 'HOST'
                                  ? muteMic(peer.socketId, true, 'unmute')
                                  : role == 'MODERATOR' &&
                                    peer.userDetails.webinarUserType != 'HOST'
                                    ? muteMic(peer.socketId, true, 'unmute')
                                    : ''
                              " id="SLASH_MIC" class="btn-mute" v-if="peer.socketId == socket.id
      ? !currentMicState
      : audioMicClose.includes(peer.socketId)
      ">
                              <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                class="css-i6dzq1">
                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path>
                                <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            </button>
  
                            <button @click="
                              role == 'GUEST' && peer.socketId == socket.id
                                ? muteVideo(peer.socketId, true, 'mute')
                                : role == 'HOST'
                                  ? muteVideo(peer.socketId, true, 'mute')
                                  : role == 'MODERATOR' &&
                                    peer.userDetails.webinarUserType != 'HOST'
                                    ? muteVideo(peer.socketId, true, 'mute')
                                    : ''
                              " :id="peer.socketId + 'CAM'" v-if="peer.socketId == socket.id ? video : !peer.mutedVideo"
                              class="btn-camera">
                              <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                class="css-i6dzq1">
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                              </svg>
                            </button>
  
                            <button @click="
                              role == 'GUEST' && peer.socketId == socket.id
                                ? muteVideo(peer.socketId, true, 'unmute')
                                : role == 'HOST'
                                  ? muteVideo(peer.socketId, true, 'unmute')
                                  : role == 'MODERATOR' &&
                                    peer.userDetails.webinarUserType != 'HOST'
                                    ? muteVideo(peer.socketId, true, 'unmute')
                                    : ''
                              " :id="peer.socketId + 'SLASH_CAM'"
                              v-if="peer.socketId == socket.id ? !video : peer.mutedVideo" class="btn-camera">
                              <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                class="css-i6dzq1">
                                <path
                                  d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10">
                                </path>
                                <line x1="1" y1="1" x2="23" y2="23"></line>
                              </svg>
                            </button>
                          </div>
  
                          <div>
                            <button v-if="role != 'VIEWER'" type="button" class="submenu_btn dropdown-toggle"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                  d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                              </svg>
                            </button>
  
                            <div class="dropdown-menu">
                              <ul class="submenu">
                                <!-- <li>rename</li> -->
                                <li v-if="!peer.screenShare &&
                                  (role == 'HOST' || role == 'MODERATOR') &&
                                  !audioMicClose.includes(peer.socketId) &&
                                  socket.id != peer.socketId
                                  " class="dropdown-item hover-item" @click="muteMic(peer.socketId)">
                                  Mute Mic
                                </li>
                                <li v-if="!peer.screenShare &&
                                  (role == 'HOST' || role == 'MODERATOR') &&
                                  socket.id != peer.socketId &&
                                  !peer.mutedVideo
                                  " class="dropdown-item hover-item" @click="muteVideo(peer.socketId)">
                                  Mute Camera
                                </li>
                                <li v-if="!peer.screenShare &&
                                  (role == 'HOST' || role == 'MODERATOR') &&
                                  socket.id != peer.socketId
                                  " class="dropdown-item hover-item" @click="removeUser(peer.socketId)">
                                  Remove User
                                </li>
  
                                <li class="dropdown-item hover-item" @click="renameUser(peer.userDetails, peer.socketId)">
                                  Rename User
                                </li>
                              </ul>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="panel-body col-md-7" id="container-screens"></div> -->
              </div>
            </div>
    </div>
  </template>
  
  <script>
  window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted,
      perf = window.performance,
      perfEntries = perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
      perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
      navigationType = perf && perf.navigation && perf.navigation.type;
    if (historyTraversal || perfEntryType === "back_forward" || navigationType === 2) {
      // Handle page restore.
      window.location.reload(true);
    }
  });
  window.addEventListener(
    "load",
    function () {
      // select parent of dish
    },
    false
  );
  
  var _ratios = ["4:3"];
  var _aspect = 0;
  var _margin = 5;
  var _ratio = ratio();
  
  // resize event of window
  window.addEventListener("resize", function () {
    // resize event to dimension cameras
    resize();
  });
  
  // split aspect ratio (format n:n)
  
  function resize() {
    dimensions();
  
    // loop (i recommend you optimize this)
    let max = 0;
    let i = 1;
    while (i < 5000) {
      var area = areas(i);
      if (area === false) {
        max = i - 1;
        break;
      }
      i++;
    }
  
    // remove margins
    max = max - _margin * 1;
  
    // set dimensions to all cameras
    resizer(max);
  }
  
  function resizer(width) {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    if (_dish) {
      for (var s = 0; s < _dish.children.length; s++) {
        // camera fron dish (div without class)
        const element = _dish.children[s];
        const pseudoElementStyle = getComputedStyle(element, "display");
  
        // console.log('pseudoElementStyle___', pseudoElementStyle.display);
        if (pseudoElementStyle.display == "flex") {
          // custom margin
          element.style.margin = _margin + "px";
  
          // calculate dimensions
          element.style.width = width + "px";
          element.style.height = width * _ratio + "px";
  
          // to show the aspect ratio in demo (optional)
          element.setAttribute("data-aspect", _ratios[_aspect]);
        }
      }
    }
  }
  
  function ratio() {
    var ratio = _ratios[_aspect].split(":");
    return ratio[1] / ratio[0];
  }
  
  function dimensions() {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    //   let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
    //   let _height = _dish.offsetHeight - _margin * 2;
    // }
  }
  
  function areas(increment) {
    var _dish = document.getElementsByClassName("Dish")[0];
  
    if (_dish) {
      let i = 0;
      let w = 0;
      let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
      let _height = _dish ? _dish.offsetHeight : 0 - _margin * 2;
      let h = increment * _ratio + _margin * 2;
      while (i < _dish.children.length) {
        if (w + increment > _width - _margin * 2) {
          w = 0;
          h = h + increment * _ratio + _margin * 2;
        }
        w = w + increment + _margin * 2;
        i++;
      }
      if (h > _height - _margin * 2 || increment > _width - _margin * 2) return false;
      else return increment;
    }
  }
  
  import io from "socket.io-client";
  import { Device, RtpCapabilities, detectDevice } from "mediasoup-client";
  import { ref, reactive } from "vue";
  import axios from "axios";
  import { verifywebinars } from "../services/service";
  import {
    liveWebinar,
    webinarParticipateUserList,
    getSingleWebinarData,
    listupdateParticipants,
    verifyOpenRoomWebinars,
  } from "../services/service";
  import { createPopup } from "@picmo/popup-picker";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  import MultiStreamsMixer from "multistreamsmixer";
  import { useToast } from "vue-toast-notification";
  import "vue-toast-notification/dist/theme-sugar.css";
  import StreamSaver from "streamsaver";
  import { v4 as uuidv4 } from "uuid";
  import fixWebmDuration from 'webm-duration-fix';
  export default {
    data() {
      let urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has("webinarid")) {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        Vue.nextTick(() => {
          document.title =
            "UW - " + companyname + " " + window.location.pathname.split("/").pop();
        });
      } else {
        Vue.nextTick(() => {
          document.title = "UW - Unity Webinar";
        });
      }
      return {
        dragging: false,
        enabled: true,
        requestUserName: "",
        webinarId: "",
        OV: undefined,
        session: undefined,
        sessionScreen: undefined,
        screensharing: false,
        is_mic_allow: false,
        is_cam_allow: false,
        running_error: "",
        ism_w_c: "",
        audioDeviceData: false,
        name_error: "",
        edit_name_error: "",
        edit_join_btn: false,
        videoDeviceData: false,
        is_disable: false,
        publisherScreen: undefined,
        mainStreamManager: undefined,
        publisher: undefined,
        disabled: true,
        microphoneState: false,
        cameraState: "",
        childId: "",
        subscribers: [],
        stremErr: "",
        mySessionId: "SessionD",
        layoutClass: "single_user_main",
        deviceData: [],
        video: false,
        audio: false,
        join_loader: false,
        join_btn: false,
        edit_socketId: "",
        socketId: "",
        edit_name: "",
        your_name: "",
        profileImg: "default.jpg",
        is_stream: true,
        is_message_loader: false,
        getDevicesOV: "",
        searchMessage: "",
        goLiveStatus: "",
        role: "",
        filterdParticipants: [],
        chatHistory: [],
        chatFilteredUserList: [],
        selectedAudio: undefined,
        selectedOuterAudio: undefined,
        screenShareLoader: false,
        recordingToaster: false,
        startTimer: "",
        selectedVideo: undefined,
        users: {
          host: [{ name: "xyz", image: "imagepath" }],
          moderator: [],
          viewers: [],
          guest: [],
        },
        isRaiseHand: false,
        chatMessage: "",
        room_type: "",
        localStreamRef: "",
        loggedUserData: "",
        user_name: "",
        audioProducer: ref(),
        localVideoCont: "",
        micElem: ref(),
        videoElem: ref(),
        produceTransport: ref(),
        videoProducer: ref(),
        socket: null,
        BASE_URL:
        window.location.host == "unitywebinar.com"  ? "https://api.unitywebinar.com/"  : window.location.host == "demo.unitywebinar.com"  ? "https://demoapi.unitywebinar.com/"  : "https://dev-api.unitywebinar.com/",
        device: null,
        roomId: window.location.pathname.split("/").pop(),
        producerTransport: null,
        viewers: [],
        peersRef: ref([]),
        audioPeersRef: ref([]),
        globalTimeout: ref(),
        guestUsers: [],
        peers: [],
        peerShow: [],
        consumers: ref(new Map()),
        receiveTransport: ref([]),
        audioMicClose: ref([]),
        audioElementSocket: ref([]),
        audioLevel: 5,
        audioElement: ref([]),
        remoteStreamsRef: ref({}),
        localScreenStreamRef: ref(),
        mainScreenStreamRef: ref(null),
        localScreenCont: ref(),
        localStream: null,
        videoProducerId2: ref(),
        videoProducerId: ref(),
        videoProducer2: ref(),
        audioProducer2: ref(),
        audioProducerId2: ref(),
        audioProducerId: ref(),
        mediasoupDevice: ref(),
        is_cam_request: false,
        recorderUsers: '',
        topRecording: false,
        currentVideoState: ref(false),
        screenShareStarted: ref(false),
        currentMicState: ref(false),
        mixer: ref(),
        recStream: ref(),
        mainCont: ref(),
        workerRef: ref(),
        audioContextRef: ref(),
        mediaRecorder: ref(),
        processingRef: ref(false),
        recordingRef: ref(),
        recordingToaster: ref(),
        recordingPauseElem: ref(),
        recordingResumeElem: ref(),
        recordingTextElem: ref(),
        dataProducer: ref(),
        dataProducerId: ref(),
        chunksRef: ref([]),
        sepChunks: ref([]),
        writer: ref(),
        savedChunks: ref([]),
        streamsArr: [],
        recordingIcon: ref(),
        isMobile: false,
        isMobileDevice: false,
        isBrave: false,
        dest: ref(),
        sourceNodes: ref(new Map()),
        hrs: "",
        min: "",
        sec: "",
        recordIds: ref([]),
        recordAudioProducer: ref(),
        recordVideoProducer: ref(),
      };
    },
    computed: {
      chatFilteredList() {
        if (this.searchMessage == "") {
          return this.chatHistory;
        } else {
          this.chatFilteredUserList = this.chatHistory.filter((name) => {
            return name.user.toLowerCase().includes(this.searchMessage.toLowerCase());
          });
          return this.chatFilteredUserList;
        }
      },
    },
    mounted: async function () {
      this.$watch("peersRef", () => {
        // alert('peers watch ')
        // if (!this.peers || this.peers.length === 0) return;
  
        Object.keys(this.remoteStreamsRef).forEach((key) => {
          console.log("this.remoteStreamsRef[key]------------", this.remoteStreamsRef[key]);
  
          if (!this.remoteStreamsRef[key].srcObject) {
            const source = this.peersRef.find(
              (peer) => peer?.storageId === key?.split("_")[0]
            )?.mediaStream;
            this.$refs.remoteStreamsRef[key].srcObject = source;
          }
        });
  
        resize();
      });
  
      window.onunload = () => {
        // writableStream.abort()
        // also possible to call abort on the writer you got from `getWriter()`
        this.writer.abort();
      };
  
      let urlParams = new URLSearchParams(window.location.search);
      this.webinarId = urlParams.get("webinarid");
      this.mySessionId = urlParams.get("webinarid");
      let email = urlParams.get("email");
      let content = urlParams.get("content");
      this.user_name = urlParams.get("user_name");
      this.childId = urlParams.get("childId");
      let body = {

        webinarId: this.webinarId,
        childId: this.childId ?? 0,
      };
      if (urlParams.has("webinarid")) {
          this.room_type = "JOIN_ROOM";
          console.log("this.webinarId = " + this.webinarId);
          this.role = "VIEWER";
          this.user_name = this.webinarId;
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
          this.loggedUserData = {
                ...userdetailsData,
                profileImg: "default.jpg",
              };
          this.profileImg = "default.jpg";
  
          this.joinMeeting();
 
      } else {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
        let urlParams = new URLSearchParams(window.location.search);
        var payLoad = {
          companyName: companyname,
          userId: localStorage.getItem("userdetails")
            ? JSON.parse(localStorage.getItem("userdetails"))._id
            : "users_",
          roomName: window.location.pathname.split("/").pop(),
        };
        const verifyOpenRoom = await verifyOpenRoomWebinars(payLoad);
  
        this.room_type = "OPEN_ROOM";
          console.log("this.webinarId = " + this.webinarId);
          this.role = "VIEWER";
          this.user_name = this.webinarId;
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
          this.loggedUserData = {
                ...userdetailsData,
                profileImg: "default.jpg",
              };
              this.profileImg = "default.jpg";
  
          this.webinarId = verifyOpenRoom.data.result.webinar_data._id;
          this.joinMeeting();

      }
    },
    async created() {
      window.addEventListener("beforeunload", function (e) {
        let confirmationMessage = "If you leave before saving, your changes will be lost.";
  
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      });
  
  
      if (!navigator.mediaDevices?.enumerateDevices) {
      } else {
        // List cameras and microphones.
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            this.deviceData = devices;
          })
          .catch((error) => {
            if (error.name == "TypeError") {
              $("#TypeError").modal("show");
            }
            if (error.name == "NotReadableError") {
              $("#NotReadableError").modal("show");
              // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
            }
          });
      }
  
  
      navigator.mediaDevices.ondevicechange = async (e) => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log('e-----', e)
        console.log('devices-----', devices)
        this.deviceData = devices;
        
        console.log('--------------', devices.some((device) => device.kind === "videoinput"))
        if(!devices.some((device) => device.kind === "videoinput")){
          if(this.currentVideoState){
            this.handleVideo();
            this.is_cam_allow = false;
          }
        }else{
            $("#DeniedModalCamera").modal("hide");
  
        }
        if (this.stremErr == "NotFoundError") {
          if (this.socketId == "") {
            if (devices.some((device) => device.kind === "videoinput") && devices.some((device) => device.kind === "audioinput")) {
              this.joinMeeting()
            }
          }
        }
      }
  
      let videoInput = this.deviceData.filter((device) => device.kind === "videoinput");
      if (videoInput.length > 0) {
        // this.selectedVideo = videoInput[0].deviceId;
      }
      let audioInput = this.deviceData.filter((device) => device.kind === "audioinput");
      if (audioInput.length > 0) {
  
        // this.selectedAudio = audioInput[0].deviceId;
      }
      let audioOutput = this.deviceData.filter((device) => device.kind === "audiooutput");
      if (audioOutput.length > 0) {
        const audioOutputDe = this.deviceData.filter(
          (device) =>
            (device.kind === "audiooutput" && device.deviceId == "default") ||
            device.kind === "audiooutput" ||
            device.deviceId == "default"
        );
        this.selectedOuterAudio = audioOutput[0].deviceId;
      }
  
      console.log("1--------", audioInput);
      console.log("2--------", audioOutput);
      console.log("3--------", this.deviceData);
  
      // try {
      //   const stream = await navigator.mediaDevices
      //     .getUserMedia({
      //       audio: {
      //         echoCancellation: true,
      //         noiseSuppression: true,
      //         sampleRate: 44100,
      //       },
      //       video: true,
      //     });
      // } catch (error) {
  
      //   if (error.name == "TypeError") {
      //     $("#TypeError").modal("show");
      //   }
  
      //   if (error.name == "AbortError") {
      //     $("#NotReadableError").modal("show");
      //     console.log("this.publisher=============", this.publisher);
      //     // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
      //   }
      //   if (error.name == "NotReadableError") {
      //     $("#NotReadableError").modal("show");
      //     console.log("this.publisher=============", this.publisher);
      //     // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
      //   }
      //   this.join_btn = false;
      //   console.log("error 3 .name", error.name + ": " + error.message);
      // }
  
      setInterval(async () => {
        if (!navigator.mediaDevices?.enumerateDevices) {
          console.log("enumerateDevices() not supported.");
        } else {
          // List cameras and microphones.
          await navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              // console.log("devices.",devices);
              this.deviceData = devices;
            })
            .catch((error) => {
              console.log("error.name", error.name + ": " + error.message);
              if (error.name == "TypeError") {
                $("#TypeError").modal("show");
              }
              if (error.name == "NotReadableError") {
                $("#NotReadableError").modal("show");
                // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
              }
            });
        }
      }, 1000);
  
      const interv = setInterval(() => {
        this.handleVoiceSort();
        // this.getCurrStats()
      }, 700);
      return () => {
        clearInterval(interv);
      };
    },
  
    methods: {
      videresize() { },
      expandNew: function expandNew() {
        resize();
      },
      handleVoiceSort() {
        if (!this.audioPeersRef) return;
  
        let tempArr = [];
        this.audioPeersRef.forEach((item) => {
          if (
            item.consumer?.rtpReceiver?.getSynchronizationSources()[0]?.audioLevel >= 0.01
          ) {
            if (!tempArr.includes(item?.socketId)) {
              tempArr.push(item?.socketId);
            }
            document?.getElementById(item?.socketId)?.childNodes?.forEach((item) => {
              if (!item.classList.contains("animate-flicker")) {
                item.classList.add("animate-flicker");
              }
            });
            if (document.getElementById(item?.socketId + "peer-name-tag")) {
              document
                .getElementById(item?.socketId + "peer-name-tag")
                .classList.add("name-tag-style");
            }
            if (document.getElementById(item?.socketId + "name-tag")) {
              document
                .getElementById(item?.socketId + "name-tag")
                .classList.add("name-tag-style");
            }
          } else {
            document?.getElementById(item?.socketId)?.childNodes?.forEach((item) => {
              if (item.classList.contains("animate-flicker")) {
                item.classList.remove("animate-flicker");
              }
            });
  
            if (document.getElementById(item?.socketId + "peer-name-tag")) {
              document
                .getElementById(item?.socketId + "peer-name-tag")
                .classList.remove("name-tag-style");
            }
            if (document.getElementById(item?.socketId + "name-tag")) {
              document
                .getElementById(item?.socketId + "name-tag")
                .classList.remove("name-tag-style");
            }
          }
        });
      },
      async handlejoinInput() {
        this.user_name = this.your_name;
        if (this.your_name == "") {
          this.name_error = "Please enter your name.";
          this.join_btn = true;
        } else {
          this.name_error = "";
          this.join_btn = false;
        }
      },
      async handleRenameInput() {
        this.editedName = this.edit_name;
        if (this.edit_name == "") {
          this.edit_name_error = "Please enter new name.";
          this.edit_join_btn = true;
        } else {
          this.edit_name_error = "";
          this.edit_join_btn = false;
        }
      },
      async joinMeeting() {
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        if (this.user_name == "") {
          this.name_error = "Please enter your name.";
          this.join_btn = true;
        } else {
          if (!localStorage.getItem("userdetails")) {
            this.loggedUserData = {
              firstName: this.user_name,
              lastName: "",
              profileImg: "default.jpg",
            };
          }
          if (this.role != "VIEWER") {
            this.name_error = "";
            this.join_btn = false;
            this.join_btn = true;
            this.join_loader = true;
            var now = Date.now();
            this.is_stream = true;
  
            try {
  
              const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                  echoCancellation: true,
                  noiseSuppression: true,
                },
                video: true
              });
  
  
  
              const audioTracks = stream.getAudioTracks();
              if (audioTracks.length > 0) {
                const audioDeviceId = audioTracks[0].getSettings().deviceId;
                this.selectedAudio = audioDeviceId;
                console.log("Audio device ID:", audioDeviceId);
              } else {
                console.log("No video tracks found in the stream.");
              }
  
              const videoTracks = stream.getVideoTracks();
              if (videoTracks.length > 0) {
                const videoDeviceId = videoTracks[0].getSettings().deviceId;
                this.selectedVideo = videoDeviceId;
                console.log("Video device ID:", videoDeviceId);
              } else {
                console.log("No video tracks found in the stream.");
              }
              $("#DeniedModal").modal("hide");
              if (this.socketId == "") {
                await this.startWebinar(this.loggedUserData);
              }
            } catch (error) {
              console.log(error.name, "-------------");
              console.log(error.message, "-------------");
              if (error.name == "NotFoundError") {
                // this.running_error = error;
                this.is_cam_allow = false;
                this.stremErr = "NotFoundError";
                $("#DeniedModal").modal("show");
              } else if (error.name == "NotAllowedError") {
                $("#cameraMicDeniedModal").modal("show");
              } else if (error.name == "NotReadableError") {
                this.is_cam_allow = false;
                this.is_cam_allow = false;
                $("#NotReadableError").modal("show");
              } else {
                this.is_cam_allow = false;
  
                $("#errorModal").modal("show");
                this.running_error = error;
              }
              this.join_btn = false;
              this.join_loader = false;
              this.is_mic_allow = false;
            }
            let userAgentString = navigator.userAgent;
            let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
            if (!firefoxAgent) {
              setInterval(async () => {
                // console.log('connected-----', this.socket.connected);
  
                navigator.permissions
                  .query({ name: "microphone" })
                  .then((permissionObj) => {
                    if (permissionObj.state == "denied") {
                      this.is_mic_allow = false;
                      if ($(".DeniedModal").hasClass("show")) {
                      } else {
  
                        this.is_cam_allow = false;
                        this.is_mic_allow = false;
                        // $("#DeniedModal").modal("show");
                      }
                    } else if (permissionObj.state == "granted") {
                      this.is_mic_allow = true;
                      // $("#DeniedModal").modal("hide");
                    } else if (permissionObj.state == "prompt") {
                      this.is_mic_allow = false;
                      // $("#microphoneDeniedModal").modal("show");
                    } else {
                      this.is_mic_allow = false;
                    }
                    permissionObj.onchange = (e) => {
                      if (e.type === "change") {
                        const newState = e.target.state;
  
                        if (newState == "denied") {
                          this.is_mic_allow = false;
                          if ($(".DeniedModal").hasClass("show")) {
                          } else {
                            this.is_cam_allow = false;
                            this.is_mic_allow = false;
                            // $("#DeniedModal").modal("show");
                          }
                        } else if (newState == "granted") {
                          this.is_mic_allow = true;
                          // $("#microphoneDeniedModal").modal("hide");
                        } else if (newState == "prompt") {
                          this.is_mic_allow = false;
                          // $("#microphoneDeniedModal").modal("show");
                        } else {
                        }
                      }
                    };
  
                  })
                  .catch((error) => {
                    console.log("this.is_mic_allow :", this.is_mic_allow);
                    console.log("Got error :", error);
                  });
  
                navigator.permissions
                  .query({ name: "camera" })
                  .then((permissionObj) => {
                    if (permissionObj.state == "denied") {
                      this.is_cam_allow = false;
                      if ($(".DeniedModal").hasClass("show")) {
                      } else {
  
                        this.is_cam_allow = false;
                        // $("#DeniedModal").modal("show");
                      }
                    } else if (permissionObj.state == "granted") {
                      // this.is_cam_allow = true;
                      if(this.deviceData.some((device) => device.kind === "videoinput")){
          this.is_cam_allow = true;
        }
                      // $("#DeniedModal").modal("hide");
                    } else if (permissionObj.state == "prompt") {
                      this.is_cam_allow = false;
                      // $("#microphoneDeniedModal").modal("show");
                    } else {
                      this.is_cam_allow = false;
                    }
                    permissionObj.onchange = (e) => {
                      if (e.type === "change") {
                        const newState = e.target.state;
  
                    console.log("newState :", newState);
  
                        if (newState == "denied") {
                          this.is_cam_allow = false;
                          if ($(".DeniedModal").hasClass("show")) {
                          } else {
                            this.is_cam_allow = false;
                            this.is_cam_allow = false;
                            // $("#DeniedModal").modal("show");
                          }
                        } else if (newState == "granted") {
                          this.is_cam_allow = true;
                          // $("#microphoneDeniedModal").modal("hide");
                        } else if (newState == "prompt") {
                          this.is_cam_allow = false;
                          // $("#microphoneDeniedModal").modal("show");
                        } else {
                        }
                      }
                    };
  
  
                  })
                  .catch((error) => {
                    console.log("this.is_cam_allow :", this.is_cam_allow);
                    console.log("Got error :", error);
                  });
  
                if (this.is_mic_allow && this.is_cam_allow) {
                  $("#cameraMicDeniedModal").modal("hide");
                }
              }, 3000);
            }
          } else {
            await this.startWebinar(this.loggedUserData);
          }
        }
      },
      expand: function expand(ev) {
        let myScreen = document.getElementById("Screen");
        let screens = document.getElementsByClassName("Screen")[0];
        let _conference = document.getElementsByClassName("Conference")[0];
  
        if ($("#Screen").css("display") == "none") {
          document.getElementById("Screen").style.display = "flex";
        } else {
          document.getElementById("Screen").style.display = "none";
        }
        resize();
      },
      async startWebinar(userdetailsData) {
        // alert('Let\'s start');
  
        if (this.socketId == "") {
          const loc = window.location.pathname;
          console.log("starting loc value = " + loc);
          const locs =
            loc.lastIndexOf("/") == loc.length - 1
              ? loc.substring(0, loc.length - 1)
              : loc.substring(0, loc.lastIndexOf("/"));
          const companyname = locs.substring(locs.lastIndexOf("/") + 1);
  
          let urlParams = new URLSearchParams(window.location.search);
  
          if (urlParams.has("webinarid")) {
            this.roomId = this.webinarId;
          } else {
            this.roomId = companyname + window.location.pathname.split("/").pop();
          }
          console.log("companyname = " + companyname);
  
          console.log("Webinar name = " + window.location.pathname.split("/").pop());
  
          console.log("this.loggedUserData________", this.loggedUserData);
  
          // console.log('LocalStream_storage-', document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV"));
          // if (document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV')) {
          //   document.body.removeChild(document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV'))
  
          //   const peerSData = this.peerShow.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
          //   const peerData = this.peers.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
          //   // this.peerShow = peerData;
          //   this.peers = peerData;
          //   this.peerShow = peerSData;
          // }
  
          this.socket = io(this.BASE_URL, { autoConnect: true });
          this.socket.on("connect", async () => {
            if (
              document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV") != null
            ) {
              const peerSData = this.peerShow.filter(
                (peerData) => peerData?.storageId !== "LocalStream_storageId"
              );
              const peerData = this.peers.filter(
                (peerData) => peerData?.storageId !== "LocalStream_storageId"
              );
              // this.peerShow = peerData;
              this.peers = peerData;
              this.peerShow = peerSData;
            }
            this.socket.emit("getRTPCapabilites", async (response) => {
              // alert('socketId ', this.socketId);
              console.log("response-------------0", response);
              this.handleCapabilities(response?.capabilities);
              this.socketId = this.socket.id;
              this.loggedUserData = {
                ...userdetailsData,
                socketId: this.socket.id,
              };
  
              this.user_name = userdetailsData.firstName + " " + userdetailsData.lastName;
              // this.profileImg = userdetailsData.profileImg;
              const obj = {
                room: this.roomId,
                webinarUserType: this.role,
                user_name: userdetailsData.firstName + " " + userdetailsData.lastName,
                profileImg: this.profileImg,
              };
              console.log("obj________", obj);
              this.socket.emit("addUserCall", obj);
  
              this.viewers.push({
                  socketId: this.socket.id,
                  room: this.roomId,
                  userDetails: {
                    user_name: this.user_name,
                    profileImg: this.profileImg,
                    webinarUserType: this.role,
                  },
                });
  
                const objV = {
                  room: this.roomId,
                  webinarUserType: this.role,
                  userDetails: {
                    user_name: userdetailsData.firstName + " " + userdetailsData.lastName,
                    profileImg: userdetailsData.profileImg,
                  },
                };
  
                this.is_stream = false;
                // this.socket.emit("addUserInViewerCall", objV);
              console.log("-----------------");
              console.log(this.role);
              setInterval(async () => {
                // console.log('connected-----', this.socket.connected);
  
                if (this.socket.connected == false) {
                  $("#reconnecting").modal("show");
                  setInterval(async () => {
                    window.location.reload(true);
                  }, 3000);
                  // this.startWebinar(this.loggedUserData)
                } else {
                  $("#reconnecting").modal("hide");
                }
              }, 3000);
            });
     
          });
  
          this.socket.on("stopRecordingNotify", (data) => {
            console.log('data-------', data);
  
            if (data.recordingUsers.length == 0) {
              this.topRecording = false;
            } else {
              this.topRecording = true;
  
            }
  
            let names = data.recordingUsers.map(x => x.username).join(", ");
            this.recorderUsers = names;
          });
  
          this.socket.on("recordingNotify", (data) => {
  
            if (data.recordingUsers.length != 0) {
              this.topRecording = true;
              let names = data.recordingUsers.map(x => x.username).join(", ");
              console.log('data-------', data);
              this.recorderUsers = names;
              console.log('names-------', names);
            }
          });
          this.socket.on("userLeft", (socketId) => {
            console.log("userLeft--------", socketId);
            console.log("userLeft------PF--", this.peersRef);
            console.log("userLeft------P--", this.peers);
            console.log("userLeft------PS--", this.peerShow);
  
            const peeridx = this.peers.findIndex((peer) => peer?.socketId === socketId);
            if (peeridx !== -1) {
              const peersFilter = this.peers.filter((peer) => peer?.socketId !== socketId);
              this.peersRef = peersFilter;
              this.peers = peersFilter;
              this.peerShow = peersFilter;
              console.log("userLeft------PR--", peersFilter);
              if (this.recordingRef) {
                this.resetVideoRec();
              }
            }
            const peersPFilter = this.filterdParticipants.filter(
              (peer) => peer?.socketId !== socketId
            );
            this.filterdParticipants = peersPFilter;
          });
  
          this.socket.on("disconnect", () => {
            this.socketId = this.socket.id;
            console.log("disconnect--", this.socket.id); // undefined
          });
  
          this.socket.on("finalFile", (id) => {
            const $toast = useToast();
            // this.downloadRecordFile(id)
            $toast.clear();
            window.open(this.BASE_URL + "api/users/v1/webinar/file/" + id, "_blank");
  
            $toast.open({
              message: "Your recording is converting and download succesfully.",
              type: "success",
              dismissible: false,
              duration: 3000,
              // all of other options may go here
            });
            console.log("finalFile--", id); // undefined
          });
  
          this.socket.on("closeCamera", async ({ socketId, status }) => {
            console.log("closeCamera--", { socketId, status }); // undefined
  
            if (status == true) {
              // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
              // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
            } else {
              // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
              // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
            }
            if (status) {
              if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
                document.getElementById(socketId + "_PEER_IMAGE").style.display = "flex";
              }
            } else {
              if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
                document.getElementById(socketId + "_PEER_IMAGE").style.display = "none";
              }
            }
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                !this.mainScreenStreamRef.screenShare
              ) {
                this.mainScreenStreamRef.mutedVideo = status;
              }
            }
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.socketId == socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peers[objIndex].mutedVideo = status;
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.socketId == socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peerShow[objIndex].mutedVideo = status;
              }
            }
            if (this.peersRef.length > 0) {
              var objIndex = this.peersRef.findIndex(
                (user) => user?.socketId == socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peersRef[objIndex].mutedVideo = status;
              }
            }
          });
  
          this.socket.on("closeConsumer", async (storageId) => {
            console.log("close consumer", storageId);
            console.log("this.mainScreenStreamRef:  ", this.mainScreenStreamRef);
            await this.receiveTransport[storageId]?.close();
            this.receiveTransport[storageId] = null;
            this.consumers.delete(storageId);
            if (this.recordingRef) {
              this.resetVideoRec()
            }
  
            console.log("this.peers  cc ", this.peers);
            console.log("this.peerShow  cc ", this.peerShow);
            const idx = this.peers?.findIndex(
              (item) => item?.storageId === storageId && item?.screenShare === false
            );
            if (idx !== -1) {
              console.log("If 1 ");
              if (this.mainScreenStreamRef) {
                console.log("If 2 ");
                if (this.mainScreenStreamRef.screenShare == false) {
                  if (this.mainScreenStreamRef.storageId == storageId) {
                    this.mainScreenStreamRef.mediaStream = null;
                    // this.mainScreenStreamRef.storageId = storageId;
                    this.mainScreenStreamRef.mutedVideo = true;
                    // this.updateIsShow(this.mainScreenStreamRef.storageId, true)
                  }
                }
              }
  
              if (this.peers[idx]?.screenShare === false) {
                this.peers[idx].mediaStream = null;
                this.peers[idx].mutedVideo = true;
              } else {
                console.log("cc ELSE ");
  
                // this.peers?.splice(idx, 1)
              }
              const idx1 = this.peerShow?.findIndex(
                (item) => item?.storageId === storageId && item?.screenShare === false
              );
              if (this.peerShow[idx1]?.screenShare === false) {
                this.peerShow[idx1].mediaStream = null;
                this.peerShow[idx1].mutedVideo = true;
              } else {
                console.log("cc ELSE 33");
  
                // this.peerShow?.splice(idx, 1)
              }
            } else {
              console.log("DISPLAY NONE HTI");
              if (document.getElementById(storageId)) {
                document.body.removeChild(document.getElementById(storageId));
  
                if (this.recordingRef) {
                  const item = this.sourceNodes.get(storageId);
                  if (item) {
                    item?.node?.disconnect();
                    item?.mediaStream?.getTracks()?.forEach((track) => {
                      track?.stop();
                    });
                    this.sourceNodes.delete(storageId);
                  }
                }
              }
              // if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
              //   document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
              // }
              if (this.peersRef?.some((item) => item?.storageId === storageId)) {
                const peerFilter = this.peersRef?.filter(
                  (peer) => peer?.storageId !== storageId
                );
                this.peersRef = peerFilter;
                // setPeers(peerFilter)
              }
  
              const peerSData = this.peerShow.filter(
                (peerData) => peerData?.storageId !== storageId
              );
              const peerData = this.peers.filter(
                (peerData) => peerData?.storageId !== storageId
              );
              const guestUsersData = this.filterdParticipants.filter(
                (peerData) => peerData?.storageId !== storageId
              );
              // this.peerShow = peerData;
              this.peers = peerData;
              this.peerShow = peerSData;
              this.filterdParticipants = guestUsersData;
  
              console.log(peerSData);
              console.log(peerData);
  
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.storageId == storageId) {
                  console.log("S 1 ");
                  this.mainScreenStreamRef = null;
                  document.getElementById("Screen").style.display = "none";
                } else {
                  if (
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                    )
                  ) {
                    console.log("ELSE");
                    // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
                    this.updateIsShow(this.mainScreenStreamRef.storageId, false);
                  }
                }
              }
  
              const audioElementData = this.audioElement.filter(
                (audioData) => audioData !== storageId
              );
              this.audioElement = audioElementData;
  
              const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
              this.peerShow = peerFilter;
              //console.log("this.peers______", this.peers);
              console.log("this.peerShow______", this.peerShow);
              this.manageDiv();
            }
          });
  
          this.socket.on("speaking", (value) => {
            if (value) {
              if (this.globalTimeout) {
                clearTimeout(this.globalTimeout);
              }
  
              const myAudioIcon = document?.getElementById(this.socket.id)?.childNodes;
              myAudioIcon?.forEach((item) => {
                if (!item.classList.contains("animate-flicker")) {
                  item.classList.add("animate-flicker");
  
                  if (document.getElementById(this.socket.id + "peer-name-tag")) {
                    document
                      .getElementById(this.socket.id + "peer-name-tag")
                      .classList.add("name-tag-style");
                  }
                  if (document.getElementById(this.socket.id + "name-tag")) {
                    document
                      .getElementById(this.socket.id + "name-tag")
                      .classList.add("name-tag-style");
                  }
                }
              });
  
              this.globalTimeout = setTimeout(() => {
                const audioIconItems = document?.getElementById(this.socket.id)?.childNodes;
                audioIconItems?.forEach((item) => {
                  if (item.classList.contains("animate-flicker")) {
                    item.classList.remove("animate-flicker");
  
                    if (document.getElementById(this.socket.id + "name-tag")) {
                      document
                        .getElementById(this.socket.id + "name-tag")
                        .classList.remove("name-tag-style");
                    }
                    if (document.getElementById(this.socket.id + "peer-name-tag")) {
                      document
                        .getElementById(this.socket.id + "peer-name-tag")
                        .classList.remove("name-tag-style");
                    }
                  }
                });
              }, 700);
            }
          });
  
          this.socket.on("closeConsumerOLD", async (storageId) => {
            console.log("close consumer", storageId);
            //console.log("this.peers______", this.peers);
  
            // console.log('peerData_________',peerData);
            // if (document.getElementById(storageId + "_SHARE_SCREEN_VIDEO") != null) {
            //   document.getElementById("Screen").style.display = "none";
            //   this.mainScreenStreamRef = null
            //   console.log('_SHARE_SCREEN_VIDEO---------------', storageId);
            // }
            if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
              console.log("DISPLAY NONE HTI");
              document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display =
                "flex";
  
              // var el = document.getElementById(storageId + "_STREAM_VIDEO_DIV");
              // var removedChild = el.remove();
              // document.body.removeChild(document.getElementById(storageId + "_STREAM_VIDEO_DIV"))
            }
            if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
              //ADDED IN DIV OR SCREEN SHARING OR
              document.getElementById("Screen").style.display = "none";
              console.log("_1---------------", storageId);
              // document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
  
              if (this.mainScreenStreamRef.storageId == storageId) {
                console.log("_2---------------", this.mainScreenStreamRef.storageId);
                console.log("if___3______");
                if (
                  document.getElementById(
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  ) != null
                ) {
                  console.log("if___2______");
                  const peerData = this.peers.filter(
                    (peerData) => peerData?.storageId !== this.mainScreenStreamRef.storageId
                  );
                  this.peers = peerData;
                  console.log(
                    "removeChild____v_____",
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  );
                  document
                    .getElementById(
                      this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                    )
                    .remove();
                  await this.receiveTransport[storageId]?.close();
                  this.receiveTransport[storageId] = null;
                  this.consumers.delete(storageId);
                  // document.body.removeChild(document.getElementById(this.mainScreenStreamRef.storageId))
                }
              } else {
                console.log("AVVo");
              }
              this.mainScreenStreamRef = null;
            } else {
              const peerData = this.peers.filter(
                (peerData) => peerData?.storageId !== storageId
              );
              this.peers = peerData;
  
              await this.receiveTransport[storageId]?.close();
              this.receiveTransport[storageId] = null;
              this.consumers.delete(storageId);
              // if (this.peersRef.some((item) => item?.storageId === storageId)) {
              //   const peerFilter = this.peersRef.filter((peer) => peer?.storageId !== storageId)
              //   this.peersRef = peerFilter;
              //   this.peers = peerFilter;
              //   console.log('peerFilter_________', peerFilter);
              // }
              // setPeers(peerFilter)
              if (this.mainScreenStreamRef) {
                console.log("if___1______");
                if (this.mainScreenStreamRef.storageId == storageId) {
                  const peerData = this.peers.filter(
                    (peerData) => peerData?.storageId !== storageId
                  );
                  this.peers = peerData;
                  console.log("if___3______");
                  if (
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                    )
                  ) {
                    console.log("if___2______");
                    console.log(
                      "removeChild____ifff_____",
                      this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                    );
                    document.body.removeChild(
                      document.getElementById(
                        this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                      )
                    );
                  }
                } else {
                  console.log("AAVO");
                }
              } else {
                console.log("if___5______");
              }
            }
            if (document.getElementById(storageId)) {
              console.log("removeChild_________", storageId);
              document.body.removeChild(document.getElementById(storageId));
            }
            const audioElementData = this.audioElement.filter(
              (audioData) => audioData !== storageId
            );
            this.audioElement = audioElementData;
            console.log("this.peers", this.peers);
            this.manageDiv();
          });
  
          this.socket.on("transportCreated", (data) => {
            this.handleTransport(data);
          });
          this.socket.on("stopShareScreen", (data) => {
            console.log("_________stopShareScreen___________");
            this.ScreenShare(data);
          });
  
          this.socket.on("chat", (data) => {
            console.log("CHAT______", data);
            this.chatHistory.unshift(data);
          });
          this.socket.on("hideRaideHandForUser", (data) => {
            this.raiseHand();
          });
          this.socket.on("raise_hand", (data) => {
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == data.socketId &&
                this.mainScreenStreamRef?.screenShare == false
              ) {
                this.mainScreenStreamRef.raiseHand = data.is_show;
              }
            }
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peers[objIndex].raiseHand = data.is_show;
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peerShow[objIndex].raiseHand = data.is_show;
              }
            }
  
            console.log("raise_hand______", data);
  
            // this.chatHistory.push(data);
          });
  
          this.socket.on("currentViewers", (producers) => {
            console.log("currentViewers:   ", producers);
            this.filterdParticipants = producers;
            // producers?.forEach((producer) => {
            //   this.startConsumeProducer(producer)
            // })
          });
          this.socket.on("currentProducers", (producers) => {
            producers?.forEach((producer) => {
              this.startConsumeProducer(producer);
            });
          });
  
          this.socket.on("producerResumed", async ({ storageId, socketId, kind }) => {
            //console.log("this.peers_______________", this.peers);
            console.log("producerResumed________", {
              storageId,
              socketId,
              kind,
            });
            if (kind === "video") {
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.socketId == socketId) {
                  this.mainScreenStreamRef.mutedVideo = false;
                }
              }
              if (this.peers.length > 0) {
                var objIndex = this.peers.findIndex(
                  (user) => user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peers[objIndex].mutedVideo = false;
                }
              }
              if (this.peerShow.length > 0) {
                var objIndex = this.peerShow.findIndex(
                  (user) => user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peerShow[objIndex].mutedVideo = false;
                }
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO_POSITION") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.position = "relative";
                document.getElementById(storageId + "_STREAM_VIDEO_POSITION").style.width =
                  "max-content";
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
                document.getElementById(storageId + "_STREAM_VIDEO").style.visibility =
                  "visible";
              }
              if (document.getElementById(storageId + "_VIDEO") != null) {
                document.getElementById(storageId + "_VIDEO").style.visibility = "hidden";
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_ZOOM_ICON"
                ).style.visibility = "visible";
              }
              if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
                document.getElementById(
                  storageId + "_STREAM_SCREEN_VIDEO"
                ).style.visibility = "visible";
                document.getElementById("Screen").style.background = "#black";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ).style.background = "transparent";
              }
              if (document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") != null) {
                document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO").style.display =
                  "none";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
                document.getElementById(storageId + "_SCREEN_VIDEO").style.display = "none";
              }
              if (
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                  ).style.display = "none")
                  : "";
              }
  
              if (document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                  ).style.display = "none")
                  : "";
              } else {
                console.log("offlinedoes not exist! _OFFLINE_VIDEO_TOP_NOTIFY");
              }
  
              if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") != null) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "none";
              }
              if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY").style.display =
                  "inline";
              }
              if (document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null) {
                document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION").innerHTML =
                  "Mute Camera";
              }
              if (document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") != null) {
                document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION").innerHTML =
                  "Mute Camera";
              }
              if (socketId == this.socket.id) {
                this.currentVideoState = true;
              }
              //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
            } else {
              const index = this.audioMicClose.indexOf(socketId);
              if (index > -1) {
                // only splice array when item is found
                this.audioMicClose.splice(index, 1); // 2nd parameter means remove one item only
              }
              if (document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                  ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                  ).style.display = "none")
                  : "";
              }
              if (document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null) {
                document.getElementById(socketId + "_AUDIO_MUTE_ACTION").innerHTML =
                  "Mute Mic";
              }
              if (document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") != null) {
                document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION").innerHTML =
                  "Mute Mic";
              }
              if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY").style.display =
                  "inline";
              }
              if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") != null) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "none";
              }
              if (socketId == this.socket.id) {
                this.currentMicState = true;
              }
  
              if (this.recordingRef) {
                this.resetVideoRec()
              }
              //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
            }
          });
  
          this.socket.on("producerPaused", async ({ storageId, socketId, kind }) => {
            //console.log("this.peers_______________", this.peers);
            console.log("producerPaused________", {
              storageId,
              socketId,
              kind,
            });
  
            if (kind === "video") {
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.socketId == socketId) {
                  this.mainScreenStreamRef.mutedVideo = true;
                }
              }
              if (this.peers.length > 0) {
                var objIndex = this.peers.findIndex(
                  (user) => user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peers[objIndex].mutedVideo = true;
                }
              }
              if (this.peerShow.length > 0) {
                var objIndex = this.peerShow.findIndex(
                  (user) => user?.storageId == storageId && user?.screenShare == false
                );
  
                //console.log("objIndex______", objIndex);
                if (objIndex >= 0) {
                  //console.log("objIndex______", objIndex);
                  this.peerShow[objIndex].mutedVideo = true;
                }
              }
              if (socketId == this.socket.id) {
                this.currentVideoState = false;
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
                document.getElementById(storageId + "_STREAM_VIDEO").style.visibility =
                  "hidden";
              }
              if (document.getElementById(storageId + "_STREAM_VIDEO_POSITION") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_POSITION"
                ).style.position = "";
                document.getElementById(storageId + "_STREAM_VIDEO_POSITION").style.width =
                  "inherit";
              }
              if (document.getElementById(storageId + "_VIDEO") != null) {
                document.getElementById(storageId + "_VIDEO").style.visibility = "visible";
              }
  
              if (document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") != null) {
                document.getElementById(
                  storageId + "_STREAM_VIDEO_ZOOM_ICON"
                ).style.visibility = "hidden";
              }
  
              if (
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
                ).style.background = "black";
              }
              if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
                document.getElementById("Screen").style.background = "black";
                document.getElementById(
                  storageId + "_STREAM_SCREEN_VIDEO"
                ).style.visibility = "hidden";
              }
              if (document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") != null) {
                document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO").style.display =
                  "none";
              }
              if (
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ) != null
              ) {
                document.getElementById(
                  "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
                ).style.display = "none";
              }
              if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
                document.getElementById(storageId + "_SCREEN_VIDEO").style.display = "flex";
              }
              if (
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                  ).style.display = "none")
                  : "";
              }
  
              if (
                document.getElementById(storageId + "_OFFLINE_VIDEO_TOP_NOTIFY") != null
              ) {
                document.getElementById(storageId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                  ? (document.getElementById(
                    storageId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                  ).style.display = "none")
                  : "";
              } else {
                console.log("offline does not exist!");
              }
              //DROP TOP LEFT HOVER ACTION
              if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") != null) {
                document.getElementById(
                  socketId + "_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "inline";
              }
  
              if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_VIDEO_TOP_NOTIFY").style.display =
                  "none";
              }
              //DROP DOWN REMOTE ACTION
              if (document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null) {
                document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION").innerHTML =
                  "Unmute Camera";
              }
              if (document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") != null) {
                document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION").innerHTML =
                  "Unmute Camera";
              }
              //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
            } else {
              this.audioMicClose.push(socketId);
              if (document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                  ).style.display = "none")
                  : "";
              }
              if (
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH") !=
                null
              ) {
                document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH")
                  ? (document.getElementById(
                    socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                  ).style.display = "none")
                  : "";
              }
              if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null) {
                document.getElementById(socketId + "_AUDIO_TOP_NOTIFY").style.display =
                  "none";
              }
              if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") != null) {
                document.getElementById(
                  socketId + "_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "inline";
              }
              if (document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null) {
                document.getElementById(socketId + "_AUDIO_MUTE_ACTION").innerHTML =
                  "Unmute Mic";
              }
              if (document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") != null) {
                document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION").innerHTML =
                  "Unmute Mic";
              }
              if (socketId == this.socket.id) {
                this.currentMicState = false;
              }
  
              if (this.recordingRef) {
                this.resetVideoRec()
              }
              //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
            }
          });
  
          this.socket.on("ConsumeTransportCreated", async (data) => {
            this.consume(data);
          });
  
          this.socket.on("consumerCreated", (data) => {
            this.handleConsumer(data);
          });
  
          this.socket.on("newProducer", (producer) => {
            this.startConsumeProducer(producer);
          });
  
          this.socket.on("renameUser", (data) => {
            if (document.getElementById(data.socketId + "_USER_MAIN_NAME") != null) {
              document.getElementById(data.socketId + "_USER_MAIN_NAME").innerHTML =
                data.name;
              console.log("renameUser________updated");
            }
            if (document.getElementById(data.socketId + "_USER_NAME") != null) {
              document.getElementById(data.socketId + "_USER_NAME").innerHTML = data.name;
              console.log("renameUser________updated");
            }
            if (document.getElementById(data.socketId + "_SCREEN_USER_NAME") != null) {
              document.getElementById(data.socketId + "_SCREEN_USER_NAME").innerHTML =
                data.name;
              console.log("renameUser________updated");
            }
            //console.log("this.peers______", this.peers);
            console.log("data______", data);
  
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peers[objIndex].userDetails.user_name = data.name;
              }
              //Update object's name property.
              var objIndexScreen = this.peers.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == true
              );
              if (objIndexScreen >= 0) {
                console.log("objIndexScreen______", objIndexScreen);
                this.peers[objIndexScreen].userDetails.user_name = data.name;
              }
            }
  
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peerShow[objIndex].userDetails.user_name = data.name;
              }
              //Update object's name property.
              var objIndexScreen = this.peerShow.findIndex(
                (user) => user?.socketId == data.socketId && user?.screenShare == true
              );
              if (objIndexScreen >= 0) {
                console.log("objIndexScreen______", objIndexScreen);
                this.peerShow[objIndexScreen].userDetails.user_name = data.name;
              }
            }
  
            if (this.mainScreenStreamRef) {
              console.log("changes ______", this.mainScreenStreamRef);
              if (this.mainScreenStreamRef.socketId == data.socketId) {
                this.mainScreenStreamRef.userDetails.user_name = data.name;
                console.log("changes DONE______", this.mainScreenStreamRef);
              }
            }
  
            console.log("this.peers___NEW___", this.peers);
            if (this.socket.id == data.socketId) {
              console.log("HIII saME AS");
              this.user_name = data.name;
            }
          });
  
          this.socket.on("removeUser", () => {
            $("#remove_users").modal("show");
  
            setTimeout(function () {
              window.location.href = window.origin;
              // this.$router.push("/login");
            }, 2000);
          });
  
          this.socket.on("muteMic", ({ remote, action, userName }) => {
            console.log("remote---muteMic---", remote);
            if (action == "unmute") {
              this.requestUserName = userName;
              $("#requestMicModal").modal("show");
            } else {
              this.handleMic();
            }
          });
  
          this.socket.on("muteVideo", ({ socketId, remote, action, userName }) => {
            console.log("remote---muteVideo---", socketId, remote, action);
            console.log("is_cam_request-------", this.is_cam_request);
  
            if (action == "unmute") {
              this.requestUserName = userName;
              $("#requestCamModal").modal("show");
            } else {
              this.handleVideo();
            }
          });
  
          window.setInterval(() => {
            resize();
            var video = document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT");
            if (video) {
              video.videoHeight; // returns the intrinsic height of the video
              video.videoWidth; // returns the intrinsic width of the video
              if (video.videoHeight < video.videoWidth) {
                if (
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width != "100%"
                ) {
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width = "100%";
                }
                if (
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit !=
                  "cover"
                ) {
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit =
                    "cover";
                  console.log("video_landscape_: TRUE");
                }
              } else {
                if (
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit !=
                  "contain"
                ) {
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit =
                    "contain";
                }
                if (
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width != "auto"
                ) {
                  document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width = "auto";
                  console.log("video_landscape_: FALSE______");
                }
                /*OR*/
                /*video_portrait = "true"*/
              }
            }
          }, 100);
        }
      },
      async allowMicModal() {
        $("#requestMicModal").modal("hide");
        // this.requestUserName = "";
        this.handleMic();
      },
      async denyMicModal() {
        $("#requestMicModal").modal("hide");
        // this.requestUserName = "";
      },
      async allowCamModal(producer) {
        this.is_cam_request = true;
        $("#requestCamModal").modal("hide");
        // this.requestUserName = "";
        this.handleVideo();
        // this.is_cam_request = false;
      },
      async denyCamModal(producer) {
        $("#requestCamModal").modal("hide");
        // this.requestUserName = "";
      },
      async startConsumeProducer(producer) {
        console.log("HHHHHHHHHHHH");
        this.socket.emit("createConsumeTransport", producer);
      },
      async handleCapabilities(capabilities) {
        const cap = { routerRtpCapabilities: capabilities };
        const detectedHandler = detectDevice();
        let handlerName;
        // alert(detectedHandler)
        if (detectedHandler) {
          handlerName = detectedHandler;
        } else {
          handlerName = "Safari12";
        }
        try {
          if (handlerName !== "Firefox60") {
            cap.routerRtpCapabilities.headerExtensions = cap.routerRtpCapabilities.headerExtensions.filter(
              (ext) => ext.uri !== "urn:3gpp:video-orientation"
            );
          }
          this.mediasoupDevice = new Device({ handlerName: handlerName });
          // alert(handlerName)
          console.log("this.mediasoupDevice-----", this.mediasoupDevice);
        } catch (err) {
          console.log("err-----", err);
        }
        await this.mediasoupDevice?.load(cap);
  
        if (this.mediasoupDevice?.loaded) {
          console.log("selectedVideo --- ", this.selectedVideo);
          console.log("selectedAudio --- ", this.selectedAudio);
          console.log("loaded --- ");
        }
      },
      async startStream() {
        try {
          const localStream = await navigator?.mediaDevices?.getUserMedia({
            audio: {
              noiseSuppression: true,
              echoCancellation: true,
            }
          });
  
          const audioTracks = localStream.getAudioTracks();
          if (audioTracks.length > 0) {
            const audioDeviceId = audioTracks[0].getSettings().deviceId;
            this.selectedAudio = audioDeviceId;
            this.is_mic_allow = true;
            console.log("Audio device ID:", audioDeviceId);
          } else {
            console.error("No video tracks found in the stream.");
          }
  
          // const videoTracks = localStream.getVideoTracks();
          // if (videoTracks.length > 0) {
          //   const videoDeviceId = videoTracks[0].getSettings().deviceId;
          //   this.selectedVideo = videoDeviceId
          //   console.log('Video device ID:', videoDeviceId);
          // } else {
          //   console.error('No video tracks found in the stream.');
          // }
          this.localStreamRef = localStream;
  
          console.log("localStream----222-------", this.localStreamRef);
  
          this.peers.push({
            is_show: true,
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
              webinarUserType: this.role,
            },
            is_mute: false,
            is_ban: false,
            paused: true,
            raiseHand: false,
            mutedVideo: true,
            kind: "video",
            mediaStream: this.localStreamRef,
          });
  
          this.peersRef = [
            ...this.peersRef,
            {
              kind: "video",
              raiseHand: false,
              mutedVideo: true,
              is_show: true,
              paused: true,
              userDetails: {
                user_name: this.user_name,
                profileImg: this.profileImg,
                webinarUserType: this.role,
              },
              socketId: this.socket.id,
              storageId: "LocalStream_storageId",
              screenShare: false,
              mediaStream: this.localStreamRef,
            },
          ];
  
          this.peerShow.push({
            is_show: true,
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            raiseHand: false,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
              webinarUserType: this.role,
            },
            is_mute: false,
            is_ban: false,
            paused: true,
            mutedVideo: true,
            kind: "video",
            mediaStream: this.localStreamRef,
          });
  
          this.filterdParticipants = this.peerShow.filter(
            (a) => a.userDetails.webinarUserType == "HOST"
          );
  
          // this.audioPeersRef = [
          //   ...this.audioPeersRef,
          //   {
          //     socketId: this.socket.id,
          //     storageId: "LocalStream_storageId",
          //     mediaStream: this.localStreamRef,
          //     consumer: "consumer",
          //   },
          // ];
          // this.handleAudioLevel();
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
        // this.audioElement.push('localStream' + this.socket.id)
        // const audioElem = document.createElement('audio')
        // audioElem.autoplay = true;
        // audioElem.srcObject = localStream;
        // audioElem.id = 'localStream' + this.socket.id;
        // this.audioElement.push('localStream' + this.socket.id)
        // document.body.appendChild(audioElem);
      },
      async openenterNameModal() {
        $("#enterNameModal").modal("show");
      },
      async manageDiv() {
        //console.log("this.peers.length__________", this.peers.length);
  
        if (this.peers.length == 1) {
          if (this.mainScreenStreamRef == null) {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          } else {
            if (this.mainScreenStreamRef.screenShare) {
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            } else {
              document.getElementById("ALL_VIDEOS").style.display = "none";
            }
          }
        } else {
          if (this.mainScreenStreamRef) {
            if (this.peers.length == 0) {
              document.getElementById("ALL_VIDEOS").style.display = "none";
            } else {
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            }
          } else {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          }
        }
      },
      async startProducing() {
        this.socket.emit("createTransport", this.socket.id);
      },
      async handleTransport({ data }) {
        console.log("trans", data);
        this.produceTransport = await this.mediasoupDevice.createSendTransport(data);
  
        console.log("trans ---  this.produceTransport----", this.produceTransport);
        this.produceTransport.on("connect", ({ dtlsParameters }, callback, errback) => {
          console.log("trans ---  connect----");
          this.socket.emit("connectTransport", {
            dtlsParameters,
            id: this.socket.id,
          });
          this.socket.on("transportConnected", () => {
            console.log("trans ---  transportConnected----");
            callback();
          });
        });
  
        this.produceTransport.on(
          "produce",
          ({ kind, rtpParameters, appData }, callback, errback) => {
            console.log("trans ---  produce----", {
              kind,
              rtpParameters,
              appData,
            });
            this.socket.emit("produce", {
              kind,
              rtpParameters,
              id: this.socket.id,
              room: this.roomId,
              appData,
            });
            this.socket.on("producing", ({ producerId, kind, screenShare, appData }) => {
              console.log("trans ---  producing----", producerId, kind, screenShare);
              if (kind === "video") {
                if (screenShare) {
                  this.videoProducerId2 = producerId;
                } else if (appData?.type === "record") {
                  if (!this.recordIds?.includes(producerId)) {
                    this.recordIds?.push(producerId);
  
                    if (this.recordIds.length === 2) {
                      this.socket.emit("startRecording", this.recordIds);
                    }
                  }
                } else {
                  this.videoProducerId = producerId;
                }
              } else {
                if (screenShare) {
                  this.audioProducerId2 = producerId;
                } else if (appData?.type === "record") {
                  if (!this.recordIds?.includes(producerId)) {
                    this.recordIds?.push(producerId);
                    if (this.recordIds.length === 2) {
                      this.socket.emit("startRecording", this.recordIds);
                    }
                  }
                } else {
                  this.audioProducerId = producerId;
                }
              }
  
              callback(producerId);
            });
          }
        );
        try {
          this.produceTransport?.on(
            "producedata",
            ({ sctpStreamParameters }, callback, errback) => {
              this.socket.emit(
                "produceData",
                { params: sctpStreamParameters, roomId: this.roomId },
                (response) => {
                  if (response?.type === "success") {
                    this.dataProducerId = response?.id;
                    console.log("response?.id-------------", response?.id);
                    callback(response?.id);
                  } else {
                    console.log("response?.error-------------", response?.error);
                    errback(response?.error);
                  }
                }
              );
            }
          );
          this.produceTransport.on("connectionstatechange", (state) => {
            console.log("state-----------", state);
            if (state == "connected") {
              let videoInput = this.deviceData.filter(
                (device) => device.kind === "videoinput"
              );
              if (videoInput.length > 0) {
                // this.selectedVideo = videoInput[0].deviceId;
              }
              let audioInput = this.deviceData.filter(
                (device) => device.kind === "audioinput"
              );
              if (audioInput.length > 0) {
                // this.selectedAudio = audioInput[0].deviceId;
              }
              let audioOutput = this.deviceData.filter(
                (device) => device.kind === "audiooutput"
              );
              if (audioOutput.length > 0) {
                const audioOutputDe = this.deviceData.filter(
                  (device) =>
                    (device.kind === "audiooutput" && device.deviceId == "default") ||
                    device.kind === "audiooutput" ||
                    device.deviceId == "default"
                );
                this.selectedOuterAudio = audioOutput[0].deviceId;
              }
              $("#enterNameModal").modal("hide");
              this.join_loader = false;
            }
            switch ((state, err)) {
              case "connecting":
                console.log("connecting");
                break;
              case "connected":
                console.log("connected");
                break;
              case "failed":
                $("#errorModal").modal("show");
                this.running_error = error;
                console.log("failed");
                this.join_loader = false;
                // window.location.reload()
                break;
              default:
                break;
            }
  
            console.log("state------err-----", state);
          });
  
          try {
            console.log("this.localStreamRef----", this.localStreamRef);
            const Audiotracks = this.localStreamRef.getAudioTracks()[0];
            // const Videotracks = this.localStreamRef.getVideoTracks()[0];
            if (Audiotracks) {
              this.audioProducer = await this.produceTransport?.produce({
                track: Audiotracks,
              });
              // this.handleMic()
              this.socket.emit(
                "handleMicVideoProducer",
                {
                  producerId: this.audioProducerId,
                  state: true,
                  kind: "audio",
                  storageId: this.currentMicState,
                  socketId: this.socket.id,
                },
                async (response) => {
                  await this.audioProducer.pause();
                  this.audio = false;
                }
              );
            }
            // if (Videotracks) {
            //   this.videoProducer = await this.produceTransport?.produce({
            //     track: Videotracks,
            //   });
            //   // this.handleVideo()
            //   this.audio = false;
            //   this.localStreamRef?.getVideoTracks()?.forEach((track) => {
            //     track?.stop();
            //   });
  
            //   if (this.peers.length > 0) {
            //     var objIndex = this.peers.findIndex(
            //       (user) =>
            //         user?.socketId == this.socket.id && user?.screenShare == false
            //     );
  
            //     //console.log("objIndex______", objIndex);
            //     if (objIndex >= 0) {
            //       const source = this.peers.find(
            //         (user) =>
            //           user?.socketId == this.socket.id &&
            //           user?.screenShare == false
            //       );
            //       //console.log("objIndex______", objIndex);
            //       if (source.mediaStream) {
            //         source.mediaStream.getVideoTracks().forEach(function (track) {
            //           console.log("PS :)  ", track.stop());
            //           track.stop();
            //         });
            //         this.peers[objIndex].mediaStream = null;
            //       }
            //       //console.log("objIndex______", objIndex);
            //       this.peers[objIndex].mutedVideo = true;
            //     }
            //   }
            //   if (this.peersRef.length > 0) {
            //     var objIndex = this.peersRef.findIndex(
            //       (user) =>
            //         user?.socketId == this.socket.id && user?.screenShare == false
            //     );
            //     //console.log("objIndex______", objIndex);
            //     if (objIndex >= 0) {
            //       const source = this.peersRef.find(
            //         (user) =>
            //           user?.socketId == this.socket.id &&
            //           user?.screenShare == false
            //       );
            //       //console.log("objIndex______", objIndex);
            //       if (source.mediaStream) {
            //         source.mediaStream.getVideoTracks().forEach(function (track) {
            //           console.log("PS :)  ", track.stop());
            //           track.stop();
            //         });
            //         this.peersRef[objIndex].mediaStream = null;
            //       }
            //       //console.log("objIndex______", objIndex);
            //       this.peersRef[objIndex].mutedVideo = true;
            //     }
            //   }
            //   if (this.peerShow.length > 0) {
            //     var objIndex = this.peerShow.findIndex(
            //       (user) =>
            //         user?.socketId == this.socket.id && user?.screenShare == false
            //     );
            //     //console.log("objIndex______", objIndex);
            //     if (objIndex >= 0) {
            //       const source = this.peerShow.find(
            //         (user) =>
            //           user?.socketId == this.socket.id &&
            //           user?.screenShare == false
            //       );
            //       //console.log("objIndex______", objIndex);
            //       if (source.mediaStream) {
            //         source.mediaStream.getVideoTracks().forEach(function (track) {
            //           console.log("PS :)  ", track.stop());
            //           track.stop();
            //         });
            //         this.peerShow[objIndex].mediaStream = null;
            //       }
            //       //console.log("objIndex______", objIndex);
            //       this.peerShow[objIndex].mutedVideo = true;
            //     }
            //   }
            //   if (
            //     document.getElementById(
            //       "localStream" + this.socket.id + "_SCREEN_VIDEO"
            //     ) != null
            //   ) {
            //     document.getElementById(
            //       "localStream" + this.socket.id + "_SCREEN_VIDEO"
            //     ).style.display = "flex";
            //   }
  
            //   if (
            //     document.getElementById(
            //       "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
            //     ) != null
            //   ) {
            //     document.getElementById(
            //       "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
            //     ).style.background = "black";
            //   }
            //   this.socket.emit(
            //     "handleMicVideoProducer",
            //     {
            //       producerId: this.videoProducerId,
            //       state: true,
            //       kind: "video",
            //       storageId: this.currentVideoState,
            //       socketId: this.socket.id,
            //     },
            //     async (response) => {
            //       await this.videoProducer.pause();
            //     }
            //   );
            // }
          } catch (err) {
            $("#errorModal").modal("show");
            this.running_error = err;
            console.log(err);
          }
          // this.handleMic()
          // this.handleVideso()
          this.is_stream = false;
        } catch (e) {
          console.log("-----------e--------", e);
        }
      },
      async handleConsumer(data) {
        const {
          producerId,
          kind,
          id,
          paused,
          rtpParameters,
          screenShare,
          socketId,
          storageId,
          userDetails,
          muted,
        } = data;
  
        let codecOptions = {};
        let mutedAudio = false;
        const consumer = await this.receiveTransport[data?.storageId].consume({
          id,
          producerId,
          kind,
          rtpParameters,
          codecOptions,
        });
        this.consumers.set(data?.storageId, consumer);
  
        const mediaStream = new MediaStream();
        mediaStream.addTrack(consumer.track);
        console.log("-mediaStream", mediaStream);
        console.log("-consumer", consumer);
        console.log("-ppppppp", data);
        console.log("-this.peers", this.peers);
  
        if (this.peers.some((peer) => peer.socketId === socketId)) {
          if (kind === "video") {
            if (this.recordingRef) {
              this.mixer?.appendStreams(mediaStream)
            }
            if (screenShare) {
              var video = document.getElementById(socketId + "STREAM_SCR_SRC");
              this.screenShareLoader = true;
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == false) {
                  console.log("*****1*******");
                  this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                } else {
                  this.mainScreenStreamRef = null;
                }
              } else {
              }
              this.mainScreenStreamRef = {
                socketId: socketId,
                storageId: storageId,
                is_autoplay: false,
                screenShare: screenShare,
                userDetails: userDetails,
                remoteScreen: true,
                paused: paused,
                mutedVideo: paused,
                raiseHand: false,
                kind: kind,
                mediaStream,
              };
              document.getElementById("Screen").style.display = "flex";
            } else {
              console.log();
              if (this.mainScreenStreamRef) {
                if (
                  this.mainScreenStreamRef.socketId == socketId &&
                  this.mainScreenStreamRef.screenShare == false
                ) {
                  this.mainScreenStreamRef.mediaStream = mediaStream;
                } else {
                  var video = document.getElementById(socketId + "STREAM_SRC");
                  // video.srcObject = mediaStream;
                  console.log("-----", mediaStream);
                  this.updateCamStream(socketId, mediaStream);
                  this.updateCamStatus(socketId, false);
                  // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                  // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
                }
              } else {
                var video = document.getElementById(socketId + "STREAM_SRC");
                // video.srcObject = mediaStream;
                console.log("-----", mediaStream);
                this.updateCamStream(socketId, mediaStream);
                this.updateCamStatus(socketId, false);
                // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
              }
              // video.autoplay = true;
            }
          } else {
            if (muted == true) {
              this.audioMicClose.push(socketId);
            }
            const audioElem = document.createElement("audio");
            audioElem.autoplay = true;
            audioElem.srcObject = mediaStream;
            // audioElem.play();
            audioElem.id = data.storageId;
            this.audioElement.push(data.storageId);
            this.audioElementSocket.push({
              storageId: data.storageId,
              socketId: data.socketId,
            });
            console.log("*************************");
            console.log(this.selectedOuterAudio);
            console.log(this.role);
            console.log("*************************");
            document.body.appendChild(audioElem);
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
            if (!screenShare) {
              this.updateCamStatus(socketId, true);
            }
            this.audioPeersRef = [
              ...this.audioPeersRef,
              {
                socketId: socketId,
                storageId: storageId,
                mediaStream: mediaStream,
                consumer: consumer,
              },
            ];
            // if (this.role == "VIEWER") {
            // } else {
            //   this.changeOuterSpeaker(this.selectedOuterAudio);
            // }
          }
        } else {
          this.peerShow.push({
            is_show: true,
            socketId: socketId,
            is_autoplay: false,
            storageId: storageId,
            screenShare: screenShare,
            userDetails: userDetails,
            paused: paused,
            mutedVideo: paused,
            is_mute: false,
            is_ban: false,
            raiseHand: false,
            kind: kind,
            mediaStream,
          });
  
          this.peers.push({
            is_show: true,
            socketId: socketId,
            storageId: storageId,
            is_autoplay: false,
            screenShare: screenShare,
            userDetails: userDetails,
            raiseHand: false,
            is_mute: false,
            is_ban: false,
            paused: paused,
            mutedVideo: paused,
            kind: kind,
            mediaStream,
          });
          // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
          // this.peerShow = peerFilter;
  
          this.peersRef = [
            ...this.peersRef,
            {
              kind: kind,
              is_show: true,
              raiseHand: false,
              paused: paused,
              userDetails: userDetails,
              socketId: socketId,
              storageId: storageId,
              mutedVideo: paused,
              mediaStream,
            },
          ];
  
          if (kind === "video") {
            if (this.recordingRef) {
              this.mixer?.appendStreams(mediaStream)
            }
            if (screenShare) {
              var video = document.getElementById(socketId + "STREAM_SCR_SRC");
              this.screenShareLoader = true;
              if (this.mainScreenStreamRef) {
                if (this.mainScreenStreamRef.screenShare == false) {
                  console.log("*****1*******");
                  this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                } else {
                  this.mainScreenStreamRef = null;
                }
              } else {
              }
              this.mainScreenStreamRef = {
                socketId: socketId,
                storageId: storageId,
                is_autoplay: false,
                screenShare: screenShare,
                userDetails: userDetails,
                remoteScreen: true,
                paused: paused,
                mutedVideo: muted,
                raiseHand: false,
                kind: kind,
                mediaStream,
              };
              document.getElementById("Screen").style.display = "flex";
            } else {
              var video = document.getElementById(socketId + "STREAM_SRC");
              // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
              // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
              // video.srcObject = mediaStream;
              this.updateCamStream(socketId, mediaStream);
              this.updateCamStatus(socketId, false);
              // video.autoplay = true;
            }
  
          } else {
            if (muted == true) {
              this.audioMicClose.push(socketId);
            }
            const audioElem = document.createElement("audio");
            audioElem.autoplay = true;
            audioElem.srcObject = mediaStream;
            // audioElem.play();
            audioElem.id = data.storageId;
            this.audioElement.push(data.storageId);
            this.audioElementSocket.push({
              storageId: data.storageId,
              socketId: data.socketId,
            });
            console.log("*************************");
            console.log(this.selectedOuterAudio);
            console.log(this.role);
            console.log("*************************");
            document.body.appendChild(audioElem);
            if (!screenShare) {
              this.updateCamStatus(socketId, true);
            }
  
            this.audioPeersRef = [
              ...this.audioPeersRef,
              {
                socketId: socketId,
                storageId: storageId,
                mediaStream: mediaStream,
                consumer: consumer,
              },
            ];
          }
        }
  
        if (this.recordingRef) {
          this.resetVideoRec()
        }
  
        this.filterdParticipants = this.peerShow.filter(
          (a) => a.userDetails.webinarUserType == "HOST"
        );
        this.handleAudioLevel();
        this.manageDiv();
  
        console.log("newConsume______r", consumer?.track, screenShare);
        console.log("this.peersRef___________", this.peersRef);
      },
      async handleAudioLevel() {
        // for (let i = 0; i < this.audioElement.length; i++) {
        //   const element = this.audioElement[i];
        //   var x = document.getElementById(element);
        //   x.volume = this.audioLevel / 10 ;
        //
        // }
        // document.getElementById("myAudio").volume = 0.2;
        console.log('handleAudioLevel----', this.audioLevel)
      },
      async playStream(storageId, socketId) {
        console.log("_____-__-----____", this.audioElementSocket);
        console.log("s_____________", { storageId, socketId });
        let obj = this.audioElementSocket.find((o) => o.socketId == socketId);
        console.log("obj", obj);
  
        if (obj.length != 0) {
          var x = document.getElementById(obj.storageId);
          x.autoplay = true;
          x.play();
  
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              this.mainScreenStreamRef?.screenShare == false
            ) {
              this.mainScreenStreamRef.is_autoplay = true;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].is_autoplay = true;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].is_autoplay = true;
            }
          }
        }
        document.getElementById(socketId + "_PEER_PLAY").style.display = "none";
        if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
          document.getElementById(socketId + "_PEER_IMAGE").style.display = "flex";
        }
      },
      async joinAsViewer() {
        this.is_disable = true;
        this.role = "VIEWER";
        for (let i = 0; i < this.audioElement.length; i++) {
          const element = this.audioElement[i];
          var x = document.getElementById(element);
          x.autoplay = true;
          x.load();
        }
        $("#DeniedModal").modal("hide");
        $("#enterNameModal").modal("hide");
        $("#joinByViewerNameModal").modal("hide");
        this.joinMeeting();
      },
      async closeMeetingModal() {
        this.$router.push("/login");
      },
      async consume(data) {
        console.log("consume---", data);
        this.receiveTransport[
          data?.storageId
        ] = await this.mediasoupDevice?.createRecvTransport(data.data);
  
        this.receiveTransport[data?.storageId].on(
          "connect",
          ({ dtlsParameters }, callback, errback) => {
            this.socket.emit("transportConnect", {
              dtlsParameters,
              storageId: data?.storageId,
            });
            this.socket.on("consumerTransportConnected", (storageId) => {
              if (storageId === data?.storageId) {
                callback();
              }
            });
          }
        );
  
        this.receiveTransport[data?.storageId].on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              console.log("Connecting To Stream!");
              break;
            case "connected":
              console.log("subscribed!");
              break;
            case "failed":
              console.log("Failed!");
              // window.location.reload()
              break;
            default:
              break;
          }
        });
  
        this.socket.emit("startConsuming", {
          roomId: this.roomId,
          rtpCapabilities: this.mediasoupDevice?.rtpCapabilities,
          storageId: data?.storageId,
          producerId: data?.producerId,
          socketId: data?.socketId,
          paused: false,
          screenShare: data?.screenShare,
        });
      },
      async startRecording() {
  
        try {
          // $("#stopRecordingModal").modal("hide");
          let finalStr;
          if (!window.chrome && "getDisplayMedia" in navigator.mediaDevices) {
            this.recStream = await navigator.mediaDevices.getDisplayMedia({
              video: true,
              preferCurrentTab: true,
            });
            finalStr = this.recStream;
          } else if ("getDisplayMedia" in navigator.mediaDevices) {
            this.recStream = await navigator.mediaDevices.getDisplayMedia({
              video: true,
              preferCurrentTab: true,
            });
            const canvas = document.createElement("canvas");
            canvas.width = 1080;
            canvas.height = 720;
            const offscreen = canvas.transferControlToOffscreen();
            this.workerRef = new Worker(new URL("./worker.js", import.meta.url));
  
            const element = document.getElementById("mainCont");
            const obj = element?.getBoundingClientRect();
  
            const mediaProcessor = new window.MediaStreamTrackProcessor(
              this.recStream?.getVideoTracks()[0]
            );
            const readable = mediaProcessor.readable;
  
            this.workerRef.postMessage({ offscreen, obj, readable }, [offscreen, readable]);
            finalStr = canvas.captureStream(30);
          } else {
            this.isMobile = true
            let streamsArr = [];
            this.localStreamRef.getVideoTracks()?.forEach((track) => {
              const medStr = new MediaStream();
              medStr.addTrack(track);
              streamsArr.push(medStr);
            });
  
            this.localScreenStreamRef?.getVideoTracks()?.forEach((track) => {
              const medStr = new MediaStream();
              medStr?.addTrack(track);
              streamsArr.push(medStr);
            });
            this.peersRef?.length !== 0 &&
              this.peersRef?.forEach((item) => {
                item?.mediaStream?.getVideoTracks()?.forEach((track, idx) => {
                  const medStr = new MediaStream();
                  medStr.addTrack(track);
                  streamsArr.push(medStr);
                });
              });
            this.mixer = new MultiStreamsMixer(streamsArr);
            this.recStream = this.mixer.getMixedStream();
            this.mixer.frameInterval = 1;
            this.mixer.startDrawingFrames();
            finalStr = this.recStream;
          }
          console.log("-0-- EEE");
  
          // this.dataProducer = await this.produceTransport?.produceData()
          // await new Promise((resolve, reject) => {
          //   setTimeout(() => {
          //     resolve()
          //   }, 3000)
          // })
          // await this.dataProducer?.send(JSON.stringify({ type: 'details', format: MediaRecorder.isTypeSupported('video/mp4') ? 'mp4' : 'mp4' }))
  
          console.log("-0-- GGGG");
  
          // this.recordingElem.disabled = true
          this.audioContextRef = new AudioContext();
  
          this.dest = this.audioContextRef.createMediaStreamDestination();
  
          this.localStreamRef.getAudioTracks()?.forEach((track) => {
            const medStr = new MediaStream();
            medStr.addTrack(track);
            const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
            this.sourceNodes.set("local", { mediaStream: medStr, node: streamSrc });
            streamSrc.connect(this.dest);
          });
  
          console.log("-0-- RRRR");
  
          this.localScreenStreamRef?.getAudioTracks()?.forEach((track) => {
            const medStr = new MediaStream();
            medStr?.addTrack(track);
            const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
            this.sourceNodes.set("screen", { mediaStream: medStr, node: streamSrc });
            streamSrc?.connect(this.dest);
          });
  
          this.audioPeersRef?.length !== 0 &&
            this.audioPeersRef?.forEach((item) => {
              item?.mediaStream?.getAudioTracks()?.forEach((track) => {
                const medStr = new MediaStream();
                medStr.addTrack(track);
                const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
                this.sourceNodes.set(item?.storageId, {
                  mediaStream: medStr,
                  node: streamSrc,
                });
                streamSrc.connect(this.dest);
              });
            });
  
          this.dest.stream?.addTrack(finalStr.getVideoTracks()[0]);
  
          if (window.navigator.brave != undefined) {
            if (window.navigator.brave.isBrave.name == "isBrave") {
              this.isBrave = true;
            } else {
  
              this.isBrave = false;
            }
          } else {
            this.isBrave = false;
          }
          try {
            const fileStream = StreamSaver.createWriteStream(
              `${uuidv4()}.${MediaRecorder.isTypeSupported("video/mp4") ||
                MediaRecorder.isTypeSupported("video/webm;codecs=h264")
                ? "mp4"
                : "webm"
              }`,
              {
                size: undefined,
                writableStrategy: undefined,
                readableStrategy: undefined,
              }
            );
            this.writer = fileStream.getWriter();
          } catch (e) {
            this.isBrave = true;
            // $("#errorModal").modal("show");
            // this.running_error = error ? error : 'Errr';
          }
  
          this.mediaRecorder = new MediaRecorder(this.dest?.stream, {
            mimeType: MediaRecorder.isTypeSupported("video/mp4")
              ? "video/mp4"
              : MediaRecorder.isTypeSupported("video/webm;codecs=h264")
                ? "video/webm;codecs=h264"
                : "video/webm",
          });
          console.log("-0-- MMMM");
  
  
          this.recordingRef = true;
          let hrs = 0;
          let min = 0;
          let sec = 0;
          let ms = 0;
  
          this.startTimer = setInterval(() => {
            sec++; //ms=ms+1;
            if (ms == 1000) {
              sec++;
              ms = 0;
            }
            if (sec == 60) {
              min++;
              sec = 0;
            }
            if (min == 60) {
              hrs++;
              min = 0;
            }
            this.updateDisplay(hrs, min, sec);
          }, 1000);
  
  
          this.mediaRecorder.ondataavailable = this.handleData;
  
          if (this.isBrave) {
            this.mediaRecorder?.start();
          } else {
            this.mediaRecorder?.start();
          }
          console.log("startRecordingNotify");
  
          this.socket.emit("startRecordingNotify", { roomId: this.roomId, username: this.user_name });
          this.recordingToaster = true;
          this.recStream
            ?.getVideoTracks()[0]
            ?.addEventListener("ended", () => this.stopRecording());
        } catch (error) {
          // $("#errorModal").modal("show");
          // this.running_error = error;
        }
      },
      async stopAskModal() {
        $("#stopRecordingModal").modal("show");
      },
      async returnToRoom() {
        $("#stopRecordingModal").modal("hide");
      },
      async handleData(e) {
        // console.log(e);
        try {
          if (e.data?.size === 0) return;
          this.savedChunks.push(e.data);
  
          this.downloadData();
          // if(this.isBrave){
          // }else{
  
          //   if (!this.processingRef) {
          //     this.processingRef = true;
          //     this.processChunks();
          //   }
          // }
          // if (this.isBrave) {
          //   // this.downloadData();
          // } else {
          // }
  
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
        // chunksRef.current?.push(e.data)
        // if (!processingRef.current) {
        //   processingRef.current = true
        //   processChunks()
  
        // }
      },
      async resetVideoRec() {
        let streamsArr = []
        console.log('-----> resetVideoRec')
  
        this.localStreamRef.getVideoTracks()?.forEach((track) => {
          const medStr = new MediaStream();
          medStr.addTrack(track);
          streamsArr.push(medStr)
        })
  
        this.localScreenStreamRef?.getVideoTracks()?.forEach((track) => {
          const medStr = new MediaStream();
          medStr?.addTrack(track);
          streamsArr.push(medStr)
        });
  
        this.peersRef?.forEach((item) => {
          item?.mediaStream?.getVideoTracks()?.forEach((track, idx) => {
            const medStr = new MediaStream();
            medStr.addTrack(track);
            streamsArr.push(medStr)
          });
  
        });
  
        this.localStreamRef.getAudioTracks()?.forEach((track) => {
          const medStr = new MediaStream();
          medStr.addTrack(track);
          const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
          this.sourceNodes.set("local", { mediaStream: medStr, node: streamSrc });
          streamSrc.connect(this.dest);
        });
  
        this.localScreenStreamRef?.getAudioTracks()?.forEach((track) => {
          const medStr = new MediaStream();
          medStr?.addTrack(track);
          const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
          this.sourceNodes.set("screen", { mediaStream: medStr, node: streamSrc });
          streamSrc?.connect(this.dest);
        });
  
        this.audioPeersRef?.length !== 0 &&
          this.audioPeersRef?.forEach((item) => {
            item?.mediaStream?.getAudioTracks()?.forEach((track) => {
              const medStr = new MediaStream();
              medStr.addTrack(track);
              const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
              this.sourceNodes.set(item?.storageId, {
                mediaStream: medStr,
                node: streamSrc,
              });
              streamSrc.connect(this.dest);
            });
          });
  
        this.mixer?.resetVideoStreams(streamsArr);
      },
      async downloadData() {
        try {
          if (!this.savedChunks) {
            this.mediaRecorder = null;
            return;
          }
  
          let device = '';
          if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|Opera Mini/i)) {
            device = true;
          } else {
            device = false;
          }
  
          if (!device) {
            const blob = await fixWebmDuration(new Blob(this.savedChunks, { type: 'video/webm' }));
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            //  var a = document.getElementById('yourlinkId')
            a.hidden = true;
            a.href = url;
            a.download = `record${uuidv4()}.webm`;
            if (!this.isBrave) {
            } else {
              a.target = '_blank';
            }
            a.click();
            setInterval(() => {
              URL.revokeObjectURL(url);
            }, 10000);
            a?.remove();
  
          } else {
  
            const blob = new Blob(this.savedChunks, {
              type: device ? "video/webm" : "video/mp4",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.hidden = true;
            a.href = url;
            a.download = device ? `record${uuidv4()}.webm` : `record${uuidv4()}.mp4`;
            if (!this.isBrave) {
            } else {
              a.target = '_blank';
            }
            a.click();
            setInterval(() => {
              URL.revokeObjectURL(url);
            }, 10000);
            a?.remove();
          }
  
          this.savedChunks = [];
          this.mediaRecorder = null;
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
      },
      async processChunks() {
        try {
          if (this.savedChunks.length === 0) {
            this.processingRef = false;
            if (!this.recordingRef) {
              this.writer?.close();
              this.writer = null;
            }
            return;
          }
          const chunk = this.savedChunks[0];
          const modifiedChunk = new Uint8Array(await chunk.arrayBuffer());
  
          try {
            await this.writer?.write(modifiedChunk);
          } catch (e) {
            this.stopRecording();
          }
          this.savedChunks.shift();
          this.processChunks();
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
      },
      async sendStrMessage(message) {
        const size = new Blob([message]).size;
        const limit = 200 * 1024;
        if (this.dataProducer?.bufferedAmount + size >= limit) {
          await new Promise((resolve, reject) => {
            this.dataProducer?.on("bufferedamountlow", async () => {
              await this.dataProducer?.send(message);
              resolve();
            });
          });
        } else {
          await this.dataProducer?.send(message);
        }
      },
      async sendChunk(chunk, chunkSize) {
        const limit = 200 * 1024;
        if (chunkSize > limit) {
          const slicedChunks = this.splitChunks(chunk);
          for (let i = 0; i < slicedChunks.length; i++) {
            if (this.dataProducer?.bufferedAmount + slicedChunks[i]?.size > limit) {
              await new Promise((resolve, reject) => {
                this.dataProducer?.once("bufferedamountlow", async () => {
                  const arrayBuff = await slicedChunks[i]?.arrayBuffer();
                  await this.dataProducer?.send(arrayBuff);
                  resolve();
                });
              });
            } else {
              const arrayBuff = await slicedChunks[i]?.arrayBuffer();
              await this.dataProducer.send(arrayBuff);
            }
          }
        } else {
          if (this.dataProducer?.bufferedAmount + chunkSize > limit) {
            await new Promise((resolve, reject) => {
              this.dataProducer?.once("bufferedamountlow", async () => {
                const arrayBuff = await chunk?.arrayBuffer();
                await this.dataProducer?.send(arrayBuff);
                resolve();
              });
            });
          } else {
            const arrayBuff = await chunk?.arrayBuffer();
            await this.dataProducer?.send(arrayBuff);
          }
        }
      },
      async splitChunks(chunk) {
        console.log("spliting");
        const chunkSize = 200 * 1024;
        let offset = 0;
        let chunks = [];
        while (offset !== chunk.size) {
          const lastLimit =
            chunkSize + offset > chunk.size ? chunk.size : chunkSize + offset;
          const newChunk = chunk?.slice(offset, lastLimit);
          chunks.push(newChunk);
          if (lastLimit === chunk.size) {
            offset = chunk.size;
          } else {
            offset += lastLimit;
          }
        }
        return chunks;
      },
      async stopRecording() {
        try {
          this.mediaRecorder?.stop();
          this.recordingRef = null;
          this.workerRef?.postMessage("close");
          this.workerRef?.terminate();
          this.workerRef = null;
          // toast.dismiss(this.recordingToaster);
          // this.globalToast = toast.loading("Converting...")
          this.sourceNodes?.forEach((value, key) => {
            value?.node?.disconnect();
            value?.mediaStream?.getTracks()?.forEach((track) => {
              // track?.stop()
            });
            this.sourceNodes?.delete(key);
          });
          this.dest?.stream?.getTracks((track) => {
            track?.stop();
          });
          this.dest?.disconnect();
          this.dest = null;
          await this.audioContextRef?.close();
          if (this.mixer) {
            this.mixer?.releaseStreams();
            this.mixer = null;
          }
          this.audioContextRef = null;
          this.recordingIcon = null;
          this.recordingRef = null;
          this.recordingTextElem = null;
          this.recordingPauseElem = null;
          this.recordingResumeElem = null;
          this.recordingToaster = null;
          this.recStream?.getTracks()?.forEach((track) => {
            track?.stop();
          });
          this.recStream = null;
          this.topRecording = false;
          this.socket.emit("stopRecordingNotify", { roomId: this.roomId, username: this.user_name });
  
          document.querySelector(".hrs").innerText = "00";
          document.querySelector(".min").innerText = "00";
          document.querySelector(".sec").innerText = "00";
          this.recordingToaster = false;
          $("#stopRecordingModal").modal("hide");
          // const $toast = useToast();
          // $toast.open({
          //   message: 'Your recording is converting is in progress. please wait a few minutes.',
          //   type: 'info',
          //   dismissible: false,
          //   duration: 30000
          //   // all of other options may go here
          // });
          clearInterval(this.startTimer);
          $("#errorModal").modal("hide");
  
  
        } catch (error) {
          $("#errorModal").modal("show");
          this.running_error = error;
        }
      },
      async updateDisplay(hrs, min, sec) {
        const phrs = hrs < 10 ? "0" + hrs : hrs;
        const pmin = min < 10 ? "0" + min : min;
        const psec = sec < 10 ? "0" + sec : sec;
        //Output
        this.hrs = phrs;
        this.min = pmin;
        this.sec = psec;
      },
      async goLive() {
        let body = {
          id: this.webinarId,
          childID: this.childId ?? undefined,
        };
        body = await liveWebinar(body);
        if (body.status == 200) {
          this.goLiveStatus = false;
        }
  
        // goLiveStatus
      },
      async ScreenShare() {
        console.log("__", this.mainScreenStreamRef);
        console.log("***********************");
  
        if (this.screenShareStarted) {
          const producerIds = this.audioProducerId2
            ? [this.videoProducerId2, this.audioProducerId2]
            : [this.videoProducerId2];
          this.socket.emit("closeScreenShare", producerIds, async (response) => {
            if (this.audioProducer2) {
              await this.audioProducer2?.close();
              this.audioProducer2 = null;
              this.audioProducerId2 = null;
            }
            if (this.videoProducer2) {
              await this.videoProducer2?.close();
              this.videoProducer2 = null;
              this.videoProducerId2 = null;
            }
            this.localScreenStreamRef?.getTracks()?.forEach((track) => {
              track.stop();
            });
            // this.mainScreenStreamRef = null
            console.log("_______R A J______", this.peers);
            console.log("_______this.socket.id______", this.socket.id);
            const peerFilter = this.peers.filter(
              (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
            );
            const peerSData = this.peerShow.filter(
              (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
            );
            this.peers = peerFilter;
            this.peerShow = peerSData;
            console.log("_______peerFilter______", peerFilter);
            console.log("_______peerSData______", peerSData);
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.screenShare == true) {
                document.getElementById("Screen").style.display = "none";
                this.mainScreenStreamRef = null;
              }
            }
            this.manageDiv();
          });
  
          this.screenShareStarted = false;
        } else {
          const checkUsername = (obj) => obj.screenShare == true;
  
          if (
            (this.mainScreenStreamRef != null &&
              this.mainScreenStreamRef.remoteScreen == true) ||
            this.peers.some(checkUsername) == true
          ) {
            swal({
              text: "This will let you take over from as ther main presenter.",
              buttons: true,
              dangerMode: true,
            }).then(async (willDelete) => {
              if (willDelete) {
                let obj = this.peers.find((o) => o.screenShare == true);
  
                if (obj) {
                  this.socket.emit("stopShareScreen", obj.socketId);
                  console.log("___obj----", obj);
                  console.log("___obj.storageId----", obj.storageId);
                  if (
                    document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV") != null
                  ) {
                    this.peers.push({ is_show: true, ...obj });
                    // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
                } else {
                  console.log("this.mainScreenStreamRef----", this.mainScreenStreamRef);
                  console.log(
                    "this.mainScreenStreamRef.storageId----",
                    this.mainScreenStreamRef.storageId
                  );
                  this.socket.emit("stopShareScreen", this.mainScreenStreamRef.socketId);
                  this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
                  if (
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                    ) != null
                  ) {
                    // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                  }
  
                  console.log("socketId----", this.mainScreenStreamRef.socketId);
                }
  
                const localScreenStream = await navigator?.mediaDevices?.getDisplayMedia({
                  audio: {
                    noiseSuppression: true,
                    echoCancellation: true,
                  },
                  video: true,
                });
                this.localScreenStreamRef = localScreenStream;
                // if (!this.localScreenStreamRef) return;
  
                // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
                const Audiotracks = this.localScreenStreamRef.getAudioTracks()[0];
                const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
                if (Audiotracks) {
                  this.audioProducer2 = await this.produceTransport?.produce({
                    track: Audiotracks,
                    appData: {
                      type: "screen",
                    },
                  });
                }
                if (Videotracks) {
                  this.videoProducer2 = await this.produceTransport?.produce({
                    track: Videotracks,
                    appData: {
                      type: "screen",
                    },
                  });
                }
                if (this.mainScreenStreamRef == null) {
                  document.getElementById("Screen").style.display = "flex";
                  this.screenShareLoader = true;
                }
                if (this.mainScreenStreamRef) {
                  this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                  document.getElementById("ALL_VIDEOS").style.display = "flex";
                }
                this.mainScreenStreamRef = {
                  storageId: this.socket.id + "SCREEN",
                  mediaStream: localScreenStream,
                  remoteScreen: false,
                  screenShare: true,
                  socketId: this.socket.id,
                  userDetails: {
                    user_name: this.user_name,
                    profileImg: this.profileImg,
                  },
                };
                this.localScreenStreamRef
                  .getVideoTracks()[0]
                  ?.addEventListener("ended", () => this.ScreenShare());
                this.manageDiv();
                this.screenShareStarted = true;
                this.screenShareLoader = false;
              }
            });
          } else {
            const localScreenStream = await navigator?.mediaDevices?.getDisplayMedia({
              audio: {
                noiseSuppression: true,
                echoCancellation: true,
              },
              video: true,
            });
            this.localScreenStreamRef = localScreenStream;
            // if (!this.localScreenStreamRef) return;
            // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
            const Audiotracks = this.localScreenStreamRef.getAudioTracks()[0];
            const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
            if (Audiotracks) {
              this.audioProducer2 = await this.produceTransport?.produce({
                track: Audiotracks,
                appData: {
                  type: "screen",
                },
              });
            }
            if (Videotracks) {
              this.videoProducer2 = await this.produceTransport?.produce({
                track: Videotracks,
                appData: {
                  type: "screen",
                },
              });
            }
            if (this.mainScreenStreamRef == null) {
              document.getElementById("Screen").style.display = "flex";
              this.screenShareLoader = true;
            }
            if (this.mainScreenStreamRef) {
              this.updateIsShow(this.mainScreenStreamRef.storageId, true);
              document.getElementById("ALL_VIDEOS").style.display = "flex";
            }
            this.mainScreenStreamRef = {
              storageId: this.socket.id + "SCREEN",
              mediaStream: localScreenStream,
              remoteScreen: false,
              screenShare: true,
              socketId: this.socket.id,
              userDetails: {
                user_name: this.user_name,
                profileImg: this.profileImg,
              },
            };
  
            this.localScreenStreamRef
              .getVideoTracks()[0]
              ?.addEventListener("ended", () => this.ScreenShare());
            this.screenShareStarted = true;
            this.screenShareLoader = false;
            // this.manageDiv()
          }
        }
      },
      async handleMic(state) {
        const stateVar = state ? state : this.currentMicState;
        console.log("this.currentMicState-----------", this.currentMicState);
  
        try {
          let userAgentString = navigator.userAgent;
          let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  
          this.socket.emit(
            "handleProducer",
            {
              producerId: this.audioProducerId,
              state: stateVar,
            },
            async (response) => {
              if (this.currentMicState) {
                await this.audioProducer.pause();
                this.currentMicState = false;
                this.audio = false;
              } else {
                await this.audioProducer.resume();
                this.currentMicState = true;
                this.audio = true;
              }
            }
          );
        } catch (error) {
          if (error.name == "NotAllowedError") {
            $("#microphoneDeniedModal").modal("show");
          } else {
            this.is_cam_allow = false;
            $("#errorModal").modal("show");
            this.running_error = error;
          }
        }
      },
      async handleVideo(state) {
        const stateVar = state ? state : this.currentVideoState;
        console.log("this.currentVideoState-----------", this.currentVideoState);
  
        try {
  
          if (stateVar) {
            console.log("Video permissions:");
  
            this.socket.emit("closeProducer", this.videoProducerId, async (response) => {
              await this.videoProducer?.close();
              this.videoProducer = null;
              this.videoProducerId = null;
  
              // this.localStreamRef?.getTracks()?.forEach((track) => {
              //   track?.stop();
              // });
              // this.localStreamRef?.getVideoTracks()?.forEach((track) => {
              //   track?.stop();
              //   this.localStreamRef?.removeTrack(track);
              // });
            });
            //   this.socket.emit('handleProducer', {
            //   producerId: this.videoProducerId,
            //   state: true
            // }, async (response) => {
            //     await this.videoProducer.pause()
            // console.log('this.videoProducer==============',this.videoProducer);
            // })
            console.log("closeProducer-----------", this.localStreamRef);
            const tracks1 = this.localStreamRef.getTracks();
            console.log("tracks-----------", tracks1);
            this.localStreamRef?.getVideoTracks()?.forEach((track) => {
              track?.stop();
            });
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.socketId == this.socket.id && user?.screenShare == false
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                const source = this.peerShow.find(
                  (user) => user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    console.log("PS :)  ", track.stop());
                    track.stop();
                  });
                }
                this.peerShow[objIndex].mediaStream = null;
                this.peerShow[objIndex].mutedVideo = true;
              }
            }
            this.currentVideoState = false;
            const socketId = this.socket.id;
  
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                this.mainScreenStreamRef.screenShare == false
              ) {
                //console.log("objIndex______", objIndex);
                if (this.mainScreenStreamRef.mediaStream) {
                  this.mainScreenStreamRef.mediaStream
                    .getVideoTracks()
                    .forEach(function (track) {
                      console.log("MS :)  ", track.stop());
                      track.stop();
                    });
                }
                this.mainScreenStreamRef.mediaStream = null;
                this.mainScreenStreamRef.mutedVideo = true;
              }
            }
  
            if (this.peers.length > 0) {
              const idx = this.peers?.findIndex(
                (peer) => peer?.socketId === socketId && peer?.screenShare === false
              );
  
              if (idx !== -1) {
                const source = this.peers.find(
                  (user) => user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    console.log("P :)  ", track.stop());
                    track.stop();
                  });
                }
                this.peers[idx].mediaStream = null;
                this.peers[idx].storageId = "LocalStream_storageId";
                this.peers[idx].mutedVideo = true;
              }
            }
  
            if (this.peersRef.length > 0) {
              const idx1 = this.peersRef?.findIndex(
                (peer) => peer?.socketId === socketId && peer?.screenShare === false
              );
              if (idx1 !== -1) {
                const source = this.peersRef.find(
                  (user) => user?.socketId == this.socket.id && user?.screenShare == false
                );
                //console.log("objIndex______", objIndex);
                if (source.mediaStream) {
                  source.mediaStream.getVideoTracks().forEach(function (track) {
                    track.stop();
                  });
                }
                this.peersRef[idx1].mediaStream = null;
                this.peersRef[idx1].storageId = "LocalStream_storageId";
                this.peersRef[idx1].mutedVideo = true;
              }
            }
            this.socket.emit("closeCamera", {
              roomId: this.roomId,
              socketId: this.socket.id,
              status: true,
            });
  
            console.log({
              roomId: this.roomId,
              socketId: this.socket.id,
              status: false,
            });
            this.video = false;
            if (this.recordingRef) {
              this.resetVideoRec()
            }
            // videoElem.current.innerHTML = "Video On";
          } else {
            console.log("Video permissions:");
            let userAgentString = navigator.userAgent;
            let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
  
            if (this.selectedVideo != undefined) {
              const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
                video: {
                  deviceId: { exact: this.selectedVideo },
                },
              });
  
              this.localStreamRef = localStreamRef;
            } else {
              const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
                video: true,
              });
              const videoTracks = localStreamRef.getVideoTracks();
              if (videoTracks.length > 0) {
                this.is_cam_allow = true;
  
                const videoDeviceId = videoTracks[0].getSettings().deviceId;
                this.selectedVideo = videoDeviceId;
                console.log("Video device ID:", videoDeviceId);
              } else {
                console.log("No video tracks found in the stream.");
              }
  
              this.localStreamRef = localStreamRef;
            }
  
            this.videoProducer = await this.produceTransport?.produce({
              track: this.localStreamRef.getVideoTracks()[0]
            });
  
            const socketId = this.socket.id;
  
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                this.mainScreenStreamRef.screenShare == false
              ) {
                this.mainScreenStreamRef.mediaStream = this.localStreamRef;
                this.mainScreenStreamRef.mutedVideo = false;
              }
            }
            const idx = this.peers?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );
  
            if (idx !== -1) {
              this.peers[idx].mediaStream = this.localStreamRef;
              this.peers[idx].storageId = "LocalStream_storageId";
              this.peers[idx].mutedVideo = false;
            }
            const idx1 = this.peerShow?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );
  
            if (idx1 !== -1) {
              this.peerShow[idx1].mediaStream = this.localStreamRef;
              this.peerShow[idx1].storageId = "LocalStream_storageId";
              this.peerShow[idx1].mutedVideo = false;
            }
            this.socket.emit("closeCamera", {
              roomId: this.roomId,
              socketId: this.socket.id,
              status: false,
            });
            if (this.recordingRef) {
              this.resetVideoRec()
            }
            this.video = true;
            this.currentVideoState = true;
          }
        } catch (error) {
          if (error.name == "NotAllowedError") {
            $("#cameraDeniedModal").modal("show");
  
          } else if (error.name == "NotFoundError") {
            this.stremErr = "NotFoundError";
            $("#DeniedModalCamera").modal("show");
          } else {
            this.is_cam_allow = false;
            $("#errorModal").modal("show");
            this.running_error = error;
          }
          console.log("error.name", error.name + ": " + error.message);
  
          console.log(error);
        }
      },
      async handleLeave() {
        window.close();
        window.location.href = window.origin;
      },
      async microphoneDeniedModal() {
        $("#microphoneDeniedModal").modal("hide");
      },
      async closeerrorModalModal() {
        $("#closeerrorModalModal").modal("hide");
      },
      async DismissModal(name) {
        if (name == 'DeniedModal') {
          this.is_disable = true;
          this.role = "VIEWER";
        }
        $("#" + name).modal("hide");
      },
      async cameraDeniedModal() {
        $("#cameraDeniedModal").modal("hide");
      },
      async DeniedModal() {
        window.location.reload();
        // $("#DeniedModal").modal("hide");
      },
      async closeDeniedModal() {
        window.location.reload();
      },
      async gotToLogin() {
        this.$router.push("/login");
      },
      async closeNotReadableErrorModal() {
        window.location.reload();
        $("#NotReadableError").modal("hide");
      },
      async closeTypeErrorModal() {
        document.getElementById("BtnTypeError").innerHTML = "Please wait..";
        window.location.reload();
        $("#TypeError").modal("hide");
      },
      async removeUser(socketId) {
        this.socket.emit("removeUser", socketId);
      },
      async renameSubmitUser(socketId) {
        console.log("___socketId_", socketId);
        if (this.edit_name != "") {
          this.socket.emit("renameUser", {
            name: this.edit_name,
            socketId: socketId,
            roomId: this.roomId,
          });
  
          this.edit_socketId = "";
          this.edit_name = "";
          $("#RenamePopup").modal("hide");
        } else {
          this.edit_name_error = "Please enter new name.";
          this.edit_join_btn = true;
        }
      },
      async renameUser(peer, socketId) {
        console.log("_____2___", peer, socketId);
        console.log("___this.edit_socketId_____", this.edit_socketId);
  
        if (socketId == this.socket.id) {
          this.edit_name = this.user_name;
          this.edit_socketId = this.socket.id;
        } else {
          this.edit_socketId = socketId;
          this.edit_name = peer.userDetails ? peer.userDetails.user_name : peer.user_name;
        }
  
        console.log("___3_____", peer, socketId);
        $("#RenamePopup").modal("show");
      },
      async closeRenameModal() {
        this.edit_socketId = "";
        this.edit_name = "";
        $("#RenamePopup").modal("hide");
      },
      async clickToMiniMiseStream(peer) {
        // console.log("__", peer);
        // console.log("this.peerShow", this.peerShow);
  
        if (this.mainScreenStreamRef) {
          if (this.mainScreenStreamRef.screenShare == true) {
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                // this.peers[objIndex].is_show = false;
              } else {
                this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
                // this.peerShow.push({ is_show: true, ...this.mainScreenStreamRef })
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex1 = this.peerShow.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex1);
              if (objIndex1 >= 0) {
                //console.log("objIndex______", objIndex1);
                // this.peers[objIndex].is_show = false;
              } else {
                // this.peers.push({ is_show: true, ...this.mainScreenStreamRef })
                this.peerShow.push({
                  is_show: true,
                  ...this.mainScreenStreamRef,
                });
              }
            }
          }
        }
        this.updateIsShow(this.mainScreenStreamRef.storageId, true);
  
        // this.mainScreenStreamRef = {...peer, mediaStream: video.srcObject};
  
        if (peer.screenShare == false) {
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
            }
          }
          if (this.peersRef.length > 0) {
            var objIndex = this.peersRef.findIndex(
              (user) => user?.socketId == peer.socketId && user?.screenShare == false
            );
  
            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peersRef[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
            }
          }
        }
        this.mainScreenStreamRef = null;
  
        // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true)
        // this.peerShow = peerFilter;
        // console.log("this.peerShow", this.peerShow);
  
        this.manageDiv();
        document.getElementById("Screen").style.display = "none";
  
        if (peer.screenShare == false) {
          if (peer.mutedVideo == true && peer.screenShare == false) {
            console.log("IFFFFFF", peer.socketId);
            // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "9" : '';
            // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
          } else {
            console.log("ELSEEEEEEE", peer.socketId);
  
            // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "10" : '';
            // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
          }
        }
      },
      async clickToBigStream(peer) {
        this.manageDiv();
  
        if (peer.screenShare == true) {
          var video = document.getElementById(peer.socketId + "STREAM_SCR_SRC");
        } else {
          var video = document.getElementById(peer.socketId + "STREAM_SRC");
        }
        // console.log('video.srcObject----------', video.srcObject);
        //console.log("__this.mainScreenStreamRef_____", this.mainScreenStreamRef);
        //console.log("__", peer);
        if ($("#" + peer.storageId + "_VIDEO").css("visibility") == "hidden") {
          var visible = true;
          document.getElementById("Screen").style.background = "#black";
  
          // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
          //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
          // }
        } else {
          document.getElementById("Screen").style.background = "black";
          var visible = false;
          // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
          //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
          // }
        }
  
        if (
          document.getElementById(
            "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          ) != null
        ) {
          document.getElementById(
            "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          ).style.visibility = "visible";
        }
  
        if (this.mainScreenStreamRef) {
          if (visible == false) {
            if (
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ).style.display = "flex";
            }
            // console.log("_____B____245____");
          } else {
            if (
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
              ).style.display = "none";
            }
          }
          if (this.mainScreenStreamRef.screenShare == true) {
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                // this.peers[objIndex].is_show = false;
              } else {
                this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex1 = this.peerShow.findIndex(
                (user) => user?.storageId == this.mainScreenStreamRef.storageId
              );
  
              //console.log("objIndex______", objIndex1);
              if (objIndex1 >= 0) {
                //console.log("objIndex______", objIndex1);
                // this.peerShow[objIndex].is_show = false;
              } else {
                this.peerShow.push({
                  is_show: true,
                  ...this.mainScreenStreamRef,
                });
              }
            }
          }
          if (
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
            ) != null
          ) {
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
            ).style.visibility = "visible";
          }
          this.updateIsShow(this.mainScreenStreamRef.storageId, true);
          this.updateIsShow(peer.storageId, false);
  
          //console.log("AHIYA AAVYU 1");
        } else {
          if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
            document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display =
              "none";
          }
          //console.log("AHIYA AAVYU 2");
          if (document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null) {
            //console.log("AHIYA AAVYU 3222");
            // document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
          }
        }
  
        this.updateIsShow(peer.storageId, false);
  
        // video.srcObject = mediaStream;
        this.mainScreenStreamRef = peer;
  
        if (document.getElementById(peer.storageId + "_STREAM_SCREEN_VIDEO") != null) {
          //console.log("AHIYA AAVYU 33333222fff");
          console.log(" exist 2", $("#Screen").css("display"));
        } else {
          //console.log("AHIYA AAVYU 4rrr");
          document.getElementById("Screen").style.display = "flex";
          if (document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null) {
            //console.log("AHIYA AAVYU 3rrrr");
          }
          //console.log(" offline slash does not exist! ", $("#Screen").css("display"));
        }
        if (document.getElementById("LOCAL_STREAM_VIDEO_DIV") != null) {
          document.getElementById("LOCAL_STREAM_VIDEO_DIV").style.display = "flex";
          document.getElementById("ALL_VIDEOS").style.display = "flex";
        }
        // this.screenShareStarted = true;
        // this.socket.emit('muteMic', socketId);
        this.manageDiv();
      },
      async updateIsShow(storageId, value) {
        //console.log("storageId,value______", storageId, value);
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex((user) => user?.storageId == storageId);
  
          //console.log("objIndex______", objIndex);
  
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].is_show = value;
          }
        }
        const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
        //console.log("this.peers______", this.peers);
        // console.log("peerFilter______", peerFilter);
        this.peerShow = peerFilter;
        this.manageDiv();
      },
      async updateCamStream(socketId, stream) {
        if (this.mainScreenStreamRef) {
          if (
            this.mainScreenStreamRef.socketId == socketId &&
            !this.mainScreenStreamRef.screenShare
          ) {
            this.mainScreenStreamRef.mediaStream = stream;
          }
        }
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          console.log("objIndex__::____", objIndex);
          if (objIndex >= 0) {
            console.log("objIndex__::2____", objIndex);
            this.peers[objIndex].mediaStream = stream;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          console.log("objIndex__::____", objIndex);
          if (objIndex >= 0) {
            console.log("objIndex__::3____", objIndex, stream);
            this.peerShow[objIndex].mediaStream = stream;
          }
        }
        if (this.peersRef.length > 0) {
          var objIndex = this.peersRef.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            console.log("objIndex__::4____", objIndex, stream);
            this.peersRef[objIndex].mediaStream = stream;
          }
        }
      },
      async updateCamStatus(socketId, status) {
        if (this.mainScreenStreamRef) {
          if (
            this.mainScreenStreamRef.socketId == socketId &&
            !this.mainScreenStreamRef.screenShare
          ) {
            this.mainScreenStreamRef.mutedVideo = status;
          }
        }
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].mutedVideo = status;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peerShow[objIndex].mutedVideo = status;
          }
        }
        if (this.peersRef.length > 0) {
          var objIndex = this.peersRef.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );
  
          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peersRef[objIndex].mutedVideo = status;
          }
        }
      },
      async muteMic(socketId, remote, action) {
        console.log("muteMic______", socketId, remote);
        const userName = this.user_name;
        if (socketId == this.socket.id) {
          this.handleMic();
        } else {
          this.socket.emit("muteMic", { socketId, remote, action, userName });
        }
      },
      async muteVideo(socketId, remote, action) {
        console.log("muteVideo______", socketId, remote, action);
  
        const userName = this.user_name;
        if (socketId == this.socket.id) {
          this.handleVideo();
        } else {
          this.socket.emit("muteVideo", { socketId, remote, action, userName });
        }
      },
      async stopMicVoice() {
        this.audio = false;
        this.publisher.publishAudio(false);
      },
      async startMicVoice() {
        this.audio = true;
        this.publisher.publishAudio(true);
      },
      async stopVideo() {
        this.video = false;
        this.publisher.publishVideo(false);
      },
      async startVideo() {
        console.log("startVideo---", this.video);
        this.publisher.publishVideo(true);
        this.video = true;
      },
      async changeMic(deviceId) {
        console.log("deviceData-------", this.deviceData);
        console.log("deviceId-------", deviceId);
        // const devices = await navigator.mediaDevices.enumerateDevices()
        //Here We Get The First Device's Device Id
        const device = deviceId.deviceId;
        //Get That Device's Stream
        if (deviceId.kind === "videoinput" || deviceId.kind === "video") {
          try {
            const stream = await navigator?.mediaDevices?.getUserMedia({
              video: {
                // deviceId: device
                //This Will Get The Device If Available, If Not Then It Will Get Some other Device
                //To Get Only The Device With Device Id Use,
                deviceId: { exact: device },
              },
            });
            this.selectedVideo = deviceId.deviceId;
            //Sending New Track To Mediasoup
            await this.videoProducer?.replaceTrack({
              track: stream.getVideoTracks()[0]
            });
  
            //Stop And Remove Previous Track From Local Stream
            this.localStreamRef?.getVideoTracks()?.forEach((track) => {
              track?.stop();
              this.localStreamRef?.removeTrack(track);
            });
            //Add New Track In Local Stream
            this.localStreamRef?.addTrack(stream.getVideoTracks()[0]);
  
            //Set That New Track In Video Element
            // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
          } catch (e) {
            if (error.name == "TypeError") {
              document.getElementById("BtnTypeError").innerHTML = "Try again";
              $("#TypeError").modal("show");
            }
  
            if (error.name == "NotReadableError") {
              document.getElementById("BtnNotReadableError").innerHTML = "Dismiss";
              $("#NotReadableError").modal("show");
              // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
            }
          }
        } else {
          try {
            const stream = await navigator?.mediaDevices?.getUserMedia({
              audio: {
                deviceId: device,
              },
              //This Will Get The Device If Available, If Not Then It Will Get Some other Device
              //To Get Only The Device With Device Id Use,
              //deviceId: {exact: device }
            });
  
            //Sending New Track To Mediasoup
            await this.audioProducer.replaceTrack({
              track: stream.getAudioTracks()[0],
            });
  
            //Stop And Remove Previous Track From Local Stream
            this.localStreamRef?.getAudioTracks()?.forEach((track) => {
              track?.stop();
              this.localStreamRef?.removeTrack(track);
            });
            //Add New Track In Local Stream
            this.localStreamRef?.addTrack(stream.getAudioTracks()[0]);
            this.selectedAudio = deviceId.deviceId;
            //Set That New Track In Video Element
            // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
          } catch (e) {
            if (error.name == "TypeError") {
              document.getElementById("BtnTypeError").innerHTML = "Try again";
              $("#TypeError").modal("show");
            }
  
            if (error.name == "NotReadableError") {
              document.getElementById("BtnNotReadableError").innerHTML = "Dismiss";
              $("#NotReadableError").modal("show");
              // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
            }
          }
        }
      },
      async changeOuterSpeaker(deviceId) {
        console.log("deviceData-------", this.deviceData);
        console.log("deviceId-------", deviceId);
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then((s) => {
            console.log("s-------", s);
            navigator.mediaDevices.enumerateDevices().then((devices) => {
              devices.forEach((device) => {
                console.log("device.label :", device);
              });
            });
          })
          .catch((error) => {
            console.log("Error :", error);
          });
  
        //Get That Device's Stream
        const sinkId = deviceId;
        if (!navigator.mediaDevices.selectAudioOutput) {
          console.log("selectAudioOutput() not supported.");
          // return;
        }
        // console.log('element-------', element);
        console.log("this.audioElement-------", this.audioElement);
        this.selectedOuterAudio = deviceId;
  
        if (this.audioElement.length > 0) {
          this.audioElement.forEach((item) => {
            const element = document.getElementById(item);
            console.log("element-------", element);
            console.log("element.sinkId-------", element.sinkId);
            if (typeof element.sinkId !== "undefined") {
              element
                .setSinkId(sinkId)
                .then(() => {
                  console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch((error) => {
                  let errorMessage = error;
                  if (error.name === "SecurityError") {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                  }
                  console.log(errorMessage);
                  // Jump back to first output device in the list as it's the default.
                  // audioOutputSelect.selectedIndex = 0;
                });
            } else {
              console.warn("Browser does not support output device selection.");
            }
          });
        }
      },
      searchInputHandler(e) {
        console.error(`EEEEEEEEEEEEEEEEEEE: `, e);
        // e.preventDefault();
        return false;
      },
      async getFilterParticipate(type) {
        if (type == "VIEWER") {
          this.socket.emit("fetchViewerUser", { room: this.roomId });
          this.filterdParticipants = this.viewers;
        } else {
          this.filterdParticipants = this.peerShow.filter(
            (a) => a.userDetails.webinarUserType == type
          );
        }
        console.log("type-------", type);
        console.log("this.peerShow-------", this.peerShow);
      },
      async MuteUser(email, mute, currentTab, userid) {
        document.getElementById("HANDLE_BUTTON_" + userid).style.display = "none";
        document.getElementById("UPDATING_" + userid).style.display = "flex";
  
        const body = {
          webinarId: this.webinarId,
          is_edit: 1,
          email: email,
          is_mute: mute,
        };
        await listupdateParticipants(body);
  
        var webinarParticipate = await webinarParticipateUserList(this.webinarId);
  
        if (webinarParticipate.data.code == 200) {
          this.participants = webinarParticipate.data.result.userId;
          this.filterdParticipants = webinarParticipate.data.result.userId.filter(
            (a) => a.webinarUserType == currentTab
          );
          document.getElementById("UPDATING_" + userid).style.display = "none";
          document.getElementById("HANDLE_BUTTON_" + userid).style.display = "block";
          // $('#UPDATING_' + userid).removeClass('box');
        }
      },
      async BannedUser(socketId) {
        // $('#UPDATING_' + userid).addClass('box');
        this.socket.emit("removeUser", socketId);
        // document.getElementById('HANDLE_BUTTON_' + userid).style.display = "none";
        // document.getElementById('UPDATING_' + userid).style.display = "flex";
        // const body = {
        //   webinarId: this.webinarId,
        //   is_edit: 1,
        //   email: email,
        //   is_ban: ban
        // }
  
        // await listupdateParticipants(body);
  
        // var webinarParticipate = await webinarParticipateUserList(this.webinarId);
  
        // if (webinarParticipate.data.code == 200) {
        //   this.participants = webinarParticipate.data.result.userId
        //   this.filterdParticipants = webinarParticipate.data.result.userId.filter(a => a.webinarUserType == currentTab);
        //   // $('#UPDATING_' + userid).removeClass('box');
        //   document.getElementById('UPDATING_' + userid).style.display = "none";
        //   document.getElementById('HANDLE_BUTTON_' + userid).style.display = "block";
  
        // }
      },
      chatInputHandler(e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          this.sendMessage();
        }
      },
      hideRaiseHand(socketId) {
        this.socket.emit("hideRaideHandForUser", socketId);
      },
      raiseHand() {
        if (this.isRaiseHand) {
          this.isRaiseHand = false;
        } else {
          this.isRaiseHand = true;
        }
  
        var daTa = {
          user: this.user_name,
          roomId: this.roomId,
          socketId: this.socket.id,
          is_show: this.isRaiseHand,
        };
        this.socket.emit("raise_hand", daTa);
      },
      sendMessage() {
        var rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
        this.chatMessage = this.chatMessage.replace(
          rexp,
          "<a href='$1' target='_blank'>$1</a>"
        );
  
        var daTa = {
          message: this.chatMessage,
          profileImg: this.profileImg,
          user: this.user_name,
          roomId: this.roomId,
          socketId: this.socket.id,
        };
        this.socket.emit("chat", daTa);
  
        this.chatMessage = "";
      },
      makeid(length) {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
      },
    },
  };
  </script>
  <style>
  /* Absolute Center Spinner */
  
  .outer-circle {
    margin-bottom: 13px;
    width: 80px;
    height: 80px;
    border: solid 5px white;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    animation: rotateCircle 1s linear infinite;
    padding: 2px;
    display: table;
  
    .inner-circle {
      height: 80px;
      width: 80px;
      border-style: solid;
      border-color: transparent;
      border-radius: 50%;
      border-width: 1px;
      animation: rotateCircle 1s linear infinite reverse;
      display: table;
      text-align: center;
    }
  
    .text {
      vertical-align: middle;
      display: table-cell;
      text-align: center;
    }
  }
  
  @keyframes rotateCircle {
    0% {
      transform: rotate(0);
    }
  
    25% {
      transform: rotate(90deg);
    }
  
    50% {
      transform: rotate(180deg);
    }
  
    75% {
      transform: rotate(270deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    color: white;
    border: 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .presentingScreen {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  
  #fixed_popup {
    position: absolute;
    transform: translate3d(404px, 536px, 0px) !important;
    top: 0px !important;
    left: 0px !important;
    will-change: transform !important;
  }
  
  #loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #444444;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
  
  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  .audio-icon-cont {
    position: relative;
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .audio-icon {
    margin: auto 0;
    width: 100%;
    height: 100%;
    /* background-color: white; */
    transform-origin: bottom;
    border-radius: 10px;
  }
  
  .audio-icon:nth-of-type(2) {
    animation-delay: -1s;
  }
  
  .audio-icon:nth-of-type(3) {
    animation-delay: -1.5s;
  }
  
  .animate-audio-icon {
    height: 100% !important;
    animation: bounce 1.2s ease infinite alternate;
  }
  
  @keyframes bounce {
    10% {
      transform: scaleY(0.3);
    }
  
    30% {
      transform: scaleY(1);
    }
  
    60% {
      transform: scaleY(0.5);
    }
  
    80% {
      transform: scaleY(0.75);
    }
  
    100% {
      transform: scaleY(0.6);
    }
  }
  
  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .animate-flicker {
    /* -webkit-animation: flickerAnimation 0.5s infinite;
    -moz-animation: flickerAnimation 0.5s infinite;
    -o-animation: flickerAnimation 0.5s infinite;
    animation: flickerAnimation 0.5s infinite; */
  
    border: 3px solid #2d8cff !important;
  }
  
  .RecordSec {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 7px;
  }
  
  .recordAnimation {
    animation: record 1.5s ease infinite normal;
  }
  
  @keyframes record {
    10% {
      opacity: 0.1;
    }
  
    30% {
      opacity: 0.3;
    }
  
    60% {
      opacity: 0.6;
    }
  
    80% {
      opacity: 0.8;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .disableTopIcon {
    pointer-events: none;
    cursor: no-drop;
    color: #9ca3af9c !important;
    background: #80808024;
    border-radius: 9px;
  }
  
  .disableTopIconMain {
    cursor: no-drop !important;
    /* background: #80808024; */
    border-radius: 9px;
  }
  
  .disableBottomIcon {
    pointer-events: none;
    cursor: no-drop;
  }
  
  .disableTopIconText {
    background: #9ca3af9c;
  }
  
  .disableTopIconSVG {
    fill: #9ca3af9c !important;
  }
  
  .play-button {
    cursor: pointer;
    z-index: 999999;
    background: black;
    border-radius: 50%;
  }
  
  .topRecordPanel {
    border-radius: 8px;
    margin: 5px;
    padding: 6px;
    border: 2px solid red;
    color: white;
  }
  
  .closeIconModal {
    border: 2px solid black;
    position: absolute;
    right: 12px;
    border-radius: 68px;
    padding: 4px 10px;
    margin: 0;
    cursor: pointer;
  }


  </style>