<template>
  <div id="main_div"
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <div style="z-index: 9999" id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
      <div class="kt-header-mobile__logo">
        <a href="demo3/index.html">
          <img alt="Logo" src="../../assets/media/site-logo.png" />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler">
          <span></span>
        </button>
        <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
          <span></span>
        </button>
      </div>
    </div>
    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
          <!-- begin:: Aside -->
          <div class="left-menu-container">
            <div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
              <div class="kt-aside__brand-logo">
                <a href="index.html">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.781" height="17.09" viewBox="0 0 22.781 17.09">
                    <g id="Group_113" data-name="Group 113" transform="translate(-57.216 -412.597)">
                      <path id="Path_137" data-name="Path 137"
                        d="M79.235,415.385a1.572,1.572,0,0,0-1.564-.073l-1.688.852a1.84,1.84,0,0,0-1.011,1.657v6.642a1.848,1.848,0,0,0,1.011,1.659l1.687.85a1.563,1.563,0,0,0,.719.175,1.594,1.594,0,0,0,.846-.246A1.627,1.627,0,0,0,80,425.513v-8.74a1.629,1.629,0,0,0-.762-1.388Z"
                        fill="#2d8cff"></path>
                      <path id="Path_138" data-name="Path 138"
                        d="M68.5,429.681H61.9a4.452,4.452,0,0,1-4.684-4.623v-7.831A4.455,4.455,0,0,1,61.9,412.6h6.6a4.453,4.453,0,0,1,4.684,4.624v7.831a4.452,4.452,0,0,1-4.684,4.623Z"
                        fill="#2d8cff"></path>
                    </g>
                  </svg>
                  <label class="main-menu-label">Live Room</label>
                </a>
              </div>
            </div>
            <!-- end:: Aside -->
            <!-- begin:: Aside Menu -->
            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
              <div id="kt_aside_menu" class="kt-aside-menu kt-aside-menu--dropdown" data-ktmenu-vertical="1"
                data-ktmenu-dropdown="1" data-ktmenu-scroll="0">
                <ul class="left-main-menu">
                  <li>
                    <a href="participants.html">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.935" height="28.669" viewBox="0 0 22.935 28.669">
                        <g id="_-System-Icons" data-name="🔍-System-Icons" transform="translate(-8 -4)">
                          <g id="ic_fluent_person_48_regular" transform="translate(8 4)">
                            <path id="_-Color" data-name="🎨-Color"
                              d="M27.889,21.2a3.045,3.045,0,0,1,3.042,2.9l0,.148v.538a6.828,6.828,0,0,1-3.526,6.006,15.476,15.476,0,0,1-7.941,1.878,15.476,15.476,0,0,1-7.941-1.878A6.835,6.835,0,0,1,8,25.028l0-.243v-.538A3.045,3.045,0,0,1,10.9,21.2l.148,0Zm0,1.792H11.046A1.254,1.254,0,0,0,9.8,24.144l0,.1v.538a5.043,5.043,0,0,0,2.666,4.476,13.725,13.725,0,0,0,7.009,1.616,13.725,13.725,0,0,0,7.009-1.616,5.051,5.051,0,0,0,2.663-4.267l0-.209v-.538A1.254,1.254,0,0,0,27.992,23ZM19.468,4A7.167,7.167,0,1,1,12.3,11.167,7.167,7.167,0,0,1,19.468,4Zm0,1.792a5.375,5.375,0,1,0,5.375,5.375A5.375,5.375,0,0,0,19.468,5.792Z"
                              transform="translate(-8 -4)" fill="#2d8cff"></path>
                          </g>
                        </g>
                      </svg>
                      <label class="main-menu-label">Manage Participants</label>
                    </a>
                  </li>
                  <li>
                    <a href="media.html">
                      <svg id="media-library-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                        viewBox="0 0 32 32">
                        <path id="Path_494" data-name="Path 494" d="M13,15v8h0l7-4Z" fill="#b6b6b6"></path>
                        <path id="Path_495" data-name="Path 495"
                          d="M26,28H6a2,2,0,0,1-2-2V12a2,2,0,0,1,2-2H26a2,2,0,0,1,2,2V26A2,2,0,0,1,26,28ZM6,12V26H26V12Z"
                          fill="#b6b6b6"></path>
                        <rect id="Rectangle_260" data-name="Rectangle 260" width="20" height="2"
                          transform="translate(6 6)" fill="#b6b6b6"></rect>
                        <rect id="Rectangle_261" data-name="Rectangle 261" width="16" height="2"
                          transform="translate(8 2)" fill="#b6b6b6"></rect>
                        <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" width="32" height="32"
                          fill="none"></rect>
                      </svg>
                      <label class="main-menu-label">Media Manager</label>
                    </a>
                  </li>
                  <li>
                    <a v-on:click="BackToDashboard()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.794" height="25.054" viewBox="0 0 22.794 25.054">
                        <g id="Group_115" data-name="Group 115" transform="translate(-57.604 -590.556)">
                          <path id="Path_125" data-name="Path 125"
                            d="M62.8,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_126" data-name="Path 126"
                            d="M67.867,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_127" data-name="Path 127"
                            d="M72.921,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_128" data-name="Path 128"
                            d="M62.8,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,62.8,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_129" data-name="Path 129"
                            d="M67.867,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,67.867,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_130" data-name="Path 130"
                            d="M72.921,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,72.921,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_131" data-name="Path 131"
                            d="M74.743,591.7a1.139,1.139,0,0,0-2.278,0v.659H65.544V591.7a1.139,1.139,0,0,0-2.278,0v.7a6.048,6.048,0,0,0-5.655,6.381v10.3A6.488,6.488,0,0,0,59.42,613.9a6.664,6.664,0,0,0,4.764,1.708h9.633a6.719,6.719,0,0,0,4.762-1.679,6.355,6.355,0,0,0,1.811-4.757V598.781a5.9,5.9,0,0,0-5.646-6.384Zm-2.278,2.936v.811a1.139,1.139,0,0,0,2.278,0v-.753a3.964,3.964,0,0,1,2.271.987,4.069,4.069,0,0,1,1.1,3.1v.206H59.889v-.2A4.085,4.085,0,0,1,61,595.68a3.98,3.98,0,0,1,2.269-.988v.752a1.139,1.139,0,0,0,2.278,0v-.813Zm-12.577,6.627v7.818a4.234,4.234,0,0,0,1.116,3.178,4.412,4.412,0,0,0,3.179,1.072h9.633a4.472,4.472,0,0,0,3.189-1.049,4.1,4.1,0,0,0,1.106-3.11v-7.909Z"
                            fill="#b6b6b6"></path>
                        </g>
                      </svg>
                      <label class="main-menu-label">Calendar</label>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.778" height="22.765" viewBox="0 0 22.778 22.765">
                        <g id="Group_116" data-name="Group 116" transform="translate(-57.632 -682.86)">
                          <path id="Path_123" data-name="Path 123"
                            d="M69.021,692.347a1.9,1.9,0,1,0,1.342.556,1.9,1.9,0,0,0-1.342-.556Zm-3.8,1.9a3.8,3.8,0,1,1,1.112,2.683,3.794,3.794,0,0,1-1.112-2.683Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_124" data-name="Path 124"
                            d="M69.021,684.758a.949.949,0,0,0-.949.948v.165a2.513,2.513,0,0,1-1.525,2.3.99.99,0,0,1-.25.069,2.514,2.514,0,0,1-2.59-.577l-.008-.006-.057-.057a.956.956,0,0,0-.308-.206.941.941,0,0,0-.727,0,.955.955,0,0,0-.308.206h0a.947.947,0,0,0-.206,1.034.95.95,0,0,0,.206.308l.065.065a2.577,2.577,0,0,1-1.778,4.364h-.107a.949.949,0,1,0,0,1.9h.165a2.523,2.523,0,0,1,1.795,4.287l-.007.008-.057.057a.947.947,0,0,0,0,1.342h0a.925.925,0,0,0,.308.206.941.941,0,0,0,.727,0,.925.925,0,0,0,.308-.206l.065-.065a2.578,2.578,0,0,1,4.365,1.777v.108a.949.949,0,0,0,1.9,0v-.165a2.526,2.526,0,0,1,4.289-1.8l.008.007.057.057a.956.956,0,0,0,.308.206.953.953,0,0,0,.727,0,.955.955,0,0,0,.308-.206h0a.948.948,0,0,0,0-1.342l-.065-.065a2.526,2.526,0,0,1,1.795-4.288h.089a.949.949,0,1,0,0-1.9H77.4a2.512,2.512,0,0,1-2.3-1.523.95.95,0,0,1-.068-.25,2.513,2.513,0,0,1,.576-2.589l.007-.008.057-.057a.947.947,0,0,0,0-1.342h0a.925.925,0,0,0-.308-.206.941.941,0,0,0-.727,0,.925.925,0,0,0-.308.206l-.065.064A2.526,2.526,0,0,1,69.97,685.8v-.09a.949.949,0,0,0-.278-.671.952.952,0,0,0-.672-.277Zm7.023,12.333.868.383a.616.616,0,0,0,.121.677l.052.053a2.846,2.846,0,0,1,0,4.028l-.671-.672.672.67a2.847,2.847,0,0,1-4.028,0h0l-.052-.052a.616.616,0,0,0-.678-.121h-.009a.619.619,0,0,0-.374.563v.159a2.847,2.847,0,0,1-5.694,0v-.072a.616.616,0,0,0-.4-.555l-.056-.023a.616.616,0,0,0-.678.121l-.052.052a2.848,2.848,0,1,1-4.028-4.027l.052-.052a.617.617,0,0,0,.121-.678v-.009a.618.618,0,0,0-.563-.373h-.165a2.847,2.847,0,1,1,0-5.693h.072a.616.616,0,0,0,.556-.4c.007-.019.014-.037.023-.055a.618.618,0,0,0-.121-.678l-.052-.052a2.847,2.847,0,1,1,4.028-4.022l.052.052a.621.621,0,0,0,.678.121.965.965,0,0,1,.189-.061.617.617,0,0,0,.27-.506v-.16a2.847,2.847,0,0,1,5.694,0v.083a.618.618,0,0,0,.374.563h.009a.616.616,0,0,0,.678-.121l.052-.052a2.848,2.848,0,1,1,4.028,4.027h0l-.052.052a.616.616,0,0,0-.121.677.954.954,0,0,1,.06.189.614.614,0,0,0,.507.27h.159a2.846,2.846,0,1,1,0,5.693h-.083a.618.618,0,0,0-.563.373Z"
                            fill="#b6b6b6"></path>
                        </g>
                      </svg>
                      <label class="main-menu-label">Settings</label>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end:: Aside Menu -->
        </div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper responsiv__space" id="kt_wrapper"
          style="padding-top: 60px !important;">
          <div id="kt_content" style="
              padding-top: 0;
              padding-bottom: 0;
    margin: 17px;
              overflow: hidden;
              position: relative;
              justify-content: center;
            ">
            <div class="top-title resp-top-spacing mb-4">
              <h3 class="text-white" style="
    font-weight: bold;
    font-size: 24px;">Webinars Recordings ({{ record.length }})</h3>

              <span @click="deleteIds.length ? deleteRecording() : ''" type="button"
                :style="deleteIds.length == 0 ? 'opacity: 0.7;cursor: no-drop;' : ''" class="btn btn-blue">

                {{ deleteIds.length ? 'Delete (' + deleteIds.length + ')' : 'Delete' }}
              </span>
            </div>
            <!-- begin:: Content -->
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid" style="
              border: 4px solid #2d8cff;
    border-radius: 12px;">



              <div class="webinar-recordings-list">
                <div class="table-responsive" style="
    overflow-y: scroll;
    height: calc(100vh - 20vh);
    padding: 16px;"
                  :style="data_loader ? 'display: flex; align-items: center; justify-content: center;' : record.length == 0 ? 'display: flex; align-items: center' : ''">
                  <div class="card-body" v-if="data_loader" style="
                      display: flex;
                      justify-content: center;
                      position: absolute;
                      margin-bottom: 35px;
                    ">
                    <span class="cutome-spinner"></span>
                  </div>

                  <div v-if="record.length == 0 && !data_loader" class="card-body" style="
                      display: flex;
                      justify-content: center;
                    ">
                    <span style="color: white;">No Recordings Available</span>
                  </div>

                  <table v-if="record.length" class="table no-border table-videos">
                    <tbody>
                      <tr>
                        <td>
                          <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                            <input :checked="isAllChecked" type="checkbox" @click="addToDelete(0, 'ALL')">
                            <span></span>
                          </label>
                        </td>
                        <td class="weight-700 text-white" style="min-width: 125px;">Date Time</td>
                        <td class="weight-700 text-white" style="min-width: 400px;">Title</td>
                        <td class="weight-700 text-white" style="min-width: 130px;">Duration</td>
                        <td class="weight-700 text-white" style="min-width: 150px;">Download</td>
                      </tr>


                      <tr v-for="records in record">
                        <td>
                          <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                            <input :checked="deleteIds.some((data) => data === records._id) ? true : false"
                              type="checkbox" @click="addToDelete(records._id, 'SINGLE')">
                            <span></span>
                          </label>
                        </td>
                        <td class=" text-white">{{ records.date }}</td>
                        <td class=" text-white">{{ records.title }}</td>
                        <td class=" text-white">{{ records.duration }}</td>
                        <td class=" text-white">
                          <span style="width: 150px; position: relative" class="download-vido"
                            :class="downloadLoader.some((data) => data === records.downloadLink) ? 'recordAnimation' : ''"
                            @click="downloadLoader.some((data) => data === records.downloadLink) ? '' : downloadRecording(records.downloadLink)">
                            <span v-if="downloadLoader.some((data) => data === records.downloadLink)"
                              class="dspinner spinner-white spinner-right"></span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="62" viewBox="0 0 70 62">
                              <g id="Group_434" data-name="Group 434" transform="translate(-1183 -637)">
                                <g id="Group_300" data-name="Group 300">
                                  <rect id="Rectangle_170" data-name="Rectangle 170" width="70" height="62" rx="9"
                                    transform="translate(1183 637)" fill="#2d8cff"></rect>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)" id="Path_479"
                                    data-name="Path 479"
                                    d="M28,22.5v5.222a2.611,2.611,0,0,1-2.611,2.611H7.111A2.611,2.611,0,0,1,4.5,27.722V22.5"
                                    transform="translate(1201.5 649.167)" fill="none" stroke="#fff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="3"></path>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)" id="Path_480"
                                    data-name="Path 480" d="M10.5,15l6.528,6.528L23.556,15"
                                    transform="translate(1200.722 650.139)" fill="none" stroke="#fff"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                                  <path v-if="!downloadLoader.some((data) => data === records.downloadLink)" id="Path_481"
                                    data-name="Path 481" d="M18,20.167V4.5" transform="translate(1199.75 651.5)"
                                    fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="3"></path>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- end:: Content -->
          </div>

        </div>


        <div class="modal fade bd-example-modal-xl" id="warningModal" tabindex="-1" role="dialog" aria-hidden="true"
          style="background: #000000bf;">
          <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content" style="background: #fff;">
              <div class="border-dotted">
                <div class="modal-body">
                  <div class="row" style="margin-top: 14px;">
                    <div class="col-md-12">
                      <h3 class="text-dark weight-700">
                        Are you sure you want to Delete Recordings?
                      </h3>
                      <hr style="margin-bottom: 0;" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px;">
                <span v-on:click="FinalDeleteRecords()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                  style="width: 150px; background: #b60808; color: #fff;position: relative" data-toggle="modal"
                  data-target="#add-new-webinar-calendar">
                  Yes
                  <span v-if="is_delete_loader" class="spinner spinner-white spinner-right"></span>
                </span>
                <span v-on:click="closewarningModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                  style="width: 150px; background: green; color: #fff;" data-toggle="modal"
                  data-target="#add-new-webinar-calendar">
                  No
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("pageshow", function (event) {
  var historyTraversal = event.persisted,
    perf = window.performance,
    perfEntries = perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
    perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
    navigationType = perf && perf.navigation && perf.navigation.type;
  if (historyTraversal || perfEntryType === "back_forward" || navigationType === 2) {
    // Handle page restore.
    window.location.reload(true);
  }
});

import { ref, reactive } from "vue";
import axios from "axios";
import {
  getRecording,
  downloadRecording,
  deleteRecording,
  unlinkRecording,
} from "../services/service";
import io from "socket.io-client";

axios.defaults.headers.post["Content-Type"] = "application/json";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    Vue.nextTick(() => {
      document.title = "UW - Unity Webinar";
    });
    return {
      chunks: ref([]),
      record: [],
      is_delete_loader: false,
      isAllChecked: false,
      deleteIds: [],
      downloadLoader: [],
      namea: '',
      progress: 5,
      data_loader: true,
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      socket: null,
      BASE_URL:
        window.location.host == "unitywebinar.com" ? "https://api.unitywebinar.com/" : window.location.host == "demo.unitywebinar.com" ? "https://demoapi.unitywebinar.com/" : "https://dev-api.unitywebinar.com/",
    };
  },
  computed: {
  },
  mounted: async function () {

  },

  async created() {

    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      await this.loadData();
    }

  },

  methods: {
    async BackToDashboard() {
      this.$router.push("/dashboard");
    },
    async closewarningModal() {
      $("#warningModal").modal("hide");
    },
    async addToDelete(id, type) {
      if (type == 'ALL') {

        if (this.isAllChecked) {
          for (let r = 0; r < this.record.length; r++) {
            const element = this.record[r]._id;
            this.deleteIds = this.deleteIds.filter(item => item !== element)
          }
          this.isAllChecked = false;
        } else {
          for (let r = 0; r < this.record.length; r++) {
            const element = this.record[r]._id;
            if (this.deleteIds.some((ids) => ids === element)) {
            } else {
              this.deleteIds.push(element);
            }
          }
          this.isAllChecked = true;
        }
      } else {
        this.isAllChecked = false;
        if (this.deleteIds.some((ids) => ids === id)) {
          this.deleteIds = this.deleteIds.filter(item => item !== id)
        } else {
          this.deleteIds.push(id);
        }
      }

      if (this.deleteIds.length == this.record.length) {
        this.isAllChecked = true;
      } else {
        this.isAllChecked = false;
      }
    },
    async downloadRecording(link) {
      this.downloadLoader.push(link);

      var link = `https://link.storjshare.io/s/jul5fajkqttktthz5lx5ab34lxoq/record/${link}?download=1`;


      fetch(link)
        // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
        .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
        .then(blob => {
          // const url = window.URL.createObjectURL(link);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = link;
          // the filename you want
          a.download = link;
          document.body.appendChild(a);
          a.click();
          // window.URL.revokeObjectURL(url);

          this.downloadLoader = [];
          // or you know, something with better UX...
        })
        .catch(() => alert('oh no!'));

      // const a = document.createElement("a");
      // console.log('downloadLoader----', this.downloadLoader);
      //   a.id = 'RECORD';
      //   a.href = link;
      //   a.download = true;
      //   a.click();
    },
    async FinalDeleteRecords() {
      this.is_delete_loader = true;
      const body = {
        id: this.deleteIds
      }
      const data = await deleteRecording(body);
      console.log(data)

      if (data.status == 200) {
        this.is_delete_loader = false;
        $("#warningModal").modal("hide");
        await this.loadData();
      }
      this.deleteIds = [];
    },
    async deleteRecording() {
      $("#warningModal").modal("show");
    },
    async loadData() {

      const getRecors = await getRecording();
      console.log("getRecors---22----", getRecors);

      if (getRecors.response) {
        if (getRecors.response.data.code == 401 || getRecors.response.status == 401) {
          localStorage.clear();
          swal(getRecors.response.data.message);
          this.$router.push("/login");
        }
      } else {

        this.record = getRecors.data.result.data;
        this.data_loader = false;

        this.socket = io(this.BASE_URL, { autoConnect: true });
        this.socket.on("connect", async () => {

        });

        this.socket.on('fileDetails', (name) => {
          console.log('file details')
          this.namea = name
        })

        this.socket.on('getChunk', (chunk) => {
          console.log('chunk', chunk)
          this.chunks.push(chunk)
        })

        this.socket.on('downloadFinished', () => {
          console.log('finished')
          const blob = new Blob(this.chunks, { type: 'video/mp4' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url;
          a.download = this.namea;
          a.click()
          URL.revokeObjectURL(url)
        })
      }
    },
  },
};
</script>
<style scoped>
/* Absolute Center Spinner */
tr:first-child {
  background: transparent;
  margin: 0 !important;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 15px !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
}

td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr {
  background: #373737;
}

td {
  vertical-align: middle !important;
}

.kt-checkbox>input {
  position: relative !important;
}

::-webkit-scrollbar {
  width: 10px;
  margin-right: 10px !important;
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgb(45 140 255);
  background-clip: padding-box;
  border-radius: 9999px;
}

.kt-container {
  padding: 10px 10px !important;
}

.kt-checkbox.kt-checkbox--brand>span:after {
  border-color: white !important;
}

.dspinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  right: 28%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.recordAnimation {
  animation: record 1.5s ease infinite normal;
}

@keyframes record {
  10% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}


.shell {
  height: 20px;
  width: 250px;
  border: 1px solid #aaa;
  border-radius: 13px;
  padding: 3px;
}

.bar {
  background: linear-gradient(to right, #B993D6, #8CA6DB);
  height: 20px;
  width: 15px;
  border-radius: 9px;

  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}

button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}
</style>