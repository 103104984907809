import createPersistedState from "vuex-persistedstate";

export default ({
  state: {
    user: null,
    posts: null,

  },
  getters: {
    isAuthenticated: state => !!state.user,    
    StatePosts: state => state.posts,
    StateUser: state => state.user,
  },
  mutations: {
    setUser(state, username){
      state.user = username
  },
  setPosts(state, posts){
      state.posts = posts
  },
  LogOut(state){
      state.user = null
      state.posts = null
  },
  },
  actions: {
    async LogIn({commit}, token) {
      await commit('setUser', token)
    },
    async LogOut({commit}){
      let user = null
      commit('logout', user)
    }
  },
  modules: {
    plugins: [createPersistedState()]
  }
})