<template >
  <div>
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {},
  mounted() {},
};

$(window).on('scroll', function() {
  console.log("come");
});


function videresize(){
  if( $("#other-participants-test1").hasClass("multli_user_main") ) {
    var mainheight = $("#other-participants-test1").height()/2-25;
    $("#other-participants-test1 video").css({"max-height": mainheight});
  } else if( $("#other-participants-test1").hasClass("two_user_main") ) { 
    var mainheight = $("#other-participants-test1").height();
    $("#other-participants-test1 video").css({"max-height": mainheight});
  } else {
    var mainheight = $("#other-participants-test1").height();
    $("#other-participants-test1 video").css({"max-height": mainheight});
  }

}
$(window).on('resize', function() {
  videresize();
});
$(window).on('load', function() {
  videresize();
});

//Write here
</script>
<style>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.vs__selected {
  color: white;
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 10%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}
.dropup .btn.dropdown-toggle:after,
.dropup .nav-link.dropdown-toggle:after {
  content: url("../src/assets/arrow-drop-up.png") !important;
  content: "";
}
.btn.dropdown-toggle:after,
.nav-link.dropdown-toggle:after {
  content: "";
  content: url("../src/assets/arrow-drop-down-line.png") !important;
}
.microphone {
  color: rgb(50, 45, 45);
  padding-left: 12px;
  font-weight: bold;
}

.fc-view-harness.fc-view-harness-active {
  height: calc(100vh - 56px) !important;
}
​ .fc .fc-scrollgrid-liquid {
  height: 100% !important;
}
​ .fc-view .fc td,
.fc-view .fc th {
  min-height: 120px;
  height: 120px;
}
.add-btn-recurring {
  margin-left: 10px;
}
.invite-btn {
  margin-bottom: 13px;
  margin-left: 9rem;
  /* width: 100%; */
}
.date-formate {
  color: #fff !important;
}
.add-btn {
  border-color: rgb(45, 140, 255) !important;
  background: rgb(45, 140, 255) !important;
  border-radius: 5px !important;
  color: white !important;
}
​ .fc .fc-daygrid-day-frame {
  height: 100% !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #101826 !important;
  color: #fff;
  opacity: 1;
}
.date-picker-custom:focus {
  color: #fff !important;
}
.vs__dropdown-toggle:focus-within {
  color: #fff;
}
@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");
* {
  font-family: "Nunito", Helvetica, Arial !important;
}
@import "./assets/vendors/responsive-video.css";

@import "./assets/vendors/all.min.css"; 
@import "./assets/vendors/bootstrap-switch.css";
@import "./assets/vendors/custom.css";
@import "./assets/vendors/flaticon.css";
@import "./assets/vendors/jquery.bootstrap-touchspin.css";
@import "./assets/vendors/owl.theme.default.css";
@import "./assets/vendors/perfect-scrollbar.css";
@import "./assets/vendors/socicon.css";
@import "./assets/vendors/style.bundle.min.css";
@import "./assets/vendors/style.css";
@import "./assets/vendors/sweetalert2.css";
@import "./assets/vendors/bootstrap-datepicker3.css";
@import "./assets/vendors/bootstrap-datetimepicker.css";
@import "./assets/vendors/bootstrap-timepicker.css";
@import "./assets/vendors/daterangepicker.css";
@import "./assets/vendors/jquery.bootstrap-touchspin.css";
@import "./assets/vendors/bootstrap-select.css";
@import "./assets/vendors/bootstrap-switch.css";
@import "./assets/vendors/select2.css";
@import "./assets/vendors/ion.rangeSlider.css";
@import "./assets/vendors/owl.carousel.css";
@import "./assets/vendors/line-awesome.css";
@import "./assets/vendors/fullcalendar.bundle.min.css";
@import "./assets/vendors/bootstrap-datetimepicker.min.css";
@import "./assets/vendors/perfect-scrollbar.css";
</style>