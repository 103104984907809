<template>
  <body
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <!-- Modal -->

    <WebinarModel ref="modal" />
    <WebinarViewModel ref="modal" />

    <div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
      <div class="kt-header-mobile__logo">
        <a href="demo3/index.html">
          <img alt="Logo" src="../../assets/media/site-logo.png" />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button
          class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left kt-header-mobile__toolbar-toggler--active"
          id="kt_aside_mobile_toggler">
          <span></span>
        </button>
        <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
          <span></span>
        </button>
      </div>
    </div>
    <!-- end:: Header Mobile -->

    <div class="kt-grid kt-grid--hor kt-grid--root fullpage" style="height: 1oovh;">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <!-- begin:: Aside -->
        <button class="kt-aside-close" id="kt_aside_close_btn">
          <i class="fa fa-window-close" aria-hidden="true"></i>
        </button>
        <!-- end:: Aside -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
          <div style="overflow: revert !important; background: transparent !important;"
            class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
            <!-- begin:: Content -->
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
              <div class="top-title resp-top-spacing">
                <h3 class="text-white" style="margin: 0;">Upcoming Webinars</h3>
                <h4 class="text-white" id="myText1"
                  style="text-align: center;margin: 0;overflow: hidden;text-overflow: ellipsis;color: red !important">
                  <span style="    text-decoration: underline;">Open Room Link</span>: <span id="RR"> {{ openRoomUrl
                  }}</span>
                  <img @click="copyUrl()" class="copyIcon" width="25" height="25"
                    src="../../assets/media/copyLinkIcon.png" />
                </h4>
                <span class="d-flex">
                  <button type="button" class="btn btn-blue" data-toggle="modal" data-target="#exampleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.104" height="14.104" viewBox="0 0 14.104 14.104">
                      <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add"
                        d="M22.191,15.139h-5.3v-5.3a.878.878,0,0,0-1.756,0v5.3h-5.3a.878.878,0,0,0,0,1.756h5.3v5.3a.878.878,0,0,0,1.756,0v-5.3h5.3a.878.878,0,0,0,0-1.756Z"
                        transform="translate(-8.965 -8.965)" fill="#fff" />
                    </svg>

                    New Webinar
                  </button>
                  <span style="margin-left: 8px;">
                    <a href="javascript:void(0)" data-toggle="dropdown">
                      <img style="border-radius: 40px" alt="Logo" width="40" height="40" :src="profileImg" />
                    </a>

                    <div class="dropdown-menu dropdown-menu-right bg-gray">
                      <div class="row mx-2 mb-2 editRole">
                        <a href="#" style="color: #fff;" @click="MyProfile()">My Profile</a>
                      </div>
                      <div class="row mx-2 mb-2 editRole">
                        <a href="#" style="color: #fff;" @click="Settings()">Settings</a>
                      </div>
                      <div class="row mx-2 mb-2 editRole">
                        <a href="#" style="color: #fff;" @click="Recordings()">Recordings</a>
                      </div>
                      <div class="row mx-2 editRole">
                        <a href="#" style="color: red;" @click="Logout()">Logout
                        </a>
                      </div>
                    </div>
                  </span>
                </span>
              </div>
              <div v-if="show == 'true'"></div>
            </div>
          </div>

          <div class="modal fade bd-example-modal-xl" id="LogoutModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal" role="document">
              <div class="modal-content" style="background: #fff;">
                <div class="border-dotted">
                  <div class="modal-body">
                    <div class="row" style="margin-top: 14px;">
                      <div class="col-md-12">
                        <h3 class="text-dark weight-700">
                          Are you sure you want to Logout?
                        </h3>
                        <hr style="margin-bottom: 0;" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px;">
                  <button v-on:click="Loggedout()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                    style="width: 150px; background: #b60808; color: #fff;" data-toggle="modal"
                    data-target="#add-new-webinar-calendar">
                    Yes
                  </button>
                  <button v-on:click="closeLogoutModal()" type="button" id="BtnNotReadableError"
                    class="btn white-btn mr-3" style="width: 150px; background: green; color: #fff;" data-toggle="modal"
                    data-target="#add-new-webinar-calendar">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: Page -->
    <div class="kt-grid__item--fluid kt-grid--hor kt-wrapper" id="kt_wrapper" style="padding-right: 25px;">
      <FullCalendar class="demo-app-calendar" :options="calendarOptions"></FullCalendar>
    </div>
    <!-- begin::Scrolltop -->
    <div id="kt_scrolltop" class="kt-scrolltop">
      <i class="fa fa-arrow-up"></i>
    </div>
    <!-- end::Scrolltop -->
  </body>
</template>

<script>
import axios from "axios";
var _ = require("lodash");
var moment = require("moment-timezone");
import WebinarModel from "../WebinarModel/WebinarModel.vue";
import WebinarViewModel from "../WebinarModel/WebinarViewModel.vue";
import webinarInvitemodel from "@/WebinarModel/webinarInvitemodel.vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { lastwebinarlist, singlewebinardata, webinaroneTime, webinarlist, updateUserDetails, userEmail } from "../services/service";
export default {
  name: "Guestlist",
  components: {
    WebinarModel,
    FullCalendar,
    WebinarViewModel,
    webinarInvitemodel,
  },
  data: function () {
    return {
      timezone: "",
      profileImg: "",
      users: [],
      openRoomUrl: "",
      room_name: "",
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        // alternatively, use the `events` setting to fetch from a feed
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventClick: this.handleEventClick,
      },
      currentEvents: [],
    };
  },
  async mounted() {
    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    }
  },
  async created() {
    const userDetails = await updateUserDetails();
    console.log('userDetails-------', userDetails);
    if (userDetails.response) {
      if (userDetails.response.status == 401) {
        localStorage.clear();
        this.$router.push("/login");
      }
    } else {
      await localStorage.setItem(
        "userdetails",
        JSON.stringify(userDetails.data.result.data)
      );

    }
    var companyname = JSON.parse(
      localStorage.getItem("userdetails")
    ).companyname;
    var roomName = JSON.parse(
      localStorage.getItem("userdetails")
    ).Name;
    console.log(this.openRoomUrl);
    this.openRoomUrl =
      window.location.origin +
      "/" +
      companyname +
      "/" +
      roomName.toLocaleLowerCase();

    const lastwebinar = await lastwebinarlist();
    this.timezone = JSON.parse(localStorage.getItem("userdetails")).timezone ? JSON.parse(localStorage.getItem("userdetails")).timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (lastwebinar.response) {
      if (
        lastwebinar.response.data.code == 401 ||
        lastwebinar.response.status == 401
      ) {
        localStorage.clear();
        swal(lastwebinar.response.data.message);
        this.$router.push("/login");
      }
    }

    var loggedUserName = JSON.parse(
      localStorage.getItem("userdetails")
    ).Name.replaceAll(" ", "");
    var loggedProfileImg = JSON.parse(
      localStorage.getItem("userdetails")
    ).profileImg.replaceAll(" ", "");
    const BASE_URL = window.location.host == "unitywebinar.com" ? "https://api.unitywebinar.com/" : window.location.host == "demo.unitywebinar.com" ? "https://demoapi.unitywebinar.com/" : "https://dev-api.unitywebinar.com/";

    if (loggedProfileImg) {
      this.profileImg = BASE_URL + loggedProfileImg
    } else {
      this.profileImg = '../../assets/media/default.jpg'
    }
    if (lastwebinar.data.result.data != null) {
      var userName = lastwebinar.data.result.data.roomName;
      var webinarId = lastwebinar.data.result.data._id;
    } else {
      var userName = loggedUserName;
      var webinarId = "";
    }

    this.webinarId = webinarId;
    this.room_name = userName.toLocaleLowerCase();
    // alert(userName)
    this.oldRoomName = userName.toLocaleLowerCase();
    if (lastwebinar.data.result.data == null) {
      this.createDefaultUrl();
    }
    let data = await userEmail();
    console.log("data-0-----", data);
    if (data.response) {
      if (data.response.data.code == 401 || data.response.status == 401) {
        localStorage.clear();
        this.$router.push("/login");
      }
    }

    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      let data = [];
      const headers = {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("user"),
      };
      const webinarslist = await webinarlist();
      console.log('webinarlist---------------', webinarslist)
      _.forEach(webinarslist.data.result, function (value, key) {
        if (value.webinarType == "ONE_TIME") {
          // var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          // d.setUTCSeconds(value.oneTime.time);
          // let time = d.toISOString();

          var date = new Date(value.oneTime.date);
          var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];

          data.push({
            id: value._id,
            title: value.title,
            start: dateString,
            data: "ONE_TIME",
          });
        } else if (value.webinarType == "MULTIPLE_BROADCAST") {
          _.forEach(value.multipleBroadcasts, function (v, k) {
            d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(v.time);
            let time = d.toISOString();
            data.push({
              id: value._id,
              title: value.title,
              start: time,
              data: "MULTIPLE_BROADCAST",
              multiId: v._id,
            });
          });
        } else if (value.webinarType == "RECURRING") {
          var d = new Date(0);
          d.setUTCSeconds(value.recurring[0].at);
          let time = d.toISOString();
          data.push({
            id: value._id,
            title: value.title,
            start: time,
            data: "RECURRING",
          });
        } else if (value.webinarType == "OPEN_ROOM") {
          // var d = new Date(); // The 0 there is the key, which sets the date to the epoch
          // d.setUTCSeconds(value.oneTime.time);
          // let time = d.toISOString();
          var date = new Date(value.oneTime.date);
          var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];


          console.log('value.oneTime.date----------', value.oneTime.date);
          console.log(dateString);
          data.push({
            id: value._id,
            title: value.title,
            start: dateString,
            data: "OPEN_ROOM",
          });
        }
      });

      this.calendarOptions.events = data;
      this.calendarOptions.eventTimeFormat = {
        hour12: true,
        hour: "numeric",
        minute: "2-digit",
        meridiem: "short",
      };
    }
  },

  methods: {
    to24Hr(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    async handleEventClick(event) {
      let data = await singlewebinardata(
        event.event._def.publicId,
        event.event._def.extendedProps.multiId
      );
      console.log(data);
      this.$refs.modal.updateWebinarDetails(
        data.data.result,
        event.event._def.extendedProps.data,
        event.event._def.publicId,
        event.event._def.extendedProps.multiId
      );
      $("#WebinarViewModel").modal("show");
    },
    async copyUrl() {
      let text = document.getElementById('RR').innerHTML;

      var copyText = this.openRoomUrl;

      navigator.clipboard.writeText(text).then(function () {

        alert('copied to clipboard')

      }, function () {

        alert('copied to clipboard failed')

      });
    },
    async createDefaultUrl() {
      var time = new Date();
      this.time = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      // alert('ok')
      let dateString =
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "/" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        "/" +
        this.date.getFullYear();
      let datetimeString =
        this.date.getFullYear() +
        "-" +
        (String(this.date.getMonth() + 1).length == 1 ?
          "0" + String(this.date.getMonth() + 1) :
          String(this.date.getMonth() + 1)) +
        "-" +
        (String(this.date.getDate()).length == 1 ?
          "0" + String(this.date.getDate()) :
          String(this.date.getDate())) +
        " " +
        this.to24Hr(this.time);
      this.is_loading = "box";
      console.log('datetimeString----', datetimeString)
      let unixTime = moment.tz(datetimeString, this.timezone);
      var companyname = JSON.parse(
        localStorage.getItem("userdetails")
      ).companyname;
      var userData = JSON.parse(
        localStorage.getItem("userdetails")
      );

      unixTime = unixTime.valueOf() / 1000;

      const specificDate = new Date(moment.unix(unixTime).format());
      const editWebinarTime = specificDate.toLocaleString('en-US', { hour: "2-digit", minute: "2-digit" })

      this.users.push({
        Name: "",
        email: userData.email,
        firstName: userData.firstName ? userData.firstName : "UnityWebinar",
        lastName: userData.lastName ? userData.lastName : "Host",
        webinarUserType: "Host",
      });
      var body = {
        title: this.room_name,
        oldRoomName: this.room_name,
        companyname: companyname,
        roomName: this.room_name.toLocaleLowerCase(),
        webinarType: "OPEN_ROOM",
        newUser: this.users,
        oneTime: {
          time: unixTime,
          duration: "24:00",
          timezone: this.timezone,
          sendTime: editWebinarTime,
          date: dateString,
        },
      };
      console.log(":body--->", body);
      try {
        body = await webinaroneTime(body);

        if (body.response) {
          if (body.response.data.code == 401 || body.response.status == 401) {
            localStorage.clear();
            swal(body.response.data.message);
            this.$router.push("/login");
          }
        }
      } catch (error) {
        console.log(":body--->", error);

      }
      this.getUpdatedUrl();
      this.is_loading = false;
    },
    async MyProfile() {
      this.$router.push("/profile");
    },
    async Recordings() {
      this.$router.push("/recordings");
    },
    async Settings() {
      // this.$router.push("/recordings");
    },
    async Logout() {
      $("#LogoutModal").modal("show");
    },
    async Loggedout() {
      localStorage.clear();
      this.$router.push("/login");
      $("#LogoutModal").modal("hide");
    },
    async closeLogoutModal() {
      $("#LogoutModal").modal("hide");
    },
    async handleEvents(events) {
      this.currentEvents = events;
    },
    logout() {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
};
</script>
<style>
.fc-button {
  text-transform: capitalize !important;
}

.copyIcon {
  border-radius: 2px;
  background-color: white;
  padding: 1px;
  cursor: pointer;
  color: red !important;
  margin-left: 8px;
}

.editRole {
  font-weight: bold;
}
</style>