<template>
    <div><h1>Webinar is not started yet! Once it is live you will join automatically.</h1></div>
</template>
<script>
import { singlewebinardata } from "../services/service";
export default {
    props: {
		message: String,
	},
    data() {
        return {
            webinarId:"",
            childId:"",
            liveWebinar:false,
        };
    },
    created() {
        let urlParams = new URLSearchParams(window.location.search);
        this.webinarId = urlParams.get("webinarid");
        this.childId = urlParams.get("childId") ?? 0;
        console.log("urlParams.get(webinarid)===>",urlParams.get("webinarid"))
        let that = this
        setInterval(function() {
          
          
                that.checkWebinar()
          
        }, 5000);
    },
    methods: {
        async checkWebinar()
        {

            if(this.liveWebinar == false)
            {
                console.log("this.webinarId--->",this.webinarId)
                console.log("this.childId--->",this.childId)
                let data = {};
                if(this.childId != 0 && this.childId != null && this.childId != "null" && this.childId != undefined && this.childId != "undefined")
                {
                    console.log("data--->1",data)
                    data = await singlewebinardata(this.webinarId,this.childId);
                }
                else{
                    console.log("data--->2",data)
                    data = await singlewebinardata(this.webinarId);
                }                   
                
                this.liveWebinar = data.data.result.live ?? false;
            }
            else{
                let urlParams = new URLSearchParams(window.location.search);
                window.location=`/join?${urlParams.toString()}`
            }
        }
    }
}
</script>