  <template></template>
<style>
.modal-backdrop.fade.show {
  z-index: -1;
}
</style>
    <script>
export default {
  name: "WebinarInvitemodel",
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: "",
      disabled: false,
      disable: "",
      disabled: false,
      users: [],
    };
  },
  methods: {
    isEmailValid: function () {
      return this.inviteUser == ""
        ? " d-none"
        : this.reg.test(this.inviteUser)
        ? "d-none"
        : " ";
    },

    disableBTN: function () {
      return this.inviteUser == ""
        ? (this.disable = false)
        : this.reg.test(this.inviteUser)
        ? (this.disable = true)
        : (this.disable = false);
    },
    deleted(id) {
      this.users = this.users.filter((name) => {
        return name.userid != id.userid;
      });
    },
    addParticipants() {
      this.users.push({
        Name: "",
        email: this.inviteUser,
        webinarUserType: "Viewer", 
      });
      this.inviteUser = "";
      this.users = this.users.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.email == thing.email &&
              t.Name == thing.Name &&
              t.webinarUserType == thing.webinarUserType
          )
      );
    },
  
  },
};
</script>