<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <section class="vh-100" style="background-color: #101826;">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div class="card border-0" style="border-radius: 1rem; background-color: #101826;">
              <div class="row g-0">
                <div class="col-md-6 col-lg-5 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5">
                    <form>
                      <h1 class="fw-normal pb-3 text-white" style="letter-spacing: 1px;">
                        Forgot password<br />
                      </h1>
                      <h6 class="fw-normal pb-3 text-white">
                        Enter your email adderess and we'll send you a link to get back into your account.<br />
                      </h6>

                      <div class="form-outline mb-4">
                        <label class="form-label text-white" for="form2Example17">Email address</label>

                        <input @input="isEmailValid" type="email" id="form2Example17"
                          class="form-control form-control-lg my" v-model="email" placeholder='Email address' />
                      </div>

                      <div style="color: #ff8b00; font-weight: bold">
                        <em>{{ validateError }}</em>
                      </div>

                      <div class="pt-1 mb-4">
                        <button v-on:click="forgotPassword()" style="position: relative;"
                          class="btn btn-primary btn-lg btn-block" type="button" :disabled="is_button">
                          Next
                          <span v-if="loader" class="spinner spinner-white spinner-right"></span>
                        </button>
                      </div>

                      <p class="mb-5 pb-lg-2" style="color: #ffff;">
                        Back to
                        <a href="#!" style="color: #2d8cff;">
                          <router-link to="login">Login </router-link>
                        </a>
                      </p>
                    </form>
                  </div>
                </div>

                <div class="col-md-6 col-lg-7 d-none d-md-block" style="
                    color: #78b0ed;
                    background-image: linear-gradient(
                      200deg,
                      rgba(172, 206, 247, 1),
                      rgba(240, 210, 238, 1)
                    );
                    border-radius: 1rem 1rem;
                  ">
                  <div class="fw-normal mt-5 mb-2 pb-5 text-dark text-center">
                    <img alt="Logo" width="350" height="62" src="../../assets/media/UW@600x.png" />
                  </div>
                  <div class="text-center mb-5" style="color: #3e4c64;">
                    <img src="../../assets/media/broadcast.jpg" alt="login form" class="img-fluid w-50"
                      style="border-radius: 1rem 0 0 1rem;" />
                    <h4 style="color: #3e4c64;">
                      The <b>Most Powerful</b> webinar software
                    </h4>
                    <p>
                      webinar software that puts you in control of your
                      broadcasts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { loginPost, sendResetPassword } from "../services/service";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: "",
      password: "",
      showPassword: false,
      loader: false,
      is_button: false,
    };
  },
  created() {
    console.log("come", localStorage.getItem("isAuthenticated"));
    if (localStorage.getItem("isAuthenticated")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async forgotPassword() {
      let body = {
        email: this.email,
      };
      if (
        this.email == "" ||
        this.email == null
      ) {
        swal("Please enter email address.");
      } else {

        var emails_before = this.email.split("@")[1];
        var emails = this.email.split("@")[0];
        var firstLetter = emails[0];
        var lastChar = emails.substr(emails.length - 1);
        this.is_button = true;
        this.loader = true;

        const sendResetStatus = await sendResetPassword(body);

        if (sendResetStatus.data.result == 1) {
          swal({
            title: "Email link sent",
            text:
              "We send an email to " + firstLetter + '•••••••' + lastChar + '@' + emails_before + " with a link to get back into your account.",
            icon: "success",
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              this.is_button = false;
              this.loader = false;
              this.$router.push("/login");
            } else {
              this.is_button = false;
              this.loader = false;
              // this.$router.push("/dashboard");
            }
          });
        } else {
          swal({
            title: "Email error",
            text:
              "We tried to send you a password reset email to " + this.email + ", but it appears that this email address is not associated with any account on our system.",
            icon: "error",
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              this.is_button = false;
              this.loader = false;
              // this.$router.push("/dashboard");
            } else {
              this.is_button = false;
              this.loader = false;
              // this.$router.push("/dashboard");
            }
          });

        }

      }
    },
    async isEmailValid() {
      if (this.reg.test(this.email) == false) {
        this.validateError = "Please enter valid Email";
        this.is_button = true;
      } else {
        this.validateError = "";
        this.is_button = false;
      }
      console.log('hiii')
    }

  },
};
</script>

<style>
.my {
  background: white !important;
}
</style>
