<template>
  <div id="main_div"
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <div style="z-index: 9999" id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
      <div class="kt-header-mobile__logo">
        <a href="demo3/index.html">
          <img alt="Logo" src="../../assets/media/site-logo.png" />
        </a>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler">
          <span></span>
        </button>
        <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
          <span></span>
        </button>
      </div>
    </div>
    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root fullpage" style="height: 1oovh">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside"
          v-if="role != 'VIEWER'">
          <!-- begin:: Aside -->
          <div class="left-menu-container">
            <div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
              <div class="kt-aside__brand-logo">
                <a href="index.html">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.781" height="17.09" viewBox="0 0 22.781 17.09">
                    <g id="Group_113" data-name="Group 113" transform="translate(-57.216 -412.597)">
                      <path id="Path_137" data-name="Path 137"
                        d="M79.235,415.385a1.572,1.572,0,0,0-1.564-.073l-1.688.852a1.84,1.84,0,0,0-1.011,1.657v6.642a1.848,1.848,0,0,0,1.011,1.659l1.687.85a1.563,1.563,0,0,0,.719.175,1.594,1.594,0,0,0,.846-.246A1.627,1.627,0,0,0,80,425.513v-8.74a1.629,1.629,0,0,0-.762-1.388Z"
                        fill="#2d8cff"></path>
                      <path id="Path_138" data-name="Path 138"
                        d="M68.5,429.681H61.9a4.452,4.452,0,0,1-4.684-4.623v-7.831A4.455,4.455,0,0,1,61.9,412.6h6.6a4.453,4.453,0,0,1,4.684,4.624v7.831a4.452,4.452,0,0,1-4.684,4.623Z"
                        fill="#2d8cff"></path>
                    </g>
                  </svg>
                  <label class="main-menu-label">Live Room</label>
                </a>
              </div>
            </div>
            <!-- end:: Aside -->
            <!-- begin:: Aside Menu -->
            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
              <div id="kt_aside_menu" class="kt-aside-menu kt-aside-menu--dropdown" data-ktmenu-vertical="1"
                data-ktmenu-dropdown="1" data-ktmenu-scroll="0">
                <ul class="left-main-menu">
                  <li>
                    <a href="participants.html">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.935" height="28.669" viewBox="0 0 22.935 28.669">
                        <g id="_-System-Icons" data-name="🔍-System-Icons" transform="translate(-8 -4)">
                          <g id="ic_fluent_person_48_regular" transform="translate(8 4)">
                            <path id="_-Color" data-name="🎨-Color"
                              d="M27.889,21.2a3.045,3.045,0,0,1,3.042,2.9l0,.148v.538a6.828,6.828,0,0,1-3.526,6.006,15.476,15.476,0,0,1-7.941,1.878,15.476,15.476,0,0,1-7.941-1.878A6.835,6.835,0,0,1,8,25.028l0-.243v-.538A3.045,3.045,0,0,1,10.9,21.2l.148,0Zm0,1.792H11.046A1.254,1.254,0,0,0,9.8,24.144l0,.1v.538a5.043,5.043,0,0,0,2.666,4.476,13.725,13.725,0,0,0,7.009,1.616,13.725,13.725,0,0,0,7.009-1.616,5.051,5.051,0,0,0,2.663-4.267l0-.209v-.538A1.254,1.254,0,0,0,27.992,23ZM19.468,4A7.167,7.167,0,1,1,12.3,11.167,7.167,7.167,0,0,1,19.468,4Zm0,1.792a5.375,5.375,0,1,0,5.375,5.375A5.375,5.375,0,0,0,19.468,5.792Z"
                              transform="translate(-8 -4)" fill="#2d8cff"></path>
                          </g>
                        </g>
                      </svg>
                      <label class="main-menu-label">Manage Participants</label>
                    </a>
                  </li>
                  <li>
                    <a href="media.html">
                      <svg id="media-library-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                        viewBox="0 0 32 32">
                        <path id="Path_494" data-name="Path 494" d="M13,15v8h0l7-4Z" fill="#b6b6b6"></path>
                        <path id="Path_495" data-name="Path 495"
                          d="M26,28H6a2,2,0,0,1-2-2V12a2,2,0,0,1,2-2H26a2,2,0,0,1,2,2V26A2,2,0,0,1,26,28ZM6,12V26H26V12Z"
                          fill="#b6b6b6"></path>
                        <rect id="Rectangle_260" data-name="Rectangle 260" width="20" height="2"
                          transform="translate(6 6)" fill="#b6b6b6"></rect>
                        <rect id="Rectangle_261" data-name="Rectangle 261" width="16" height="2"
                          transform="translate(8 2)" fill="#b6b6b6"></rect>
                        <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" width="32" height="32"
                          fill="none"></rect>
                      </svg>
                      <label class="main-menu-label">Media Manager</label>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.794" height="25.054" viewBox="0 0 22.794 25.054">
                        <g id="Group_115" data-name="Group 115" transform="translate(-57.604 -590.556)">
                          <path id="Path_125" data-name="Path 125"
                            d="M62.8,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_126" data-name="Path 126"
                            d="M67.867,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_127" data-name="Path 127"
                            d="M72.921,604.571a1.138,1.138,0,0,1,1.139-1.138h.01a1.139,1.139,0,0,1,0,2.277h-.01a1.14,1.14,0,0,1-1.139-1.139Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_128" data-name="Path 128"
                            d="M62.8,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,62.8,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_129" data-name="Path 129"
                            d="M67.867,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,67.867,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_130" data-name="Path 130"
                            d="M72.921,609a1.138,1.138,0,0,1,1.139-1.138h.01a1.138,1.138,0,1,1,0,2.276h-.01A1.138,1.138,0,0,1,72.921,609Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_131" data-name="Path 131"
                            d="M74.743,591.7a1.139,1.139,0,0,0-2.278,0v.659H65.544V591.7a1.139,1.139,0,0,0-2.278,0v.7a6.048,6.048,0,0,0-5.655,6.381v10.3A6.488,6.488,0,0,0,59.42,613.9a6.664,6.664,0,0,0,4.764,1.708h9.633a6.719,6.719,0,0,0,4.762-1.679,6.355,6.355,0,0,0,1.811-4.757V598.781a5.9,5.9,0,0,0-5.646-6.384Zm-2.278,2.936v.811a1.139,1.139,0,0,0,2.278,0v-.753a3.964,3.964,0,0,1,2.271.987,4.069,4.069,0,0,1,1.1,3.1v.206H59.889v-.2A4.085,4.085,0,0,1,61,595.68a3.98,3.98,0,0,1,2.269-.988v.752a1.139,1.139,0,0,0,2.278,0v-.813Zm-12.577,6.627v7.818a4.234,4.234,0,0,0,1.116,3.178,4.412,4.412,0,0,0,3.179,1.072h9.633a4.472,4.472,0,0,0,3.189-1.049,4.1,4.1,0,0,0,1.106-3.11v-7.909Z"
                            fill="#b6b6b6"></path>
                        </g>
                      </svg>
                      <label class="main-menu-label">Calendar</label>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.778" height="22.765" viewBox="0 0 22.778 22.765">
                        <g id="Group_116" data-name="Group 116" transform="translate(-57.632 -682.86)">
                          <path id="Path_123" data-name="Path 123"
                            d="M69.021,692.347a1.9,1.9,0,1,0,1.342.556,1.9,1.9,0,0,0-1.342-.556Zm-3.8,1.9a3.8,3.8,0,1,1,1.112,2.683,3.794,3.794,0,0,1-1.112-2.683Z"
                            fill="#b6b6b6"></path>
                          <path id="Path_124" data-name="Path 124"
                            d="M69.021,684.758a.949.949,0,0,0-.949.948v.165a2.513,2.513,0,0,1-1.525,2.3.99.99,0,0,1-.25.069,2.514,2.514,0,0,1-2.59-.577l-.008-.006-.057-.057a.956.956,0,0,0-.308-.206.941.941,0,0,0-.727,0,.955.955,0,0,0-.308.206h0a.947.947,0,0,0-.206,1.034.95.95,0,0,0,.206.308l.065.065a2.577,2.577,0,0,1-1.778,4.364h-.107a.949.949,0,1,0,0,1.9h.165a2.523,2.523,0,0,1,1.795,4.287l-.007.008-.057.057a.947.947,0,0,0,0,1.342h0a.925.925,0,0,0,.308.206.941.941,0,0,0,.727,0,.925.925,0,0,0,.308-.206l.065-.065a2.578,2.578,0,0,1,4.365,1.777v.108a.949.949,0,0,0,1.9,0v-.165a2.526,2.526,0,0,1,4.289-1.8l.008.007.057.057a.956.956,0,0,0,.308.206.953.953,0,0,0,.727,0,.955.955,0,0,0,.308-.206h0a.948.948,0,0,0,0-1.342l-.065-.065a2.526,2.526,0,0,1,1.795-4.288h.089a.949.949,0,1,0,0-1.9H77.4a2.512,2.512,0,0,1-2.3-1.523.95.95,0,0,1-.068-.25,2.513,2.513,0,0,1,.576-2.589l.007-.008.057-.057a.947.947,0,0,0,0-1.342h0a.925.925,0,0,0-.308-.206.941.941,0,0,0-.727,0,.925.925,0,0,0-.308.206l-.065.064A2.526,2.526,0,0,1,69.97,685.8v-.09a.949.949,0,0,0-.278-.671.952.952,0,0,0-.672-.277Zm7.023,12.333.868.383a.616.616,0,0,0,.121.677l.052.053a2.846,2.846,0,0,1,0,4.028l-.671-.672.672.67a2.847,2.847,0,0,1-4.028,0h0l-.052-.052a.616.616,0,0,0-.678-.121h-.009a.619.619,0,0,0-.374.563v.159a2.847,2.847,0,0,1-5.694,0v-.072a.616.616,0,0,0-.4-.555l-.056-.023a.616.616,0,0,0-.678.121l-.052.052a2.848,2.848,0,1,1-4.028-4.027l.052-.052a.617.617,0,0,0,.121-.678v-.009a.618.618,0,0,0-.563-.373h-.165a2.847,2.847,0,1,1,0-5.693h.072a.616.616,0,0,0,.556-.4c.007-.019.014-.037.023-.055a.618.618,0,0,0-.121-.678l-.052-.052a2.847,2.847,0,1,1,4.028-4.022l.052.052a.621.621,0,0,0,.678.121.965.965,0,0,1,.189-.061.617.617,0,0,0,.27-.506v-.16a2.847,2.847,0,0,1,5.694,0v.083a.618.618,0,0,0,.374.563h.009a.616.616,0,0,0,.678-.121l.052-.052a2.848,2.848,0,1,1,4.028,4.027h0l-.052.052a.616.616,0,0,0-.121.677.954.954,0,0,1,.06.189.614.614,0,0,0,.507.27h.159a2.846,2.846,0,1,1,0,5.693h-.083a.618.618,0,0,0-.563.373Z"
                            fill="#b6b6b6"></path>
                        </g>
                      </svg>
                      <label class="main-menu-label">Settings</label>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end:: Aside Menu -->
        </div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper responsiv__space" id="kt_wrapper"
          :style="role == 'VIEWER' ? 'padding-left: 1px !important;' : 'padding-top: 105px'
            ">
          <!-- begin:: Header -->
          <div id="kt_header" class="kt-header kt-grid__item kt-header--fixed"
            :style="role == 'VIEWER' ? 'left: 0px !important;' : ''">
            <!-- begin: Header Menu -->
            <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn">
              <i class="la la-close"></i>
            </button>
            <div class="topRecordPanel" v-if="topRecording">
              <span class="icon recordAnimation">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path id="Path_45" data-name="Path 45"
                    d="M647,964a10,10,0,1,0,10,10A10.011,10.011,0,0,0,647,964Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5A8.489,8.489,0,0,1,647,965.5Zm0,4a4.5,4.5,0,1,0,4.5,4.5A4.512,4.512,0,0,0,647,969.5Z"
                    transform="translate(-637 -964)" fill="#ef4444" />
                </svg>
              </span>
              &nbsp;
              <span v-if="recordingToaster && hrs"> {{ hrs }}:{{ min }}:{{ sec }} </span>
              <a v-if="recordingToaster" style="color: white;font-weight: bold;cursor: pointer;" @click="stopAskModal()">
                Stop {{ recordType }} recording </a>
              <span v-if="!recordingToaster"> {{ recorderUsers }} is recording the call </span>
            </div>
            <div class="kt-header-menu-wrapper responsiv__header__space" id="kt_header_menu_wrapper"
              style="padding: 10px 0px !important">
              <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-tab">
                <ul class="nav nav-tabs nav-tabs-line kt-menu__nav home-sidebar-menu">
                  <li class="nav-item" v-if="role != 'VIEWER'">
                    <span class="notification"></span>
                    <a class="nav-link participants-tab" data-toggle="tab" onclick="openNav()" href="#participant1">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                        viewBox="0 0 16 20">
                        <path :style="role == 'VIEWER' ? 'fill: #9ca3af9c !important' : ''" id="Path_420"
                          data-name="Path 420"
                          d="M1302.5,30a5,5,0,1,0,5,5A5.013,5.013,0,0,0,1302.5,30Zm-5.75,12a2.264,2.264,0,0,0-2.25,2.25v.6a4.534,4.534,0,0,0,2.35,3.7,11.7,11.7,0,0,0,11.3,0,4.534,4.534,0,0,0,2.35-3.7v-.6a2.264,2.264,0,0,0-2.25-2.25Z"
                          transform="translate(-1294.5 -30)" fill="#fff" />
                      </svg>
                      Participants
                    </a>
                  </li>
                  <li class="nav-item">
                    <span class="notification"></span>
                    <a class="nav-link" data-toggle="tab" href="#offers" onclick="openNav()" disabled>
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="18.93" height="18.931"
                        viewBox="0 0 18.93 18.931">
                        <path id="Path_418" data-name="Path 418"
                          d="M1457.25,40.5h-4.5a1.755,1.755,0,0,1-1.75-1.75v-4.5a1.755,1.755,0,0,1,1.75-1.75h4.5a1.755,1.755,0,0,1,1.75,1.75v4.5A1.755,1.755,0,0,1,1457.25,40.5Zm-4.5-6.5a.249.249,0,0,0-.25.25v4.5a.249.249,0,0,0,.25.25h4.5a.249.249,0,0,0,.25-.25v-4.5a.249.249,0,0,0-.25-.25Zm4.5,15.5h-4.5a1.755,1.755,0,0,1-1.75-1.75v-4.5a1.755,1.755,0,0,1,1.75-1.75h4.5a1.755,1.755,0,0,1,1.75,1.75v4.5A1.755,1.755,0,0,1,1457.25,49.5Zm-4.5-6.5a.249.249,0,0,0-.25.25v4.5a.249.249,0,0,0,.25.25h4.5a.249.249,0,0,0,.25-.25v-4.5a.249.249,0,0,0-.25-.25Zm13.5,6.5h-4.5a1.755,1.755,0,0,1-1.75-1.75v-4.5a1.755,1.755,0,0,1,1.75-1.75h4.5a1.755,1.755,0,0,1,1.75,1.75v4.5A1.755,1.755,0,0,1,1466.25,49.5Zm-4.5-6.5a.249.249,0,0,0-.25.25v4.5a.249.249,0,0,0,.25.25h4.5a.249.249,0,0,0,.25-.25v-4.5a.249.249,0,0,0-.25-.25Zm3.25-2.569a1.746,1.746,0,0,1-1.24-.512l-3.18-3.182a1.756,1.756,0,0,1,0-2.474l3.18-3.182a1.758,1.758,0,0,1,2.48,0l3.18,3.182a1.756,1.756,0,0,1,0,2.474l-3.18,3.182A1.746,1.746,0,0,1,1465,40.431Zm0-8.363a.267.267,0,0,0-.18.073l-3.18,3.182a.246.246,0,0,0,0,.353l3.18,3.182a.258.258,0,0,0,.36,0l3.18-3.182a.246.246,0,0,0,0-.353l-3.18-3.182A.267.267,0,0,0,1465,32.068Z"
                          transform="translate(-1451 -30.569)" fill="#9ca3af" />
                      </svg>
                      Offers
                    </a>
                  </li>
                  <li class="nav-item" v-if="role != 'VIEWER'">
                    <span class="notification"></span>
                    <a class="nav-link" data-toggle="tab" href="#videos" onclick="openNav()">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="28.231" height="18.692"
                        viewBox="0 0 28.231 18.692">
                        <g id="Group_118" data-name="Group 118" transform="translate(-457.5 -66)">
                          <path id="Path_421" data-name="Path 421" d="M32.346,10.5,24,16.461l8.346,5.961Z"
                            transform="translate(452.385 58.885)" fill="none" stroke="#9ca3af" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" />
                          <path id="Path_422" data-name="Path 422"
                            d="M3.885,7.5H17a2.385,2.385,0,0,1,2.385,2.385V21.808A2.385,2.385,0,0,1,17,24.192H3.885A2.385,2.385,0,0,1,1.5,21.808V9.885A2.385,2.385,0,0,1,3.885,7.5Z"
                            transform="translate(457 59.5)" fill="none" stroke="#9ca3af" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2" />
                        </g>
                      </svg>
                      Videos
                    </a>
                  </li>
                  <li class="nav-item">
                    <span class="notification"></span>
                    <a class="nav-link" data-toggle="tab" href="#polls" onclick="openNav()">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="27" height="23.555"
                        viewBox="0 0 27 23.555">
                        <path id="Icon_material-grapheq" data-name="Icon material-grapheq"
                          d="M10.5,21.844h3V7.711h-3Zm6,4.711h3V3h-3Zm-12-9.422h3V12.422h-3Zm18,4.711h3V7.711h-3Zm6-9.422v4.711h3V12.422Z"
                          transform="translate(-4.5 -3)" fill="#9ca3af" />
                      </svg>
                      Polls
                    </a>
                  </li>
                  <li class="nav-item">
                    <span class="notification"></span>
                    <a class="nav-link" data-toggle="tab" href="#documents" onclick="openNav()">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="19.591" height="22.39"
                        viewBox="0 0 19.591 22.39">
                        <path id="Icon_open-document" data-name="Icon open-document"
                          d="M0,0V22.39H19.591V11.195H8.4V0ZM11.195,0V8.4h8.4ZM2.8,5.6H5.6V8.4H2.8Zm0,5.6H5.6v2.8H2.8Zm0,5.6H13.993v2.8H2.8Z"
                          fill="#9ca3af" />
                      </svg>
                      Documents
                    </a>
                  </li>
                  <li class="nav-item">
                    <span class="notification"></span>
                    <a class="nav-link" data-toggle="tab" href="#chat" onclick="openNav()">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="21.688" height="21.688"
                        viewBox="0 0 21.688 21.688">
                        <path id="Icon_material-chat_bubble_outline" data-name="Icon material-chat_bubble_outline"
                          d="M22.519,3H5.169A2.175,2.175,0,0,0,3,5.169V24.688l4.338-4.338H22.519a2.175,2.175,0,0,0,2.169-2.169V5.169A2.175,2.175,0,0,0,22.519,3Zm0,15.182H7.338L5.169,20.351V5.169H22.519Z"
                          transform="translate(-3 -3)" fill="#9ca3af" />
                      </svg>
                      Chat
                    </a>
                  </li>
                  <li class="nav-item" v-if="role != 'VIEWER'">
                    <span class="notification"></span>
                    <a class="nav-link" title="HI" data-toggle="tab" href="#slides" onclick="openNav()">
                      <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="22"
                        viewBox="0 0 18 22">
                        <path id="grid-slides-svgrepo-com"
                          d="M5,5H19a2,2,0,0,1,2,2V17a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V7A2,2,0,0,1,5,5ZM5,7V17H19V7ZM20,21v2H4V21ZM20,1V3H4V1Z"
                          transform="translate(-3 -1)" fill="#9ca3af" fill-rule="evenodd" />
                      </svg>
                      Slides
                    </a>
                  </li>
                </ul>
                <!-- <a href="javascript:void(0)" class="gray-btn record-video">
                           <svg xmlns="http://www.w3.org/2000/svg" width="22.4" height="22.4" viewBox="0 0 22.4 22.4">
                              <g id="Group_117" data-name="Group 117" transform="translate(-1140.88 -63.8)">
                                 <path id="Path_22" data-name="Path 22" d="M1154.2,30a11.2,11.2,0,1,0,11.2,11.2A11.216,11.216,0,0,0,1154.2,30Zm0,1.68a9.52,9.52,0,1,1-9.52,9.52A9.51,9.51,0,0,1,1154.2,31.68Z" transform="translate(-2.12 33.8)" fill="#ef4444"/>
                                 <path id="Path_23" data-name="Path 23" d="M1148.5,40.54a5.04,5.04,0,1,1,5.04,5.04A5.05,5.05,0,0,1,1148.5,40.54Z" transform="translate(-1.459 34.46)" fill="#ef4444"/>
                              </g>
                           </svg>
                           <span class="ml-2">13:03:34</span>
                        </a> -->
                <div class="gray-btn record-video" :class="recordingToaster ? 'RecordSec' : ''" id="record-video">
                  <span class="ml-2" v-if="!recordingToaster" id="livetime"></span>
                  <span class="" v-if="recordingToaster" id="livetime" style="color: red">REC</span>
                  <span v-if="recordingToaster" class="d-flex" id="livetime">
                    <div class="hrs">{{ hrs }}</div>
                    <div class="colon">:</div>
                    <div class="min">{{ min }}</div>
                    <div class="colon">:</div>
                    <div class="sec">{{ sec }}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- end:: Header -->
          <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor white-background" id="kt_content"
            style="
              padding-top: 0;
              padding-bottom: 0;
              overflow: hidden;
              position: relative;
              justify-content: center;
            ">
            <!-- begin:: Content -->
            <div style="flex: none" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
              <div class="loading" id="loading">Loading&#8230;</div>
              <div class="Scenary">
                <div class="Conference" id="mainCont">
                  <div class="Screen" id="Screen" style="align-items: center">
                    <video v-if="mainScreenStreamRef != null && mainScreenStreamRef.screenShare
                      " :id="mainScreenStreamRef.storageId + '_SHARE_SCREEN_VIDEO'" muted autoPlay playsInline
                      width="640" height="480" :srcObject.prop="mainScreenStreamRef != null && mainScreenStreamRef.mediaStream
                        "></video>

                    <video v-if="mainScreenStreamRef != null &&
                      !mainScreenStreamRef.screenShare &&
                      !mainScreenStreamRef.mutedVideo
                      " id="ID_OF_YOUR_VIDEO_ELEMENT" muted autoPlay playsInline :srcObject.prop="mainScreenStreamRef != null && mainScreenStreamRef.mediaStream
    "></video>

                    <div class="zoom-actions" style="display: block" v-if="mainScreenStreamRef != null">
                      <button class="btn-mute" @click="clickToMiniMiseStream(mainScreenStreamRef)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                          stroke="#e86969">
                          <g id="SVGRepo_bgCarrier" stroke-width="0" />

                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#c9c0c0"
                            stroke-width="4.8">
                            <path d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path opacity="0.5"
                              d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                              stroke="#808080" stroke-width="1.512" />
                          </g>

                          <g id="SVGRepo_iconCarrier">
                            <path d="M10.5 13.5H7.5M10.5 13.5V16.5M10.5 13.5L7 17" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.5 10.5H16.5M13.5 10.5V7.5M13.5 10.5L17 7" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.5 10.5H7.5M10.5 10.5V7.5M10.5 10.5L7 7" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.5 13.5H16.5M13.5 13.5V16.5M13.5 13.5L17 17" stroke="#808080" stroke-width="1.512"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path opacity="0.5"
                              d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                              stroke="#808080" stroke-width="1.512" />
                          </g>
                        </svg>
                      </button>
                    </div>

                    <div v-if="mainScreenStreamRef != null &&
                      !mainScreenStreamRef.screenShare &&
                      mainScreenStreamRef.socketId == socket.id
                      ? currentMicState
                      : mainScreenStreamRef != null &&
                      !mainScreenStreamRef.screenShare &&
                      !audioMicClose.includes(mainScreenStreamRef.socketId)
                      " style="position: absolute; top: '16%'; right: '3%'" :id="mainScreenStreamRef.socketId"
                      class="audio-icon-cont">
                      <span class="audio-icon" />
                    </div>

                    <div class="vertical-center play-button" v-if="mainScreenStreamRef != null &&
                      role == 'VIEWER' &&
                      !mainScreenStreamRef.is_autoplay &&
                      !mainScreenStreamRef.screenShare
                      " :id="mainScreenStreamRef.socketId + '_PEER_PLAY'">
                      <img @click="
                        playStream(
                          mainScreenStreamRef.storageId,
                          mainScreenStreamRef.socketId
                        )
                        " src="../../assets/media/play.png" class="r-img rounded participants-img-medium" />
                    </div>

                    <div class="vertical-center" v-if="mainScreenStreamRef != null &&
                      !mainScreenStreamRef.screenShare &&
                      mainScreenStreamRef.mutedVideo
                      ">
                      <img :src="BASE_URL + mainScreenStreamRef.userDetails.profileImg"
                        class="r-img rounded participants-img-medium" />
                    </div>
                    <a class="name-tag d-flex" :id="mainScreenStreamRef.screenShare
                      ? ''
                      : mainScreenStreamRef.socketId + 'name-tag'
                      " v-if="mainScreenStreamRef != null">
                      <a :id="mainScreenStreamRef.socketId + '_USER_MAIN_NAME'" style="padding-top: 3px">{{
                        mainScreenStreamRef.userDetails
                        ? mainScreenStreamRef.userDetails.user_name
                        : ""
                      }}</a>
                      <a>{{
                        mainScreenStreamRef.screenShare ? " (presentation)" : ""
                      }}</a>
                      <img @click="
                        role == 'HOST' || socket.id == mainScreenStreamRef.socketId
                          ? hideRaiseHand(mainScreenStreamRef.socketId)
                          : ''
                        " v-if="mainScreenStreamRef.raiseHand" :id="mainScreenStreamRef.socketId + '_BIG_SCREEN_RAISE'"
                        class="wave" src="../../assets/media/raised-hand.png" height="25" width="25" />
                    </a>

                    <div>
                      <button v-if="role != 'VIEWER'" type="button" style="z-index: 999999999999999"
                        class="submenu_btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                          <path
                            d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                        </svg>
                      </button>

                      <div class="dropdown-menu">
                        <ul class="submenu" v-if="mainScreenStreamRef != null">
                          <li v-if="!mainScreenStreamRef.screenShare &&
                            (role == 'HOST' || role == 'MODERATOR') &&
                            !audioMicClose.includes(mainScreenStreamRef.socketId) &&
                            mainScreenStreamRef.socketId != socket.id
                            " :id="mainScreenStreamRef.socketId + '_AUDIO_MAIN_MUTE_ACTIONs'
    " class="dropdown-item hover-item" @click="muteMic(mainScreenStreamRef.socketId)">
                            Mute Mic
                          </li>
                          <li v-if="!mainScreenStreamRef.screenShare &&
                            (role == 'HOST' || role == 'MODERATOR') &&
                            !mainScreenStreamRef.mutedVideo &&
                            mainScreenStreamRef.socketId != socket.id &&
                            !mainScreenStreamRef.mutedVideo
                            " class="dropdown-item hover-item" :id="mainScreenStreamRef.socketId + '_VIDEO_MAIN_UNMUTE_ACTIONs'
    " @click="muteVideo(mainScreenStreamRef.socketId)">
                            Mute Camera
                          </li>
                          <li v-if="!mainScreenStreamRef.screenShare &&
                            (role == 'HOST' || role == 'MODERATOR') &&
                            mainScreenStreamRef.socketId != socket.id
                            " class="dropdown-item hover-item" @click="removeUser(mainScreenStreamRef.socketId)">
                            Remove User
                          </li>
                          <li class="dropdown-item hover-item" @click="
                            renameUser(
                              mainScreenStreamRef,
                              mainScreenStreamRef.socketId
                            )
                            ">
                            Rename User
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="Dish" id="ALL_VIDEOS">
                    <div :id="peer.storageId + '_STREAsM_VIDEO_DIV'" style="background-color: black"
                      v-for="peer in peerShow">
                      <div class="lds-ellipsis" v-if="is_stream">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                      <a class="stream" v-if="!is_stream" :id="peer.storageId + '_STREsAM_VIDEO_POSITION'" :style="peer.mutedVideo == false
                        ? 'display: flex; justify-content: center;position: relative;width: inherit;'
                        : 'display: flex; justify-content: center;width: 100%;'
                        ">
                        <div v-if="!peer.screenShare && peer.socketId == socket.id
                          ? currentMicState
                          : !peer.screenShare &&
                          !audioMicClose.includes(peer.socketId)
                          " style="position: absolute; top: '16%'; right: '3%'" :id="peer.socketId"
                          class="audio-icon-cont">
                          <span class="audio-icon" />
                        </div>

                        <video v-if="!peer.screenShare && !peer.mutedVideo" :id="peer.socketId + 'STREAM_SRC'" muted
                          autoPlay playsInline :srcObject.prop="peer.mediaStream"></video>

                        <video :id="peer.socketId + 'STREAM_SCR_SRC'" v-if="peer.screenShare" style="visibility: visible"
                          muted autoPlay playsInline :srcObject.prop="peer.mediaStream"></video>

                        <div class="vertical-center" v-if="!peer.screenShare && peer.mutedVideo"
                          :id="peer.socketId + '_PEER_IMAGE'">
                          <img :src="BASE_URL + peer.userDetails.profileImg"
                            class="r-img rounded participants-img-medium" />
                        </div>

                        <div class="zoom-actions">
                          <button class="btn-mute" @click="clickToBigStream(peer)">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g id="SVGRepo_bgCarrier" stroke-width="0" />

                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                              <g id="SVGRepo_iconCarrier">
                                <path d="M17 7H14M17 7V10M17 7L13.5 10.5M7 17H10M7 17V14M7 17L10.5 13.5" stroke="#808080"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7 7H10M7 7V10M7 7L10.5 10.5M17 17H14M17 17V14M17 17L13.5 13.5" stroke="#808080"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                  d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                  stroke="#808080" stroke-width="1.5" />
                              </g>
                            </svg>
                          </button>
                        </div>

                        <a class="name-tag" :id="peer.socketId + 'peer-name-tag'"
                          v-if="!peer.screenShare && peer.userDetails.user_name">
                          <img src="../assets/media/verify.png" v-if="socket.id == peer.socketId" alt="" width="15"
                            height="15" style="margin-right: 6px" />
                          <p :id="peer.socketId + '_USER_NAME'" style="margin: auto">
                            {{ peer.userDetails.user_name }}
                          </p>
                          <img v-if="peer.raiseHand" class="wave" @click="
                            role == 'HOST' || socket.id == peer.socketId
                              ? hideRaiseHand(peer.socketId)
                              : ''
                            " src="../../assets/media/raised-hand.png" height="25" width="25" />
                        </a>

                        <a class="name-tag" :id="peer.socketId + '_SCREEN_USER_NAME'"
                          v-if="peer.screenShare && peer.userDetails.user_name">
                          {{ peer.userDetails.user_name }} (presentation)
                        </a>

                        <div class="vertical-center play-button" v-if="role == 'VIEWER' && !peer.is_autoplay && !peer.screenShare
                          " :id="peer.socketId + '_PEER_PLAY'">
                          <img @click="playStream(peer.storageId, peer.socketId)" src="../../assets/media/play.png"
                            class="r-img rounded participants-img-medium" />
                        </div>

                        <div v-if="!peer.screenShare" class="participant-actions" id="participant-actions">
                          <button @click="
                            role == 'GUEST' && peer.socketId == socket.id
                              ? muteMic(peer.socketId, true, 'mute')
                              : role == 'HOST'
                                ? muteMic(peer.socketId, true, 'mute')
                                : role == 'MODERATOR' &&
                                  peer.userDetails.webinarUserType != 'HOST'
                                  ? muteMic(peer.socketId, true, 'mute')
                                  : ''
                            " id="MIC" class="btn-mute" v-if="peer.socketId == socket.id
    ? currentMicState
    : !audioMicClose.includes(peer.socketId)
    ">
                            <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                              stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              class="css-i6dzq1">
                              <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                              <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                              <line x1="12" y1="19" x2="12" y2="23"></line>
                              <line x1="8" y1="23" x2="16" y2="23"></line>
                            </svg>
                          </button>
                          <button @click="
                            role == 'GUEST' && peer.socketId == socket.id
                              ? muteMic(peer.socketId, true, 'unmute')
                              : role == 'HOST'
                                ? muteMic(peer.socketId, true, 'unmute')
                                : role == 'MODERATOR' &&
                                  peer.userDetails.webinarUserType != 'HOST'
                                  ? muteMic(peer.socketId, true, 'unmute')
                                  : ''
                            " id="SLASH_MIC" class="btn-mute" v-if="peer.socketId == socket.id
    ? !currentMicState
    : audioMicClose.includes(peer.socketId)
    ">
                            <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                              stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              class="css-i6dzq1">
                              <line x1="1" y1="1" x2="23" y2="23"></line>
                              <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path>
                              <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path>
                              <line x1="12" y1="19" x2="12" y2="23"></line>
                              <line x1="8" y1="23" x2="16" y2="23"></line>
                            </svg>
                          </button>

                          <button @click="
                            role == 'GUEST' && peer.socketId == socket.id
                              ? muteVideo(peer.socketId, true, 'mute')
                              : role == 'HOST'
                                ? muteVideo(peer.socketId, true, 'mute')
                                : role == 'MODERATOR' &&
                                  peer.userDetails.webinarUserType != 'HOST'
                                  ? muteVideo(peer.socketId, true, 'mute')
                                  : ''
                            " :id="peer.socketId + 'CAM'" v-if="peer.socketId == socket.id ? video : !peer.mutedVideo"
                            class="btn-camera">
                            <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                              stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              class="css-i6dzq1">
                              <polygon points="23 7 16 12 23 17 23 7"></polygon>
                              <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                            </svg>
                          </button>

                          <button @click="
                            role == 'GUEST' && peer.socketId == socket.id
                              ? muteVideo(peer.socketId, true, 'unmute')
                              : role == 'HOST'
                                ? muteVideo(peer.socketId, true, 'unmute')
                                : role == 'MODERATOR' &&
                                  peer.userDetails.webinarUserType != 'HOST'
                                  ? muteVideo(peer.socketId, true, 'unmute')
                                  : ''
                            " :id="peer.socketId + 'SLASH_CAM'"
                            v-if="peer.socketId == socket.id ? !video : peer.mutedVideo" class="btn-camera">
                            <svg style="padding: 3px" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                              stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              class="css-i6dzq1">
                              <path
                                d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10">
                              </path>
                              <line x1="1" y1="1" x2="23" y2="23"></line>
                            </svg>
                          </button>
                        </div>

                        <div>
                          <button v-if="role != 'VIEWER'" type="button" class="submenu_btn dropdown-toggle"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                              <path
                                d="M0 256a56 56 0 1 1 112 0A56 56 0 1 1 0 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                            </svg>
                          </button>

                          <div class="dropdown-menu">
                            <ul class="submenu">
                              <!-- <li>rename</li> -->
                              <li v-if="!peer.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                !audioMicClose.includes(peer.socketId) &&
                                socket.id != peer.socketId
                                " class="dropdown-item hover-item" @click="muteMic(peer.socketId)">
                                Mute Mic
                              </li>
                              <li v-if="!peer.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                socket.id != peer.socketId &&
                                !peer.mutedVideo
                                " class="dropdown-item hover-item" @click="muteVideo(peer.socketId)">
                                Mute Camera
                              </li>
                              <li v-if="!peer.screenShare &&
                                (role == 'HOST' || role == 'MODERATOR') &&
                                socket.id != peer.socketId
                                " class="dropdown-item hover-item" @click="removeUser(peer.socketId)">
                                Remove User
                              </li>

                              <li class="dropdown-item hover-item" @click="renameUser(peer.userDetails, peer.socketId)">
                                Rename User
                              </li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="panel-body col-md-7" id="container-screens"></div> -->
            </div>

            <!-- end:: Content -->
          </div>

          <div id="kt_sidebar" v-if="role != 'VIEWER'" class="kt-sidebar kt-sidebar--fixed participants-video-controls">
            <ul class="buttons res-b">
              <li class="sound gray-btn">
                <div class="form-group row mb-0">
                  <label for="example-email-input" class="col-2 col-form-label">
                    <svg v-if="audioLevel == 0" xmlns="http://www.w3.org/2000/svg" version="1.1"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="18" x="0" y="0" viewBox="0 0 24 24"
                      style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
                      <g
                        transform="matrix(1.3699999999999997,0,0,1.3699999999999997,-4.43653985977172,-4.43989634752274)">
                        <path fill="#9ca3af" fill-rule="evenodd"
                          d="M16.25 6.003c0-1.03-1.176-1.618-2-1l-3.084 2.313a.75.75 0 0 1-.9-1.2l3.084-2.313c1.812-1.358 4.4-.066 4.4 2.2V13a.75.75 0 1 1-1.5 0zM3.47 3.47a.75.75 0 0 1 1.06 0l16 16a.75.75 0 0 1-1.06 1.06l-1.812-1.812c-.477 1.8-2.698 2.685-4.308 1.478l-4.267-3.198a1.25 1.25 0 0 0-.75-.25H6a2.75 2.75 0 0 1-2.75-2.75v-3.997A2.75 2.75 0 0 1 6 7.25h.191L3.47 4.532a.75.75 0 0 1 0-1.061zM7.69 8.752H6c-.69 0-1.25.56-1.25 1.249v3.998c0 .69.56 1.249 1.25 1.249h2.334a2.75 2.75 0 0 1 1.65.55l4.266 3.198c.824.618 2 .03 2-.999v-.686z"
                          clip-rule="evenodd" opacity="1" data-original="#000000" class=""></path>
                      </g>
                    </svg>
                    <svg v-if="audioLevel != 0 || audioLevel == 2" xmlns="http://www.w3.org/2000/svg" width="17"
                      height="17.99" viewBox="0 0 17 17.99">
                      <path id="Path_29" data-name="Path 29"
                        d="M185.924,965.016a1.279,1.279,0,0,0-.355,0,1.245,1.245,0,0,0-.66.307l-.009.008-4.23,3.963a.751.751,0,0,1-.513.2H176.25a2.261,2.261,0,0,0-2.25,2.25v4.5a2.261,2.261,0,0,0,2.25,2.25h3.907a.751.751,0,0,1,.513.2l4.23,3.963.009.008a1.25,1.25,0,0,0,2.091-.925v-15.5a1.276,1.276,0,0,0-.744-1.144A1.238,1.238,0,0,0,185.924,965.016Zm-.424,1.811v14.346l-3.8-3.565a2.249,2.249,0,0,0-1.538-.608H176.25a.739.739,0,0,1-.75-.75v-4.5a.739.739,0,0,1,.75-.75h3.907a2.249,2.249,0,0,0,1.538-.608Zm4.006,3.3a.75.75,0,0,0-.674,1.1,6.083,6.083,0,0,1,0,5.543.749.749,0,0,0,.083.844.749.749,0,0,0,1.253-.164,7.577,7.577,0,0,0,0-6.9.749.749,0,0,0-.662-.419Z"
                        transform="translate(-174 -965.005)" fill="#9ca3af" />
                    </svg>

                  </label>
                  <div class="col-10">
                    <input class="form-control" type="range" min="0" max="5" step="1" v-model="audioLevel"
                      @input="handleAudioLevel" style="padding: 0;" />
                  </div>
                </div>
              </li>
              <li class="vid gray-btn" :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" style="position: relative">
                <div class="form-group mb-0">
                  <div class="btn-group dropup">
                    <button v-if="!currentMicState" type="button" class="btn btn-primary" id="mic-btn"
                      :disabled="is_disable" v-on:click="handleMic()">
                      <span class="icon">
                        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-mic-off">
                          <line x1="1" y1="1" x2="23" y2="23"></line>
                          <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6" fill="white"></path>
                          <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23" fill="white"></path>
                          <line x1="12" y1="19" x2="12" y2="23"></line>
                          <line x1="8" y1="23" x2="16" y2="23"></line>
                        </svg>
                      </span>
                    </button>
                    <button v-if="currentMicState" :disabled="is_disable" type="button" class="btn btn-primary"
                      id="mic-btn" v-on:click="handleMic()">
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M12 3a3 3 0 0 0-3 3v4a3 3 0 0 0 6 0V6a3 3 0 0 0-3-3zm0-2a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z"
                            fill="rgba(149,164,166,1)" />
                        </svg>
                      </span>
                    </button>
                    <!-- Mic dropdown -->
                    <div class="btn-group dropup">
                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" :disabled="is_disable"></button>
                      <div class="dropdown-menu">
                        <a>
                          <h3 class="microphone">Mic</h3>
                        </a>
                        <span class="" style="margin-left: 14px" href="#" v-if="!is_mic_allow">No device found.
                        </span>

                        <div v-for="device in deviceData" :key="device.id">
                          <a class="dropdown-item font-weight-bold" href="#" v-if="is_mic_allow &&
                            device.kind == 'audioinput' &&
                            device.deviceId == selectedAudio
                            " @click="changeMic(device)">{{ device.label }}
                            <i style="color: green">&#x2713; </i>
                          </a>
                          <a class="dropdown-item" href="#" v-if="device.kind == 'audioinput' &&
                            device.deviceId != selectedAudio
                            " @click="changeMic(device)">{{ device.label }}
                          </a>
                        </div>

                        <a>
                          <h3 class="microphone mt-2">Speaker</h3>
                        </a>

                        <span class="" style="margin-left: 14px" href="#" v-if="!is_mic_allow">No device found.
                        </span>

                        <div v-for="device in deviceData" :key="device.id">
                          <a class="dropdown-item font-weight-bold" href="#" v-if="is_mic_allow &&
                            device.kind == 'audiooutput' &&
                            device.deviceId == selectedOuterAudio
                            " @click="changeOuterSpeaker(device.deviceId)">{{ device.label }}
                            <i style="color: green">&#x2713; </i>
                          </a>
                          <a class="dropdown-item" href="#" v-if="device.kind == 'audiooutput' &&
                            device.deviceId != selectedOuterAudio
                            " @click="changeOuterSpeaker(device.deviceId)">{{ device.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- Mic dropdown -->
                  </div>
                </div>
              </li>

              <li class="vid gray-btn" :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" style="position: relative">
                <div class="form-group mb-0">
                  <!-- Video button start -->
                  <div class="btn-group dropup">
                    <button v-if="!currentVideoState" type="button" class="btn btn-primary" id="mic-btn"
                      :disabled="is_disable" @click="handleVideo()">
                      <span id="offVideo" style="margin-left: 0px;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="28" fill="#9ca3af" class="bi bi-camera-video-off"
                          viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56-10-14 .814-.58 10 14-.814.58z" />
                        </svg>
                      </span>
                    </button>
                    <button v-if="currentVideoState" type="button" class="btn btn-primary" id="mic-btn"
                      :disabled="is_disable" @click="handleVideo()">
                      <span id="offVideo" style="margin-left: 0px;">
                        <svg class="header-menu-icon" xmlns="http://www.w3.org/2000/svg" width="28.231" height="19"
                          viewBox="0 0 28.231 18.692">
                          <g id="Group_118" data-name="Group 118" transform="translate(-457.5 -66)">
                            <path id="Path_421" data-name="Path 421" d="M32.346,10.5,24,16.461l8.346,5.961Z"
                              transform="translate(452.385 58.885)" fill="none" stroke="#9ca3af" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                            <path id="Path_422" data-name="Path 422"
                              d="M3.885,7.5H17a2.385,2.385,0,0,1,2.385,2.385V21.808A2.385,2.385,0,0,1,17,24.192H3.885A2.385,2.385,0,0,1,1.5,21.808V9.885A2.385,2.385,0,0,1,3.885,7.5Z"
                              transform="translate(457 59.5)" fill="none" stroke="#9ca3af" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2"></path>
                          </g>
                        </svg>
                      </span>
                    </button>
                    <!-- Mic dropdown -->
                    <div class="btn-group dropup">
                      <button type="button" :disabled="is_disable" class="btn dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"></button>
                      <div class="dropdown-menu">
                        <a>
                          <h3 class="microphone">Camera</h3>
                        </a>
                        <span class="" style="margin-left: 14px" href="#" v-if="!is_cam_allow">No device found.
                        </span>
                        <div v-for="device in deviceData" :key="device.id" v-if="is_cam_allow">
                          <a class="dropdown-item font-weight-bold" href="#" v-if="device.kind == 'videoinput' &&
                            device.deviceId == selectedVideo
                            " @click="changeMic(device)">{{ device.label }}
                            <i style="color: green">&#x2713; </i>
                          </a>
                          <a class="dropdown-item" href="#" v-if="device.kind == 'videoinput' &&
                            device.deviceId != selectedVideo
                            " @click="changeMic(device)">{{ device.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- Mic dropdown -->
                  </div>
                </div>
              </li>
              <li class="video gray-btn" :class="role == 'VIEWER' ? 'disableBottomIcon' : ''">
                <div class="form-group mb-0">
                  <div class="btn-group dropup">
                    <button type="button" :disabled="is_disable" class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.5" viewBox="0 0 21 17.5">
                          <path id="Path_41" data-name="Path 41"
                            d="M547.25,965.5a2.761,2.761,0,0,0-2.75,2.75v9a2.761,2.761,0,0,0,2.75,2.75h15.5a2.761,2.761,0,0,0,2.75-2.75v-9a2.761,2.761,0,0,0-2.75-2.75Zm0,1.5h15.5a1.239,1.239,0,0,1,1.25,1.25v9a1.239,1.239,0,0,1-1.25,1.25h-15.5a1.239,1.239,0,0,1-1.25-1.25v-9A1.239,1.239,0,0,1,547.25,967Zm3.5,14.5a.758.758,0,0,0-.29.054.74.74,0,0,0-.247.162.748.748,0,0,0,0,1.068.74.74,0,0,0,.247.162.758.758,0,0,0,.29.054h8.5a.758.758,0,0,0,.29-.054.74.74,0,0,0,.247-.162.748.748,0,0,0,0-1.068.74.74,0,0,0-.247-.162.758.758,0,0,0-.29-.054Z"
                            transform="translate(-544.5 -965.5)" fill="#9ca3af" />
                        </svg>
                      </span>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a v-if="!screenShareStarted" @click="ScreenShare()" class="dropdown-item" href="#">Share Screen</a>
                      <a v-if="screenShareStarted" @click="ScreenShare()" class="dropdown-item" href="#">Stop Sharing</a>
                    </div>
                  </div>
                </div>
              </li>
              <li class="video gray-btn p-0 border-0" id="end_sesssion">
                <a href="#" @click="handleLeave()" class="red-btn">End Broadcast</a>
              </li>
              <li v-if="role == 'HOST' || role == 'MODERATOR'" :class="role == 'VIEWER' ? 'disableBottomIcon' : ''"
                class="video gray-btn">
                <div class="form-group mb-0">
                  <div class="btn-group">
                    <button :disabled="is_disable" type="button" class="btn btn-primary" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <span class="icon" :class="topRecording ? 'recordAnimation' : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_45" data-name="Path 45"
                            d="M647,964a10,10,0,1,0,10,10A10.011,10.011,0,0,0,647,964Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5A8.489,8.489,0,0,1,647,965.5Zm0,4a4.5,4.5,0,1,0,4.5,4.5A4.512,4.512,0,0,0,647,969.5Z"
                            transform="translate(-637 -964)" fill="#ef4444" />
                        </svg>
                      </span>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item" href="#" @click="startRecording('Local')"
                        v-if="!recordingToaster && !topRecording">Start
                        Local Recording</a>
                      <a class="dropdown-item" href="#" @click="startRecording('Cloud')"
                        v-if="!recordingToaster && !topRecording">Start
                        Cloud Recording</a>

                      <a class="dropdown-item" href="#" v-if="topRecording || recordingToaster"
                        @click="recordingToaster && hrs ? stopAskModal() : ''">{{ recordingToaster && hrs ? 'Stop ' +
                          recordType + ' Recording' : '' }} <span v-if="!recordingToaster"> {{ recorderUsers }} is recording
                          the call </span> </a>
                    </div>
                  </div>
                </div>
              </li>
              <li :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" class="addUser video gray-btn">
                <div class="form-group mb-0">
                  <span class="icon p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.827" height="21.158" viewBox="0 0 19.827 21.158">
                      <g id="Iconly_Curved_Add_User" data-name="Iconly/Curved/Add User" transform="translate(-2.1 -1.4)">
                        <g id="Add_User" data-name="Add User">
                          <path id="Stroke_1" data-name="Stroke 1"
                            d="M9.922,21.808c-3.814,0-7.072-.577-7.072-2.887S6.087,14.51,9.922,14.51c3.814,0,7.072,2.081,7.072,4.39S13.757,21.808,9.922,21.808Z"
                            fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            fill-rule="evenodd" />
                          <path id="Stroke_3" data-name="Stroke 3" d="M9.922,11.216a4.517,4.517,0,1,0-.032,0Z" fill="none"
                            stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            fill-rule="evenodd" />
                          <path id="Stroke_5" data-name="Stroke 5" d="M19.131,8.129v4.01" fill="none" stroke="#9ca3af"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                          <path id="Stroke_7" data-name="Stroke 7" d="M21.178,10.134h-4.09" fill="none" stroke="#9ca3af"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
              </li>
              <li :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" class="video gray-btn"
                @click="is_disable ? '' : raiseHand()" style="cursor: pointer">
                <div class="form-group mb-0">
                  <div class="btn-group dropup">
                    <span class="icon p-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="20.501" viewBox="0 0 19.5 20.501">
                        <path id="Path_51" data-name="Path 51" :style="isRaiseHand ? 'fill: #ffc83d' : ''"
                          d="M847.75,963.5a2.253,2.253,0,0,0-2,1.247,2.206,2.206,0,0,0-1-.247,2.261,2.261,0,0,0-2.25,2.25v9.241c-.734-.418-1.646-.946-2.293-1.227a3,3,0,0,0-1.2-.264,2.515,2.515,0,0,0-2.1,1.052,2.571,2.571,0,0,0-.4,1.053.751.751,0,0,0,.245.638s4.171,3.756,5.347,5A5.224,5.224,0,0,0,845.961,984h4.257A5.762,5.762,0,0,0,856,978.282V968.75a2.261,2.261,0,0,0-2.25-2.25,2.193,2.193,0,0,0-.761.143,2.257,2.257,0,0,0-2.239-2.143,2.17,2.17,0,0,0-1,.267A2.246,2.246,0,0,0,847.75,963.5Zm0,1.5a.739.739,0,0,1,.75.75v7.5a.758.758,0,0,0,.054.29.74.74,0,0,0,.162.247.748.748,0,0,0,1.068,0,.74.74,0,0,0,.162-.247.758.758,0,0,0,.054-.29v-6.5a.75.75,0,0,1,1.5,0v7a.758.758,0,0,0,.054.29.74.74,0,0,0,.162.247.748.748,0,0,0,1.068,0,.74.74,0,0,0,.162-.247.758.758,0,0,0,.054-.29v-5a.75.75,0,0,1,1.5,0v9.519a4.239,4.239,0,0,1-4.269,4.231h-4.3a3.716,3.716,0,0,1-2.745-1.288h0c-1.256-1.328-4.586-4.309-5.07-4.743.02-.041.006-.042.035-.086.132-.2.278-.383.857-.383a1.5,1.5,0,0,1,.6.14c.564.245,1.765.924,2.525,1.356A1.274,1.274,0,0,0,844,976.409V966.75a.75.75,0,0,1,1.5,0v6.5a.758.758,0,0,0,.054.29.74.74,0,0,0,.162.247.748.748,0,0,0,1.068,0,.74.74,0,0,0,.162-.247.758.758,0,0,0,.054-.29v-7.5A.739.739,0,0,1,847.75,965Z"
                          transform="translate(-836.5 -963.5)" fill="#9ca3af" />
                      </svg>
                    </span>
                  </div>
                </div>
              </li>
              <li :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" class="emoji video gray-btn">
                <div class="form-group mb-0">
                  <div class="btn-group dropup">
                    <button :disabled="is_disable" type="button" class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_55" data-name="Path 55"
                            d="M939,964a10,10,0,1,0,10,10A10.011,10.011,0,0,0,939,964Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5A8.489,8.489,0,0,1,939,965.5Zm-4.25,4.5a.758.758,0,0,0-.29.054.74.74,0,0,0-.247.162.748.748,0,0,0,0,1.068.74.74,0,0,0,.247.162.758.758,0,0,0,.29.054h2a.758.758,0,0,0,.29-.054.74.74,0,0,0,.247-.162.748.748,0,0,0,0-1.068.74.74,0,0,0-.247-.162.758.758,0,0,0-.29-.054Zm6.5,0a.758.758,0,0,0-.29.054.74.74,0,0,0-.247.162.748.748,0,0,0,0,1.068.74.74,0,0,0,.247.162.758.758,0,0,0,.29.054h2a.758.758,0,0,0,.29-.054.74.74,0,0,0,.247-.162.748.748,0,0,0,0-1.068.74.74,0,0,0-.247-.162.758.758,0,0,0-.29-.054Zm-6.5,4a.751.751,0,0,0-.75.75,5.006,5.006,0,1,0,10,0,.751.751,0,0,0-.75-.75Zm.9,1.5h6.7a3.37,3.37,0,0,1-6.7,0Z"
                            transform="translate(-929 -964)" fill="#9ca3af" />
                        </svg>
                      </span>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item" href="#">Raise</a>
                      <a class="dropdown-item" href="#">Off</a>
                    </div>
                  </div>
                </div>
              </li>
              <li :class="role == 'VIEWER' ? 'disableBottomIcon' : ''" class="video gray-btn">
                <div class="form-group mb-0">
                  <div class="btn-group dropup">
                    <button type="button" :disabled="is_disable" class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.5" viewBox="0 0 21 17.5">
                          <path id="Path_41" data-name="Path 41"
                            d="M547.25,965.5a2.761,2.761,0,0,0-2.75,2.75v9a2.761,2.761,0,0,0,2.75,2.75h15.5a2.761,2.761,0,0,0,2.75-2.75v-9a2.761,2.761,0,0,0-2.75-2.75Zm0,1.5h15.5a1.239,1.239,0,0,1,1.25,1.25v9a1.239,1.239,0,0,1-1.25,1.25h-15.5a1.239,1.239,0,0,1-1.25-1.25v-9A1.239,1.239,0,0,1,547.25,967Zm3.5,14.5a.758.758,0,0,0-.29.054.74.74,0,0,0-.247.162.748.748,0,0,0,0,1.068.74.74,0,0,0,.247.162.758.758,0,0,0,.29.054h8.5a.758.758,0,0,0,.29-.054.74.74,0,0,0,.247-.162.748.748,0,0,0,0-1.068.74.74,0,0,0-.247-.162.758.758,0,0,0-.29-.054Z"
                            transform="translate(-544.5 -965.5)" fill="#9ca3af" />
                        </svg>
                      </span>
                    </button>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item" href="#" id="oneByFour">1 X 4</a>
                      <a class="dropdown-item" href="#" id="fourByFour">2 X 2</a>
                    </div>
                  </div>
                </div>
              </li>
              <!-- <li class="gray-btn" id="share_session_url">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M4 19h16v-5h2v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-6h2v5zm8-9H9a5.992 5.992 0 0 0-4.854 2.473A8.003 8.003 0 0 1 12 6V2l8 6-8 6v-4z"
                          fill="rgba(149,164,166,1)"
                        />
                      </svg>
                    </div>
                  </li> -->
            </ul>
          </div>
        </div>

        <div id="mySidenav" class="sidenav">
          <div class="tab-content">
            <div id="participant1" class="tab-pane fade">
              <div class="align-items-center">
                <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">
                  Participants
                </h5>
              </div>
              <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">
                <svg id="Icon_open-collapse-left" data-name="Icon open-collapse-left" xmlns="http://www.w3.org/2000/svg"
                  width="22.785" height="22.785" viewBox="0 0 22.785 22.785">
                  <path id="Icon_open-collapse-left-2" data-name="Icon open-collapse-left"
                    d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z" fill="#2d8cff" />
                </svg>
              </a>

              <div id="participants1">
                <!-- begin:: Form-->
                <div class="mt-5">
                  <div class="input-group mb-3" style="width: 95%">
                    <input class="form-control" placeholder="Search..." v-model="searchMessage"
                      @keydown="searchInputHandler" />
                    <div class="input-group-append">
                      <span class="input-group-text p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                          <g id="Group_58" data-name="Group 58" transform="translate(-1520 -98)">
                            <rect id="Rectangle_125" data-name="Rectangle 125" width="32" height="32" rx="8"
                              transform="translate(1520 98)" fill="#2d8cff" />
                            <g id="Icon_feather-search" data-name="Icon feather-search"
                              transform="translate(1529.495 107.645)">
                              <path id="Path_426" data-name="Path 426"
                                d="M15.622,10.061A5.561,5.561,0,1,1,10.061,4.5,5.561,5.561,0,0,1,15.622,10.061Z"
                                transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.5" />
                              <path id="Path_427" data-name="Path 427" d="M28,28l-3.024-3.024"
                                transform="translate(-15.486 -15.486)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <div class="">
                    <ul class="nav nav-tabs nav-tabs-line nav-tabs-inverse participants-rols" style="margin-right: 13px">
                      <li class="nav-item" @click="getFilterParticipate('HOST')">
                        <a class="nav-link active" data-toggle="tab" href="#hosts-tab">Hosts</a>
                      </li>
                      <li class="nav-item" @click="getFilterParticipate('MODERATOR')">
                        <a class="nav-link" data-toggle="tab" href="#moderators-tab">Moderators</a>
                      </li>
                      <li class="nav-item" @click="getFilterParticipate('GUEST')">
                        <a class="nav-link" data-toggle="tab" href="#guests-tab">Guests</a>
                      </li>
                      <li class="nav-item" @click="getFilterParticipate('VIEWER')">
                        <a class="nav-link" data-toggle="tab" href="#viewers-tab">Viewers</a>
                      </li>
                    </ul>
                    <div class="tab-content mt-5" id="myTabContent" style="padding-right: 15px">
                      <div class="tab-pane fade show active" id="hosts-tab" role="tabpanel"
                        aria-labelledby="kt_tab_pane_2">
                        <h3 style="text-align: center" v-if="filterdParticipants.length == 0">
                          No Participants.
                        </h3>
                        <div v-for="parti in filterdParticipants" :key="parti.storageId" class="row mt-3">
                          <div class="col-6" style="display: flex; align-items: center">
                            <img :src="parti.userDetails.profileImg
                              ? BASE_URL + parti.userDetails.profileImg
                              : '../../assets/media/default.jpg'
                              " alt="John Doe" class="mr-2 rounded participants-img-small" />
                            <div class="participant-menber-info">
                              <h5 class="text-white extra-small">
                                {{ parti.userDetails.user_name }}
                              </h5>
                              <!-- <p class="extra-small">Project Manager</p> -->
                            </div>
                          </div>
                          <div style="display: none" class="col-6" :id="'UPDATING_' + parti.socketId">
                            <span class="spinner spinner-white spinner-right"></span>
                          </div>

                          <div class="col-6 text-right" :id="'HANDLE_BUTTON_' + parti.socketId">
                            <p v-if="parti.is_mute" class="badge badge-pill badge-info bg-blue">
                              Muted
                            </p>
                            <p style="margin-left: 5px" v-if="parti.is_ban" class="badge badge-pill badge-info bg-danger">
                              Banned
                            </p>

                            <span style="margin-left: 8px" v-if="role == 'HOST' || role == 'MODERATOR'">
                              <a href="javascript:void(0)" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="19" viewBox="0 0 5 19">
                                  <g id="Group_334" data-name="Group 334" transform="translate(-1545 -326)">
                                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 326)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 333)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 340)" fill="#fff" opacity="0.5" />
                                  </g>
                                </svg>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right bg-gray">
                                <div class="border-bottom-white" style="display: flex; align-items: center">
                                  <img :src="parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/default.jpg'
                                    " :alt="parti.firstName" class="mr-2 rounded participants-img-small" />

                                  <div class="participant-menber-info">
                                    <h5 class="text-white extra-small">
                                      {{ parti.userDetails.user_name }}
                                    </h5>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="parti.socketId != socket.id">
                                  <div class="col-8">
                                    <a @click="BannedUser(parti.socketId)" href="#" style="color: #fff">{{
                                      parti.is_ban ? "Remove from ban" : "Ban User"
                                    }}</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.76" height="15.76"
                                      viewBox="0 0 15.76 15.76">
                                      <path id="Icon_awesome-ban" data-name="Icon awesome-ban"
                                        d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                        transform="translate(-0.563 -0.563)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row mb-2" v-if="(!parti.screenShare &&
                                    socket.id != parti.socketId &&
                                    role == 'HOST') ||
                                  (role == 'MODERATOR' &&
                                    !audioMicClose.includes(parti.socketId) &&
                                    parti.socketId != socket.id)
                                  ">
                                  <div class="col-8">
                                    <a @click="muteMic(parti.socketId)" href="#" style="color: #fff">Mute User</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.575" height="13.182"
                                      viewBox="0 0 17.575 13.182">
                                      <path id="Icon_awesome-volume-mute" data-name="Icon awesome-volume-mute"
                                        d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                        transform="translate(0 -4.5)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <a href="#" style="color: #fff" @click="
                                      renameUser(parti.userDetails, parti.socketId)
                                      ">Edit Name</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.547" height="15.67"
                                      viewBox="0 0 15.547 15.67">
                                      <g id="Group_348" data-name="Group 348" transform="translate(0.5 0.624)">
                                        <path id="Path_491" data-name="Path 491"
                                          d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                          transform="translate(-3 -4.467)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Path_492" data-name="Path 492"
                                          d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                          transform="translate(-7.662 -2.818)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="moderators-tab" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                        <h3 style="text-align: center" v-if="filterdParticipants.length == 0">
                          No Participants.
                        </h3>

                        <div v-for="parti in filterdParticipants" class="row mt-3" :key="parti.socketId"
                          :id="'UPDAsTING_' + parti.socketId">
                          <div class="col-6" style="display: flex; align-items: center">
                            <img :src="parti.userDetails.profileImg
                              ? BASE_URL + parti.userDetails.profileImg
                              : '../../assets/media/default.jpg'
                              " alt="John Doe" class="mr-2 rounded participants-img-small" />
                            <div class="participant-menber-info">
                              <h5 class="text-white extra-small">
                                {{ parti.userDetails.user_name }}
                              </h5>
                            </div>
                          </div>
                          <div style="display: none" class="col-6" :id="'UPDATING_' + parti.socketId">
                            <span class="spinner spinner-white spinner-right"></span>
                          </div>

                          <div class="col-6 text-right">
                            <p v-if="parti.is_mute" class="badge badge-pill badge-info bg-blue">
                              Muted
                            </p>
                            <p v-if="parti.is_ban" class="badge badge-pill badge-info bg-danger">
                              Banned
                            </p>

                            <span style="margin-left: 8px" v-if="role == 'HOST' ||
                              role == 'MODERATOR' ||
                              role == 'MODERATOR'
                              ">
                              <a href="javascript:void(0)" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="19" viewBox="0 0 5 19">
                                  <g id="Group_334" data-name="Group 334" transform="translate(-1545 -326)">
                                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 326)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 333)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 340)" fill="#fff" opacity="0.5" />
                                  </g>
                                </svg>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right bg-gray">
                                <div class="border-bottom-white" style="display: flex; align-items: center">
                                  <img :src="parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/default.jpg'
                                    " :alt="parti.firstName" class="mr-2 rounded participants-img-small" />
                                  <div class="participant-menber-info">
                                    <h5 class="text-white extra-small">
                                      {{ parti.userDetails.user_name }}
                                    </h5>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="parti.socketId != socket.id">
                                  <div class="col-8">
                                    <a @click="BannedUser(parti.storageId)" href="#" style="color: #fff">{{
                                      parti.is_ban ? "Remove from ban" : "Ban User"
                                    }}</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.76" height="15.76"
                                      viewBox="0 0 15.76 15.76">
                                      <path id="Icon_awesome-ban" data-name="Icon awesome-ban"
                                        d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                        transform="translate(-0.563 -0.563)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row mb-2" v-if="(!parti.screenShare &&
                                    socket.id != parti.socketId &&
                                    role == 'HOST') ||
                                  (role == 'MODERATOR' &&
                                    !audioMicClose.includes(parti.socketId))
                                  ">
                                  <div class="col-8">
                                    <a @click="muteMic(parti.socketId)" href="#" style="color: #fff">Mute User</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.575" height="13.182"
                                      viewBox="0 0 17.575 13.182">
                                      <path id="Icon_awesome-volume-mute" data-name="Icon awesome-volume-mute"
                                        d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                        transform="translate(0 -4.5)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <a href="#" style="color: #fff" @click="
                                      renameUser(parti.userDetails, parti.socketId)
                                      ">Edit Name</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.547" height="15.67"
                                      viewBox="0 0 15.547 15.67">
                                      <g id="Group_348" data-name="Group 348" transform="translate(0.5 0.624)">
                                        <path id="Path_491" data-name="Path 491"
                                          d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                          transform="translate(-3 -4.467)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Path_492" data-name="Path 492"
                                          d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                          transform="translate(-7.662 -2.818)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <!-- <p class="badge badge-pill badge-info">Host</p> -->
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="guests-tab" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                        <h3 style="text-align: center" v-if="filterdParticipants.length == 0">
                          No Participants.
                        </h3>
                        <div v-for="parti in filterdParticipants" :key="parti.socketId"
                          :id="'UPDATIsNG_' + parti.socketId" class="row mt-3">
                          <div class="col-6" style="display: flex; align-items: center">
                            <img :src="parti.userDetails.profileImg
                              ? BASE_URL + parti.userDetails.profileImg
                              : '../../assets/media/default.jpg'
                              " alt="John Doe" class="mr-2 rounded participants-img-small" />
                            <div class="participant-menber-info">
                              <h5 class="text-white extra-small">
                                {{ parti.userDetails.user_name }}
                              </h5>
                            </div>
                          </div>
                          <div style="display: none" class="col-6" :id="'UPDATING_' + parti.socketId">
                            <span class="spinner spinner-white spinner-right"></span>
                          </div>
                          <div class="col-6 text-right">
                            <p v-if="parti.is_mute" class="badge badge-pill badge-info bg-blue">
                              Muted
                            </p>
                            <p v-if="parti.is_ban" class="badge badge-pill badge-info bg-danger">
                              Banned
                            </p>

                            <span style="margin-left: 8px" v-if="role == 'HOST' ||
                              role == 'MODERATOR' ||
                              role == 'MODERATOR'
                              ">
                              <a href="javascript:void(0)" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="19" viewBox="0 0 5 19">
                                  <g id="Group_334" data-name="Group 334" transform="translate(-1545 -326)">
                                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 326)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 333)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 340)" fill="#fff" opacity="0.5" />
                                  </g>
                                </svg>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right bg-gray">
                                <div class="border-bottom-white" style="display: flex; align-items: center">
                                  <img :src="parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/default.jpg'
                                    " :alt="parti.firstName" class="mr-2 rounded participants-img-small" />
                                  <div class="participant-menber-info">
                                    <h5 class="text-white extra-small">
                                      {{ parti.userDetails.user_name }}
                                    </h5>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="parti.socketId != socket.id">
                                  <div class="col-8">
                                    <a @click="BannedUser(parti.storageId)" href="#" style="color: #fff">{{
                                      parti.is_ban ? "Remove from ban" : "Ban User"
                                    }}</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.76" height="15.76"
                                      viewBox="0 0 15.76 15.76">
                                      <path id="Icon_awesome-ban" data-name="Icon awesome-ban"
                                        d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                        transform="translate(-0.563 -0.563)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row mb-2" v-if="(!parti.screenShare &&
                                    socket.id != parti.socketId &&
                                    role == 'HOST') ||
                                  (role == 'MODERATOR' &&
                                    !audioMicClose.includes(parti.socketId))
                                  ">
                                  <div class="col-8">
                                    <a @click="muteMic(parti.socketId)" href="#" style="color: #fff">Mute User</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.575" height="13.182"
                                      viewBox="0 0 17.575 13.182">
                                      <path id="Icon_awesome-volume-mute" data-name="Icon awesome-volume-mute"
                                        d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                        transform="translate(0 -4.5)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <a href="#" style="color: #fff" @click="
                                      renameUser(parti.userDetails, parti.socketId)
                                      ">Edit Name</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.547" height="15.67"
                                      viewBox="0 0 15.547 15.67">
                                      <g id="Group_348" data-name="Group 348" transform="translate(0.5 0.624)">
                                        <path id="Path_491" data-name="Path 491"
                                          d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                          transform="translate(-3 -4.467)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Path_492" data-name="Path 492"
                                          d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                          transform="translate(-7.662 -2.818)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane fade" id="viewers-tab" role="tabpanel" aria-labelledby="kt_tab_pane_2">
                        <h3 style="text-align: center" v-if="filterdParticipants.length == 0">
                          No Participants.
                        </h3>
                        <div v-for="parti in filterdParticipants" :key="parti.userid" :id="'UPDATsING_' + parti.userid"
                          class="row mt-3">
                          <div class="col-6" style="display: flex; align-items: center">
                            <img :src="parti.userDetails.profileImg
                              ? BASE_URL + parti.userDetails.profileImg
                              : '../../assets/media/default.jpg'
                              " alt="John Doe" class="mr-2 rounded participants-img-small" />
                            <div class="participant-menber-info">
                              <h5 class="text-white extra-small">
                                {{ parti.userDetails.user_name }}
                              </h5>
                            </div>
                          </div>

                          <div style="display: none" class="col-6" :id="'UPDATING_' + parti.userid">
                            <span class="spinner spinner-white spinner-right"></span>
                          </div>
                          <div class="col-6 text-right">
                            <p v-if="parti.is_mute" class="badge badge-pill badge-info bg-blue">
                              Muted
                            </p>
                            <p v-if="parti.is_ban" class="badge badge-pill badge-info bg-danger">
                              Banned
                            </p>

                            <span style="margin-left: 8px" v-if="role == 'HOST' ||
                              role == 'MODERATOR' ||
                              role == 'MODERATOR'
                              ">
                              <a href="javascript:void(0)" data-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="19" viewBox="0 0 5 19">
                                  <g id="Group_334" data-name="Group 334" transform="translate(-1545 -326)">
                                    <circle id="Ellipse_30" data-name="Ellipse 30" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 326)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_31" data-name="Ellipse 31" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 333)" fill="#fff" opacity="0.5" />
                                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="2.5" cy="2.5" r="2.5"
                                      transform="translate(1545 340)" fill="#fff" opacity="0.5" />
                                  </g>
                                </svg>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right bg-gray">
                                <div class="border-bottom-white" style="display: flex; align-items: center">
                                  <img :src="parti.userDetails.profileImg
                                    ? BASE_URL + parti.userDetails.profileImg
                                    : '../../assets/media/default.jpg'
                                    " :alt="parti.firstName" class="mr-2 rounded participants-img-small" />
                                  <div class="participant-menber-info">
                                    <h5 class="text-white extra-small">
                                      {{ parti.userDetails.user_name }}
                                    </h5>
                                  </div>
                                </div>

                                <div class="row mb-2" v-if="parti.socketId != socket.id">
                                  <div class="col-8">
                                    <a @click="BannedUser(parti.storageId)" href="#" style="color: #fff">{{
                                      parti.is_ban ? "Remove from ban" : "Ban User"
                                    }}</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.76" height="15.76"
                                      viewBox="0 0 15.76 15.76">
                                      <path id="Icon_awesome-ban" data-name="Icon awesome-ban"
                                        d="M8.443.563a7.88,7.88,0,1,0,7.88,7.88A7.88,7.88,0,0,0,8.443.563Zm4.134,3.746a5.847,5.847,0,0,1,.657,7.487L5.089,3.651a5.847,5.847,0,0,1,7.487.657ZM4.308,12.577a5.847,5.847,0,0,1-.657-7.487L11.8,13.234A5.847,5.847,0,0,1,4.308,12.577Z"
                                        transform="translate(-0.563 -0.563)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row mb-2" v-if="(!parti.screenShare &&
                                    socket.id != parti.socketId &&
                                    role == 'HOST') ||
                                  (role == 'MODERATOR' &&
                                    !audioMicClose.includes(parti.socketId))
                                  ">
                                  <div class="col-8">
                                    <a @click="muteMic(parti.socketId)" href="#" style="color: #fff">Mute User</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.575" height="13.182"
                                      viewBox="0 0 17.575 13.182">
                                      <path id="Icon_awesome-volume-mute" data-name="Icon awesome-volume-mute"
                                        d="M7.381,4.742,4.327,7.8H.824A.824.824,0,0,0,0,8.619v4.943a.824.824,0,0,0,.824.824h3.5l3.054,3.053a.824.824,0,0,0,1.406-.583V5.325A.824.824,0,0,0,7.381,4.742Zm8.465,6.349,1.567-1.567a.554.554,0,0,0,0-.783l-.783-.783a.554.554,0,0,0-.783,0L14.279,9.524,12.713,7.957a.554.554,0,0,0-.783,0l-.783.783a.554.554,0,0,0,0,.783l1.567,1.567-1.566,1.566a.554.554,0,0,0,0,.783l.783.783a.554.554,0,0,0,.783,0l1.566-1.566,1.567,1.567a.554.554,0,0,0,.783,0l.783-.783a.554.554,0,0,0,0-.783Z"
                                        transform="translate(0 -4.5)" fill="#2d8cff" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-8">
                                    <a href="#" style="color: #fff" @click="
                                      renameUser(parti.userDetails, parti.socketId)
                                      ">Edit Name</a>
                                  </div>
                                  <div class="col-4 text-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.547" height="15.67"
                                      viewBox="0 0 15.547 15.67">
                                      <g id="Group_348" data-name="Group 348" transform="translate(0.5 0.624)">
                                        <path id="Path_491" data-name="Path 491"
                                          d="M9.507,6H4.446A1.446,1.446,0,0,0,3,7.446V17.567a1.446,1.446,0,0,0,1.446,1.446H14.567a1.446,1.446,0,0,0,1.446-1.446V12.507"
                                          transform="translate(-3 -4.467)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Path_492" data-name="Path 492"
                                          d="M19.591,3.267A1.534,1.534,0,1,1,21.76,5.436L14.892,12.3,12,13.027l.723-2.892Z"
                                          transform="translate(-7.662 -2.818)" fill="none" stroke="#2d8cff"
                                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end:: Form-->
              </div>
            </div>
            <div id="chat" class="tab-pane fade active show">
              <div class="align-items-center">
                <h5 class="card-title fw-bolder text-white top-title mt-2 fs-3">Chat</h5>
              </div>
              <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">
                <svg id="Icon_open-collapse-left" data-name="Icon open-collapse-left" xmlns="http://www.w3.org/2000/svg"
                  width="22.785" height="22.785" viewBox="0 0 22.785 22.785">
                  <path id="Icon_open-collapse-left-2" data-name="Icon open-collapse-left"
                    d="M22.785,0V22.785H19.937V0ZM5.7,0V22.785H0V0ZM8.544,5.7l5.7,5.7-5.7,5.7Z" fill="#2d8cff"></path>
                </svg>
              </a>

              <div id="participants1">
                <!-- begin:: Form-->
                <div class="mt-5">
                  <div class="input-group mb-3" style="width: 95%">
                    <input type="text" class="form-control" placeholder="Search..." v-model="searchMessage" />
                    <div class="input-group-append">
                      <span class="input-group-text p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                          <g id="Group_58" data-name="Group 58" transform="translate(-1520 -98)">
                            <rect id="Rectangle_125" data-name="Rectangle 125" width="32" height="32" rx="8"
                              transform="translate(1520 98)" fill="#2d8cff"></rect>
                            <g id="Icon_feather-search" data-name="Icon feather-search"
                              transform="translate(1529.495 107.645)">
                              <path id="Path_426" data-name="Path 426"
                                d="M15.622,10.061A5.561,5.561,0,1,1,10.061,4.5,5.561,5.561,0,0,1,15.622,10.061Z"
                                transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.5"></path>
                              <path id="Path_427" data-name="Path 427" d="M28,28l-3.024-3.024"
                                transform="translate(-15.486 -15.486)" fill="none" stroke="#fff" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="1.5"></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end:: Form-->

                <div style="
                    padding-right: 15px;
                    height: 65vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                  ">
                  <div v-for="(chat, i) in chatFilteredList" :key="i">
                    <div class="kt-chat__message" v-if="chat.socketId != socket.id">
                      <div class="kt-chat__user">
                        <span class="kt-media kt-media--circle kt-media--sm">
                          <img :src="BASE_URL + chat.profileImg" alt="image" />
                        </span>
                        From
                        <a href="#" class="kt-chat__username">{{ chat.user }}</a>
                        to <a href="#">Everyone</a>
                        <!-- <span class="kt-chat__datetime">2 Hours</span> -->
                      </div>
                      <div style="word-wrap: break-word; text-align: start" class="kt-chat__text kt-bg-light-brand"
                        v-html="chat.message"></div>
                    </div>
                    <div class="kt-chat__message kt-chat__message--right" v-else>
                      <div class="kt-chat__user">
                        <!-- <span class="kt-chat__datetime">30 Seconds</span> -->
                        From
                        <a href="#" class="kt-chat__username">{{ chat.user }}</a>
                        to <a href="#">Everyone</a>
                        <span class="kt-media kt-media--circle kt-media--sm">
                          <img :src="BASE_URL + chat.profileImg" alt="image" />
                        </span>
                      </div>
                      <div style="word-wrap: break-word; text-align: end" class="kt-chat__text kt-bg-light-success"
                        v-html="chat.message"></div>
                    </div>
                  </div>
                  <div class="kt-portlet__foot" style="position: fixed; bottom: 0; width: 94%">
                    <div class="kt-chat__input">
                      <div class="kt-chat__editor">
                        <textarea :disabled="is_disable" style="height: 50px" placeholder="Message to everyone"
                          spellcheck="false" v-model="chatMessage" @keydown="chatInputHandler"></textarea>
                        <span class="attachments">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12.01" height="12.299"
                            viewBox="0 0 12.01 12.299">
                            <path id="Path_554" data-name="Path 554"
                              d="M278.8,937.348a3.546,3.546,0,0,0-2.514,1.037l-5.756,5.757a.626.626,0,0,0-.139.2.619.619,0,0,0-.05.238.629.629,0,0,0,.045.239.614.614,0,0,0,1.014.193l5.756-5.756a2.324,2.324,0,1,1,3.287,3.286l-5.547,5.548A1.126,1.126,0,0,1,273.3,946.5l4.417-4.417a.612.612,0,0,0,.139-.2.62.62,0,0,0,.051-.238.621.621,0,0,0-.18-.442.615.615,0,0,0-.442-.18.618.618,0,0,0-.237.05.632.632,0,0,0-.2.139l-4.417,4.417a2.357,2.357,0,0,0,3.334,3.334l5.547-5.547a3.551,3.551,0,0,0-2.514-6.066Z"
                              transform="translate(-270.339 -937.349)" fill="#9ca3af"></path>
                          </svg>
                        </span>
                        <span class="emoji" id="emojiPicker">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12.318" height="12.316"
                            viewBox="0 0 12.318 12.316">
                            <path id="Path_555" data-name="Path 555"
                              d="M308.492,937.333a6.159,6.159,0,1,0,6.159,6.159A6.166,6.166,0,0,0,308.492,937.333Zm0,.924a5.235,5.235,0,1,1-5.235,5.235A5.228,5.228,0,0,1,308.492,938.257Zm-2.617,2.771a.459.459,0,0,0-.331.133.463.463,0,0,0-.1.151.47.47,0,0,0,0,.357.455.455,0,0,0,.1.151.446.446,0,0,0,.151.1.457.457,0,0,0,.179.033h1.231a.46.46,0,0,0,.179-.033.446.446,0,0,0,.151-.1.461.461,0,0,0,.1-.507.46.46,0,0,0-.1-.151.459.459,0,0,0-.331-.133Zm4,0a.459.459,0,0,0-.331.133.461.461,0,0,0-.1.151.462.462,0,0,0,.1.507.446.446,0,0,0,.151.1.46.46,0,0,0,.179.033h1.231a.457.457,0,0,0,.179-.033.446.446,0,0,0,.151-.1.456.456,0,0,0,.1-.151.47.47,0,0,0,0-.357.463.463,0,0,0-.1-.151.459.459,0,0,0-.331-.133Zm-4,2.464a.461.461,0,0,0-.462.462,3.083,3.083,0,1,0,6.158,0,.46.46,0,0,0-.462-.462Zm.554.924h4.126a2.075,2.075,0,0,1-4.126,0Z"
                              transform="translate(-302.333 -937.334)" fill="#9ca3af"></path>
                          </svg>
                        </span>
                        <div class="kt_chat__actions">
                          <button type="button" :disabled="chatMessage == ''" v-on:click="sendMessage()"
                            class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply">
                            <span v-if="is_message_loader" class="spinner spinner-white spinner-right"></span>
                            <svg v-if="is_message_loader == false" xmlns="http://www.w3.org/2000/svg" width="18.475"
                              height="18.473" viewBox="0 0 18.475 18.473">
                              <path id="Path_557" data-name="Path 557"
                                d="M291.668,984a.681.681,0,0,0-.314.089.693.693,0,0,0-.307.854l2.54,6.559a.695.695,0,0,0,.535.433l6.9,1.12c.172.028.155.078.155.182s.017.154-.155.182l-6.9,1.121a.694.694,0,0,0-.535.433l-2.54,6.56a.678.678,0,0,0-.028.406.665.665,0,0,0,.208.351.636.636,0,0,0,.37.176.68.68,0,0,0,.4-.065l17.09-8.544a.693.693,0,0,0,0-1.24L292,984.073A.687.687,0,0,0,291.668,984Z"
                                transform="translate(-291 -984)" fill="#fff"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" style="background-color: #000000bd" id="remove_users" tabindex="-1"
      data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    You have been removed from the webinar. Thank you for attending.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="RenamePopup" style="background: #00000091" tabindex="-1"
      data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="justify-content: center">
        <div class="modal-content" style="width: max-content; border: 1px solid">
          <div class="border-dotted">
            <div class="modal-body">
              <h2 class="text-white weight-700 text-center">Enter new name</h2>
              <div class="align-self-end text-right mt-5" style="display: flex; margin: auto">
                <input type="hidden" v-model="edit_socketId" />
                <input style="margin-right: 10px" @input="handleRenameInput" type="text" v-model="edit_name"
                  class="form-control bg-white text-black" placeholder="Enter new name" />
              </div>
              <div style="color: #ff8b00; font-weight: bold" v-if="edit_name_error">
                <em>{{ edit_name_error }}</em>
              </div>
              <div class="row mt-5 text-center align-items-center justify-content-center justify-content-center">
                <button v-on:click="(e) => renameSubmitUser(edit_socketId)" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" data-toggle="modal" :disabled="edit_join_btn"
                  data-target="#add-new-webinar-calendar">
                  Save
                  <span v-if="join_loader" class="spinner spinner-white spinner-right"></span>
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
                  @click="closeRenameModal()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="enterNameModal" style="background: #000000fc" tabindex="-1"
      data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="justify-content: center">
        <div class="modal-content" style="width: max-content; border: 1px solid">
          <div class="border-dotted">
            <div class="modal-body">
              <h2 class="text-white weight-700 text-center">Enter your name</h2>
              <div class="align-self-end text-right mt-5" style="display: flex; margin: auto">
                <input style="margin-right: 10px" @input="handlejoinInput" type="text" v-model="your_name"
                  class="form-control bg-white text-black" placeholder="Enter your name" />
              </div>
              <div style="color: #ff8b00; font-weight: bold" v-if="name_error">
                <em>{{ name_error }}</em>
              </div>
              <div class="row mt-5 text-center align-items-center justify-content-center justify-content-center">
                <button v-on:click="(e) => joinMeeting()" v-if="role != 'VIEWER'" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" data-toggle="modal" :disabled="join_btn"
                  data-target="#add-new-webinar-calendar">
                  Join Meeting
                  <span v-if="join_loader" class="spinner spinner-white spinner-right"></span>
                </button>
                <button v-on:click="(e) => joinAsViewer()" v-if="role == 'VIEWER'" type="button"
                  class="btn white-btn mr-3" style=" position: relative" data-toggle="modal" :disabled="join_btn"
                  data-target="#add-new-webinar-calendar">
                  Join Meeting (Viewer)
                  <span v-if="join_loader" class="spinner spinner-white spinner-right"></span>
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
                  @click="closeMeetingModal()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-xl" id="joinByViewerNameModal" style="background: #000000fc" tabindex="-1"
      data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="justify-content: center">
        <div class="modal-content" style="width: max-content; border: 1px solid">
          <div class="border-dotted">
            <div class="modal-body">
              <h2 class="text-white weight-700 text-center">Welcome to the room</h2>
              <h6 class="mt-3 text-white text-center">
                You are able to hear and see the hosts.
              </h6>

              <div class="row mt-4 text-center align-items-center justify-content-center justify-content-center">
                <button v-on:click="(e) => joinAsViewer()" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" data-toggle="modal" :disabled="join_btn"
                  data-target="#add-new-webinar-calendar">
                  Join Meeting
                  <span v-if="join_loader" class="spinner spinner-white spinner-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" style="background-color: #000000fc" id="invalidLink" tabindex="-1"
      data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Oops, Something's Wrong:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    Couldn't find the meeting you're trying to join. Please ensure you
                    have the correct link and try again
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="gotToLogin()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff">
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" style="background-color: #000000bd" id="not_found_video" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Can't find your camera</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    Check your system settings to make sure that a camera is available. If
                    not, plug one in. You might then need to restart your browse.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" data-bs-keyboard="false" data-bs-backdrop="static"
      style="background-color: #000000bd" id="reconnecting" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document" style="justify-content: center">
        <div class="modal-content" style="background: #fff; width: auto">
          <div class="border-dotted">
            <div class="modal-body" style="
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
              ">
              <h3 class="text-dark weight-700">
                <img height="100" width="100" src="../../assets/media/1488.gif" alt="image" />
              </h3>
              <h3 class="text-dark weight-700">You are a reconnecting...</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" data-bs-keyboard="false" data-bs-backdrop="static" id="TypeError"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">No camera and mic detected 2</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    Unable to detect a camera or mic. No one can see or hear you. please
                    try connecting a camera and microphone. then reload this call.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="closeTypeErrorModal()" type="button" class="btn white-btn" id="BtnTypeError"
              style="width: 150px; background: #00cd8b; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Try again
            </button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="errorModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Error:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    {{ running_error }}
                  </h4>
                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="DeniedModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade bd-example-modal-xl" id="stopLocalRecordingModal" tabindex="-1" role="dialog"
      data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" style="background: #000000b8;">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div style="justify-content: center;display: flex;
    align-items: center;">
                    <h3 style="margin: 0;" class="text-dark weight-700">Attention:</h3>
                    <h3 v-on:click="DismissModal('stopLocalRecordingModal')" class="text-dark weight-700 closeIconModal">X
                    </h3>

                  </div>
                  <hr />
                  <h4 class="text-dark weight-400 mt-3">
                    You are about to stop the recording and download the replay.
                  </h4>
                  <h4 class="text-dark weight-400 mt-4">
                    If this was in error, please click the "X" in the top right to continue recording.
                  </h4>

                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center align-items-center justify-content-center"
            style="margin-bottom: 15px;flex-direction: column;">
            <button v-on:click="isRecordLoader ? '' : stopCloudRecording()" type="button" id="BtnNotReadableError"
              class="btn white-btn" :class="isRecordLoader ? 'recordAnimation' : ''"
              style="background: #b60808; color: #fff;display: flex;padding: 5px 7px;border: 2px solid #0B111B;"
              :style="isRecordLoader ? 'background: #2d8cff' : ' background: #b60808'" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              <img alt="Logo" v-if="!isRecordLoader" src="../../assets/media/download-circular-button.png" height="25"
                width="25" />

              <span style="margin-left: 9px;">{{ isRecordLoader ? 'Download in progress...please wait until complete.' :
                'Stop Recording & Download' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="stopCloudRecordingModal" tabindex="-1" role="dialog"
      data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" style="background: #000000b8;">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div style="justify-content: center;display: flex;
    align-items: center;">
                    <h3 style="margin: 0;" class="text-dark weight-700">Attention:</h3>
                    <h3 v-on:click="DismissModal('stopCloudRecordingModal')" class="text-dark weight-700 closeIconModal">X
                    </h3>

                  </div>
                  <hr />
                  <h4 class="text-dark weight-400 mt-3">
                    You are about to stop the cloud recording.
                  </h4>
                  <h4 class="text-dark weight-400 mt-4">
                    After the video is done processing (Aprox 15 min) you will be able to download the file from your back
                    office.
                  </h4>
                  <h4 class="text-dark weight-400 mt-4">
                    If this was in error, please click the "X" in the top right to continue recording.
                  </h4>

                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center align-items-center justify-content-center"
            style="margin-bottom: 15px;flex-direction: column;">
            <button v-on:click="stopCloudRecording()" type="button" id="BtnNotReadableError" class="btn white-btn"
              style="background: #b60808; color: #fff;display: flex;padding: 5px 7px;border: 2px solid #0B111B;"
              data-toggle="modal" data-target="#add-new-webinar-calendar">
              <span>Stop Recording</span>

              <span v-if="isRecordLoader" class="mySpin"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="LeaveLocalRecordingModal" tabindex="-1" role="dialog"
      data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" style="background: #000000b8;">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div style="justify-content: center;display: flex;
    align-items: center;">
                    <h3 style="margin: 0;" class="text-dark weight-700">Attention:</h3>
                    <h3 v-on:click="isActionForLeave ? leaveWithStopLocalRec() : DismissModal('LeaveLocalRecordingModal')"
                      class="text-dark weight-700 closeIconModal">
                      X</h3>

                  </div>
                  <hr />
                  <h4 class="text-dark weight-400 mt-3">
                    You are about to stop the recording, download the replay and end the broadcast.
                  </h4>
                  <h4 class="text-dark weight-400 mt-4">
                    If this was in error, please click the "X" in the top right to continue recording.
                  </h4>

                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center align-items-center justify-content-center"
            style="margin-bottom: 15px;flex-direction: column;">
            <button v-on:click="isRecordLoader ? '' : leaveWithStopLocalRec()" type="button" id="BtnNotReadableError"
              class="btn white-btn" :class="isRecordLoader ? 'recordAnimation' : ''"
              style="background: #b60808; color: #fff;display: flex;padding: 5px 7px;border: 2px solid #0B111B;"
              :style="isRecordLoader ? 'background: #2d8cff' : ' background: #b60808'" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              <img alt="Logo" v-if="(!isRecordLoader || !isActionForLeave) && (isRecordLoader || !isActionForLeave)"
                src="../../assets/media/download-circular-button.png" height="25" width="25"
                style="    margin-right: 9px;" />

              <span>{{ isRecordLoader ? 'Download in progress...please wait until complete.' : isActionForLeave ? 'End Broadcast' : 'End Broadcast & Download Recording' }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl microphoneDeniedModal" id="microphoneDeniedModal" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    To join the room you need to give microphone access to the browser.
                    <h5 class="text-dark weight-700">1. Go to your device settings.</h5>
                    <h5 class="text-dark weight-700">
                      2. Allow access to the mic then refresh the page.
                    </h5>
                  </h4>
                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="DeniedModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="requestMicModal" style="background: #000000c2" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="width: max-content; border: 1px solid">
          <div class="border-dotted">
            <div class="modal-body">
              <h2 class="text-white weight-700 text-center">
                {{ requestUserName }} is requesting you to unmute your microphone
              </h2>

              <div class="row mt-5 text-center align-items-center justify-content-center justify-content-center">
                <button v-on:click="(e) => allowMicModal()" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" data-toggle="modal" data-target="#add-new-webinar-calendar">
                  Allow
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
                  @click="denyMicModal()">
                  Deny
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" id="requestCamModal" style="background: #000000c2" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="width: max-content; border: 1px solid">
          <div class="border-dotted">
            <div class="modal-body">
              <h2 class="text-white weight-700 text-center">
                {{ requestUserName }} is requesting you to unmute your camera
              </h2>

              <div class="row mt-5 text-center align-items-center justify-content-center justify-content-center">
                <button v-on:click="(e) => allowCamModal()" type="button" class="btn white-btn mr-3"
                  style="width: 150px; position: relative" data-toggle="modal" data-target="#add-new-webinar-calendar">
                  Allow
                </button>
                <button type="button" class="btn transparent-btn border-blue" style="width: 150px" data-dismiss="modal"
                  @click="denyCamModal()">
                  Deny
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl cameraDeniedModal" id="cameraDeniedModal" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    To join the room you need to give camera access to the browser.
                    <h5 class="text-dark weight-700">1. Go to your device settings.</h5>
                    <h5 class="text-dark weight-700">
                      2. Allow access to the camera then refresh the page.
                    </h5>
                  </h4>
                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="DeniedModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style="width: 150px; background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl cameraMicDeniedModal" id="cameraMicDeniedModal" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    To join the room you need to give camera and microphone access to the browser.
                    <h5 class="text-dark weight-700">1. Go to your device settings.</h5>
                    <h5 class="text-dark weight-700">
                      2. Allow access to the camera and microphone then refresh the page.
                    </h5>
                  </h4>
                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="DeniedModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style=" background: #b60808; color: #fff" data-toggle="modal" data-target="#add-new-webinar-calendar">
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl DeniedModal" data-bs-keyboard="false" data-bs-backdrop="static"
      id="DeniedModal" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document" style="margin-top: 0px;">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    We could not locate a camera or microphone on your computer. Please connect camera or microphone or
                    check if they are connected properly. Waiting for devices to be connected....
                  </h4>
                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="joinAsViewer()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
              style="background: #b60808; color: #fff" data-toggle="modal" data-target="#add-new-webinar-calendar">
              Join As Viewer Instead
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl DeniedModalCamera" id="DeniedModalCamera" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document" style="margin-top: 0px;">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Attention:</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    We could not locate a camera or microphone on your computer. Please connect camera or microphone or
                    check if they are connected properly. Waiting for devices to be connected....
                  </h4>

                  <hr style="margin-bottom: 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px">
            <button v-on:click="DismissModal('DeniedModalCamera')" type="button" id="BtnNotReadableError"
              class="btn white-btn mr-3" style="background: #b60808; color: #fff" data-toggle="modal"
              data-target="#add-new-webinar-calendar">
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-xl" data-bs-keyboard="false" data-bs-backdrop="static" id="NotReadableError"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content" style="background: #fff">
          <div class="border-dotted">
            <div class="modal-body">
              <div class="row" style="margin-top: 14px">
                <div class="col-md-12">
                  <h3 class="text-dark weight-700">Another app is using your camera</h3>
                  <hr />
                  <h4 class="text-dark weight-400">
                    We cannot access your camera. Close any apps (like Zoom or Teams) that
                    might be using your camera. then refresh the page.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 32px">
            <button v-on:click="closeNotReadableErrorModal()" type="button" id="BtnNotReadableError"
              class="btn white-btn mr-3" style="width: 150px; background: #b60808; color: #fff">
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("pageshow", function (event) {
  var historyTraversal = event.persisted,
    perf = window.performance,
    perfEntries = perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
    perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
    navigationType = perf && perf.navigation && perf.navigation.type;
  if (historyTraversal || perfEntryType === "back_forward" || navigationType === 2) {
    // Handle page restore.
    window.location.reload(true);
  }
});
window.addEventListener(
  "load",
  function () {
    // select parent of dish
  },
  false
);

var _ratios = ["4:3"];
var _aspect = 0;
var _margin = 5;
var _ratio = ratio();

// resize event of window
window.addEventListener("resize", function () {
  // resize event to dimension cameras
  resize();
});

// split aspect ratio (format n:n)

function resize() {
  dimensions();

  // loop (i recommend you optimize this)
  let max = 0;
  let i = 1;
  while (i < 5000) {
    var area = areas(i);
    if (area === false) {
      max = i - 1;
      break;
    }
    i++;
  }

  // remove margins
  max = max - _margin * 1;

  // set dimensions to all cameras
  resizer(max);
}

function resizer(width) {
  var _dish = document.getElementsByClassName("Dish")[0];

  if (_dish) {
    for (var s = 0; s < _dish.children.length; s++) {
      // camera fron dish (div without class)
      const element = _dish.children[s];
      const pseudoElementStyle = getComputedStyle(element, "display");

      // console.log('pseudoElementStyle___', pseudoElementStyle.display);
      if (pseudoElementStyle.display == "flex") {
        // custom margin
        element.style.margin = _margin + "px";

        // calculate dimensions
        element.style.width = width + "px";
        element.style.height = width * _ratio + "px";

        // to show the aspect ratio in demo (optional)
        element.setAttribute("data-aspect", _ratios[_aspect]);
      }
    }
  }
}

function ratio() {
  var ratio = _ratios[_aspect].split(":");
  return ratio[1] / ratio[0];
}

function dimensions() {
  var _dish = document.getElementsByClassName("Dish")[0];

  //   let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
  //   let _height = _dish.offsetHeight - _margin * 2;
  // }
}

function areas(increment) {
  var _dish = document.getElementsByClassName("Dish")[0];

  if (_dish) {
    let i = 0;
    let w = 0;
    let _width = _dish ? _dish.offsetWidth : 0 - _margin * 2;
    let _height = _dish ? _dish.offsetHeight : 0 - _margin * 2;
    let h = increment * _ratio + _margin * 2;
    while (i < _dish.children.length) {
      if (w + increment > _width - _margin * 2) {
        w = 0;
        h = h + increment * _ratio + _margin * 2;
      }
      w = w + increment + _margin * 2;
      i++;
    }
    if (h > _height - _margin * 2 || increment > _width - _margin * 2) return false;
    else return increment;
  }
}

import io from "socket.io-client";
import { Device, RtpCapabilities, detectDevice } from "mediasoup-client";
import { ref, reactive } from "vue";
import axios from "axios";
import { verifywebinars } from "../services/service";
import {
  liveWebinar,
  webinarParticipateUserList,
  getSingleWebinarData,
  listupdateParticipants,
  addToRecording,
  verifyOpenRoomWebinars,
} from "../services/service";
import { createPopup } from "@picmo/popup-picker";
axios.defaults.headers.post["Content-Type"] = "application/json";
import MultiStreamsMixer from "multistreamsmixer";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import StreamSaver from "streamsaver";
import { v4 as uuidv4 } from "uuid";
import fixWebmDuration from 'webm-duration-fix';
export default {
  data() {
    let urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("webinarid")) {
      const loc = window.location.pathname;
      console.log("starting loc value = " + loc);
      const locs =
        loc.lastIndexOf("/") == loc.length - 1
          ? loc.substring(0, loc.length - 1)
          : loc.substring(0, loc.lastIndexOf("/"));
      const companyname = locs.substring(locs.lastIndexOf("/") + 1);

      Vue.nextTick(() => {
        document.title =
          "UW - " + companyname + " " + window.location.pathname.split("/").pop();
      });
    } else {
      Vue.nextTick(() => {
        document.title = "UW - Unity Webinar";
      });
    }
    return {
      dragging: false,
      enabled: true,
      requestUserName: "",
      webinarId: "",
      OV: undefined,
      session: undefined,
      sessionScreen: undefined,
      screensharing: false,
      is_mic_allow: false,
      is_cam_allow: false,
      running_error: "",
      ism_w_c: "",
      audioDeviceData: false,
      name_error: "",
      edit_name_error: "",
      edit_join_btn: false,
      videoDeviceData: false,
      is_disable: false,
      publisherScreen: undefined,
      mainStreamManager: undefined,
      publisher: undefined,
      disabled: true,
      microphoneState: false,
      cameraState: "",
      childId: "",
      subscribers: [],
      stremErr: "",
      mySessionId: "SessionD",
      layoutClass: "single_user_main",
      deviceData: [],
      video: false,
      audio: false,
      join_loader: false,
      join_btn: false,
      edit_socketId: "",
      socketId: "",
      edit_name: "",
      your_name: "",
      profileImg: "default.jpg",
      is_stream: true,
      is_message_loader: false,
      getDevicesOV: "",
      searchMessage: "",
      goLiveStatus: "",
      role: "",
      filterdParticipants: [],
      chatHistory: [],
      chatFilteredUserList: [],
      selectedAudio: undefined,
      selectedOuterAudio: undefined,
      screenShareLoader: false,
      recordingToaster: false,
      isActionForLeave: false,
      startTimer: "",
      selectedVideo: undefined,
      users: {
        host: [{ name: "xyz", image: "imagepath" }],
        moderator: [],
        viewers: [],
        guest: [],
      },
      isRaiseHand: false,
      chatMessage: "",
      room_type: "",
      localStreamRef: "",
      loggedUserData: "",
      user_name: "",
      audioProducer: ref(),
      localVideoCont: "",
      micElem: ref(),
      videoElem: ref(),
      produceTransport: ref(),
      videoProducer: ref(),
      recordType: '',
      isRecordLoader: false,
      socket: null,
      BASE_URL:
        window.location.host == "unitywebinar.com" ? "https://api.unitywebinar.com/" : window.location.host == "demo.unitywebinar.com" ? "https://demoapi.unitywebinar.com/" : "https://dev-api.unitywebinar.com/",
      FRONT_BASE_URL: window.location.host == "unitywebinar.com" ? "https://unitywebinar.com/" : window.location.host == "demo.unitywebinar.com" ? "https://demo.unitywebinar.com/" : "https://dev.unitywebinar.com/",
      device: null,
      roomId: window.location.pathname.split("/").pop(),
      producerTransport: null,
      viewers: [],
      peersRef: ref([]),
      audioPeersRef: ref([]),
      globalTimeout: ref(),
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      guestUsers: [],
      peers: [],
      peerShow: [],
      consumers: ref(new Map()),
      receiveTransport: ref([]),
      audioMicClose: ref([]),
      audioElementSocket: ref([]),
      audioLevel: 5,
      audioElement: ref([]),
      remoteStreamsRef: ref({}),
      localScreenStreamRef: ref(),
      mainScreenStreamRef: ref(null),
      localScreenCont: ref(),
      localStream: null,
      videoProducerId2: ref(),
      videoProducerId: ref(),
      videoProducer2: ref(),
      audioProducer2: ref(),
      audioProducerId2: ref(),
      audioProducerId: ref(),
      mediasoupDevice: ref(),
      is_cam_request: false,
      recorderUsers: '',
      topRecording: false,
      currentVideoState: ref(false),
      screenShareStarted: ref(false),
      currentMicState: ref(false),
      mixer: ref(),
      recStream: ref(),
      mainCont: ref(),
      workerRef: ref(),
      audioContextRef: ref(),
      mediaRecorder: ref(),
      processingRef: ref(false),
      recordingRef: ref(),
      recordingToaster: ref(),
      recordingPauseElem: ref(),
      recordingResumeElem: ref(),
      recordingTextElem: ref(),
      dataProducer: ref(),
      dataProducerId: ref(),
      chunksRef: ref([]),
      sepChunks: ref([]),
      writer: ref(),
      savedChunks: ref([]),
      streamsArr: [],
      recordingIcon: ref(),
      isMobile: false,
      isMobileDevice: false,
      isBrave: false,
      dest: ref(),
      sourceNodes: ref(new Map()),
      hrs: "",
      title: "",
      min: "",
      sec: "",
      recordIds: ref([]),
      recordAudioProducer: ref(),
      recordVideoProducer: ref(),
    };
  },
  computed: {
    chatFilteredList() {
      if (this.searchMessage == "") {
        return this.chatHistory;
      } else {
        this.chatFilteredUserList = this.chatHistory.filter((name) => {
          return name.user.toLowerCase().includes(this.searchMessage.toLowerCase());
        });
        return this.chatFilteredUserList;
      }
    },
  },
  mounted: async function () {
    this.$watch("peersRef", () => {
      // alert('peers watch ')
      // if (!this.peers || this.peers.length === 0) return;

      Object.keys(this.remoteStreamsRef).forEach((key) => {
        console.log("this.remoteStreamsRef[key]------------", this.remoteStreamsRef[key]);

        if (!this.remoteStreamsRef[key].srcObject) {
          const source = this.peersRef.find(
            (peer) => peer?.storageId === key?.split("_")[0]
          )?.mediaStream;
          this.$refs.remoteStreamsRef[key].srcObject = source;
        }
      });

      resize();
    });

    window.onunload = () => {
      // writableStream.abort()
      // also possible to call abort on the writer you got from `getWriter()`
      this.writer.abort();
    };

    let urlParams = new URLSearchParams(window.location.search);
    this.webinarId = urlParams.get("webinarid");
    this.mySessionId = urlParams.get("webinarid");
    let email = urlParams.get("email");
    let content = urlParams.get("content");
    this.childId = urlParams.get("childId");
    let body = {
      webinarId: this.webinarId,
      childId: this.childId ?? 0,
    };
    if (urlParams.has("webinarid")) {
      if (urlParams.has("content")) {
        body = {
          webinarId: this.webinarId,
          email: email ?? undefined,
          content: content ?? undefined,
          childId: this.childId ?? undefined,
        };
      } else {
        if (localStorage.getItem("isAuthenticated") == null) {
          this.$router.push("/login");
        }
      }
      this.room_type = "JOIN_ROOM";
      body = await verifywebinars(body);

      var b_code = body.code ? body.code : "NORMAL";
      console.log("body__________", body);
      if (b_code == "ERR_BAD_REQUEST") {
        swal({
          title: "Oops!",
          text: body.response.data.message,
          icon: "error",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.$router.push("/login");
          } else {
          }
        });
      } else {
        console.log("body.data__________", body.data);

        if (body.data.code == 400) {
          swal({
            title: "Oops!",
            text: "You are not invited to webinar.",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            buttons: false,
          }).then((result) => { });
        } else {
          if (body.status ?? 0 == 200) {
            var webinarParticipate = await webinarParticipateUserList(this.webinarId);
            const SingleWebinarData = await getSingleWebinarData(this.webinarId);
            this.title = SingleWebinarData.data.result.title;

            console.log("SingleWebinarData-----------", SingleWebinarData.data.result.title);
            console.log("webinarParticipate-----------", webinarParticipate);

            if (webinarParticipate.data.code == 200) {
              // this.filterdParticipants = webinarParticipate.data.result.userId.filter(a => a.webinarUserType == 'Host');
              // this.participants = webinarParticipate.data.result.userId
            }
            if (body.data.result.live == true) {
              this.goLiveStatus = false;
            } else {
              this.goLiveStatus = false;
            }
            this.role = body.data.result.userRole;
            if (body.data.result.userRole == "Host") {
              this.role = "HOST";
            } else if (body.data.result.userRole == "Moderator") {
              this.role = "MODERATOR";
            } else if (body.data.result.userRole == "Guest") {
              this.role = "GUEST";
            } else if (body.data.result.userRole == "Viewer") {
              this.is_disable = true;

              this.role = "VIEWER";

              $("#joinByViewerNameModal").modal("show");
            } else {
              // this.is_disable = true;
              this.role = "GUEST";
            }
            this.loggedUserData = {
              ...body.data.result.userData,
              profileImg: body.data.result.userData.profileImg
                ? body.data.result.userData.profileImg
                : "default.jpg",
            };
            this.user_name =
              body.data.result.userData.firstName +
              " " +
              body.data.result.userData.lastName;

            // this.profileImg = userdetailsData.profileImg;
            console.log("this.FFa-----------", this.loggedUserData);
            // this.startWebinar(this.loggedUserData);
            // this.startWebinar(this.loggedUserData);

            if (this.role != "VIEWER") {
              this.joinMeeting();
            }

            // if (
            //   this.role == "GUEST" &&
            //   (body.data.result.live ?? false) != true
            // ) {
            //   window.location = `/wait?${urlParams.toString()}`;
            // } else {

            // }
          }
        }
      }
    } else {
      const loc = window.location.pathname;
      console.log("starting loc value = " + loc);
      const locs =
        loc.lastIndexOf("/") == loc.length - 1
          ? loc.substring(0, loc.length - 1)
          : loc.substring(0, loc.lastIndexOf("/"));
      const companyname = locs.substring(locs.lastIndexOf("/") + 1);

      let urlParams = new URLSearchParams(window.location.search);
      var payLoad = {
        companyName: companyname,
        userId: localStorage.getItem("userdetails")
          ? JSON.parse(localStorage.getItem("userdetails"))._id
          : "users_",
        roomName: window.location.pathname.split("/").pop(),
      };
      const verifyOpenRoom = await verifyOpenRoomWebinars(payLoad);

      if (!verifyOpenRoom.data.result) {
        $("#invalidLink").modal("show");
      } else {
        this.room_type = "OPEN_ROOM";

        this.webinarId = verifyOpenRoom.data.result.webinar_data._id;
        // this.filterdParticipants = verifyOpenRoom.data.result.participants.data.userId.filter(a => a.webinarUserType == 'Host');
        // this.participants = verifyOpenRoom.data.result.participants.data.userId
        if (!localStorage.getItem("userdetails")) {
          $("#enterNameModal").modal("show");
          this.role = "GUEST";
        } else {
          if (verifyOpenRoom.data.result.userData != null) {
            if (verifyOpenRoom.data.result.userData.webinarUserType == "Host") {
              this.role = "HOST";
            } else if (
              verifyOpenRoom.data.result.userData.webinarUserType == "Moderator"
            ) {
              this.role = "MODERATOR";
            } else if (verifyOpenRoom.data.result.userData.webinarUserType == "Viewer") {
              this.is_disable = true;
              this.role = "VIEWER";
              $("#joinByViewerNameModal").modal("show");
            } else {
              this.role = "GUEST";
            }
          } else {
            this.role = "GUEST";
          }
          var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
          if (userdetailsData.profileImg) {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: userdetailsData.profileImg,
            };
            this.profileImg = userdetailsData.profileImg;
            // this.loggedUserData = { ...userdetailsData, profileImg: this.BASE_URL + userdetailsData.profileImg };
          } else {
            this.loggedUserData = {
              ...userdetailsData,
              profileImg: "default.jpg",
            };
            this.profileImg = "default.jpg";
          }
          this.user_name = userdetailsData.firstName + " " + userdetailsData.lastName;

          if (this.role != "VIEWER") {
            this.joinMeeting();
          }
          console.log("this.loggedUserData-----------", this.loggedUserData);
        }
      }
      // if (!localStorage.getItem("userdetails")) {

      // } else {
      //   var userdetailsData = JSON.parse(localStorage.getItem("userdetails"));
      //   if (userdetailsData.profileImg) {
      //     this.loggedUserData = { ...userdetailsData, profileImg: userdetailsData.profileImg };
      //     this.profileImg = userdetailsData.profileImg;
      //     // this.loggedUserData = { ...userdetailsData, profileImg: this.BASE_URL + userdetailsData.profileImg };
      //   } else {
      //     this.loggedUserData = { ...userdetailsData, profileImg: 'default.jpg' };
      //   }

      //   this.user_name = userdetailsData.firstName + ' ' + userdetailsData.lastName;
      //   console.log('this.loggedUserData-----------', this.loggedUserData)
      //   this.startWebinar(this.loggedUserData);
      // }
    }
  },
  async created() {
    window.addEventListener("beforeunload", function (e) {
      let confirmationMessage = "If you leave before saving, your changes will be lost.";

      if (this.recordingToaster) {
        if (this.recordType == 'Local') {
          $("#LeaveLocalRecordingModal").modal("show");

        } else {
          this.stopCloudRecording();
        }
      } else {
        // window.close();
        // window.location.href = window.origin;
      }

      console.log('confirmationMessage-----------------')

      // (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });


    if (!navigator.mediaDevices?.enumerateDevices) {
    } else {
      // List cameras and microphones.
      await navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.deviceData = devices;
        })
        .catch((error) => {
          if (error.name == "TypeError") {
            $("#TypeError").modal("show");
          }
          if (error.name == "NotReadableError") {
            $("#NotReadableError").modal("show");
            // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
          }
        });
    }


    navigator.mediaDevices.ondevicechange = async (e) => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log('e-----', e)
      console.log('devices-----', devices)
      this.deviceData = devices;

      console.log('--------------', devices.some((device) => device.kind === "videoinput"))
      if (!devices.some((device) => device.kind === "videoinput")) {
        if (this.currentVideoState) {
          this.handleVideo();
          this.is_cam_allow = false;
        }
      } else {
        $("#DeniedModalCamera").modal("hide");

      }
      if (this.stremErr == "NotFoundError") {
        if (this.socketId == "") {
          if (devices.some((device) => device.kind === "videoinput") && devices.some((device) => device.kind === "audioinput")) {
            this.joinMeeting()
          }
        }
      }
    }

    let videoInput = this.deviceData.filter((device) => device.kind === "videoinput");
    if (videoInput.length > 0) {
      // this.selectedVideo = videoInput[0].deviceId;
    }
    let audioInput = this.deviceData.filter((device) => device.kind === "audioinput");
    if (audioInput.length > 0) {

      // this.selectedAudio = audioInput[0].deviceId;
    }
    let audioOutput = this.deviceData.filter((device) => device.kind === "audiooutput");
    if (audioOutput.length > 0) {
      const audioOutputDe = this.deviceData.filter(
        (device) =>
          (device.kind === "audiooutput" && device.deviceId == "default") ||
          device.kind === "audiooutput" ||
          device.deviceId == "default"
      );
      this.selectedOuterAudio = audioOutput[0].deviceId;
    }

    console.log("1--------", audioInput);
    console.log("2--------", audioOutput);
    console.log("3--------", this.deviceData);

    // try {
    //   const stream = await navigator.mediaDevices
    //     .getUserMedia({
    //       audio: {
    //         echoCancellation: true,
    //         noiseSuppression: true,
    //         sampleRate: 44100,
    //       },
    //       video: true,
    //     });
    // } catch (error) {

    //   if (error.name == "TypeError") {
    //     $("#TypeError").modal("show");
    //   }

    //   if (error.name == "AbortError") {
    //     $("#NotReadableError").modal("show");
    //     console.log("this.publisher=============", this.publisher);
    //     // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
    //   }
    //   if (error.name == "NotReadableError") {
    //     $("#NotReadableError").modal("show");
    //     console.log("this.publisher=============", this.publisher);
    //     // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
    //   }
    //   this.join_btn = false;
    //   console.log("error 3 .name", error.name + ": " + error.message);
    // }

    setInterval(async () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
      } else {
        // List cameras and microphones.
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            // console.log("devices.",devices);
            this.deviceData = devices;
          })
          .catch((error) => {
            console.log("error.name", error.name + ": " + error.message);
            if (error.name == "TypeError") {
              $("#TypeError").modal("show");
            }
            if (error.name == "NotReadableError") {
              $("#NotReadableError").modal("show");
              // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
            }
          });
      }
    }, 1000);

    const interv = setInterval(() => {
      this.handleVoiceSort();
      // this.getCurrStats()
    }, 700);
    return () => {
      clearInterval(interv);
    };
  },

  methods: {
    videresize() { },
    expandNew: function expandNew() {
      resize();
    },
    handleVoiceSort() {
      if (!this.audioPeersRef) return;

      let tempArr = [];
      this.audioPeersRef.forEach((item) => {
        if (
          item.consumer?.rtpReceiver?.getSynchronizationSources()[0]?.audioLevel >= 0.01
        ) {
          if (!tempArr.includes(item?.socketId)) {
            tempArr.push(item?.socketId);
          }
          document?.getElementById(item?.socketId)?.childNodes?.forEach((item) => {
            if (!item.classList.contains("animate-flicker")) {
              item.classList.add("animate-flicker");
            }
          });
          if (document.getElementById(item?.socketId + "peer-name-tag")) {
            document
              .getElementById(item?.socketId + "peer-name-tag")
              .classList.add("name-tag-style");
          }
          if (document.getElementById(item?.socketId + "name-tag")) {
            document
              .getElementById(item?.socketId + "name-tag")
              .classList.add("name-tag-style");
          }
        } else {
          document?.getElementById(item?.socketId)?.childNodes?.forEach((item) => {
            if (item.classList.contains("animate-flicker")) {
              item.classList.remove("animate-flicker");
            }
          });

          if (document.getElementById(item?.socketId + "peer-name-tag")) {
            document
              .getElementById(item?.socketId + "peer-name-tag")
              .classList.remove("name-tag-style");
          }
          if (document.getElementById(item?.socketId + "name-tag")) {
            document
              .getElementById(item?.socketId + "name-tag")
              .classList.remove("name-tag-style");
          }
        }
      });
    },
    async handlejoinInput() {
      this.user_name = this.your_name;
      if (this.your_name == "") {
        this.name_error = "Please enter your name.";
        this.join_btn = true;
      } else {
        this.name_error = "";
        this.join_btn = false;
      }
    },
    async handleRenameInput() {
      this.editedName = this.edit_name;
      if (this.edit_name == "") {
        this.edit_name_error = "Please enter new name.";
        this.edit_join_btn = true;
      } else {
        this.edit_name_error = "";
        this.edit_join_btn = false;
      }
    },
    async joinMeeting() {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      if (this.user_name == "") {
        this.name_error = "Please enter your name.";
        this.join_btn = true;
      } else {
        if (!localStorage.getItem("userdetails")) {
          this.loggedUserData = {
            firstName: this.user_name,
            lastName: "",
            profileImg: "default.jpg",
          };
        }
        if (this.role != "VIEWER") {
          this.name_error = "";
          this.join_btn = false;
          this.join_btn = true;
          this.join_loader = true;
          var now = Date.now();
          this.is_stream = true;

          try {

            const stream = await navigator.mediaDevices.getUserMedia({
              audio: {
                echoCancellation: true,
                noiseSuppression: true,
              },
              video: true
            });



            const audioTracks = stream.getAudioTracks();
            if (audioTracks.length > 0) {
              const audioDeviceId = audioTracks[0].getSettings().deviceId;
              this.selectedAudio = audioDeviceId;
              console.log("Audio device ID:", audioDeviceId);
            } else {
              console.log("No video tracks found in the stream.");
            }

            const videoTracks = stream.getVideoTracks();
            if (videoTracks.length > 0) {
              const videoDeviceId = videoTracks[0].getSettings().deviceId;
              this.selectedVideo = videoDeviceId;
              console.log("Video device ID:", videoDeviceId);
            } else {
              console.log("No video tracks found in the stream.");
            }
            $("#DeniedModal").modal("hide");
            if (this.socketId == "") {
              await this.startWebinar(this.loggedUserData);
            }
          } catch (error) {
            console.log(error.name, "-------------");
            console.log(error.message, "-------------");
            if (error.name == "NotFoundError") {
              // this.running_error = error;
              this.is_cam_allow = false;
              this.stremErr = "NotFoundError";
              $("#DeniedModal").modal("show");
            } else if (error.name == "NotAllowedError") {
              $("#cameraMicDeniedModal").modal("show");
            } else if (error.name == "NotReadableError") {
              this.is_cam_allow = false;
              this.is_cam_allow = false;
              $("#NotReadableError").modal("show");
            } else {
              this.is_cam_allow = false;

              $("#errorModal").modal("show");
              this.running_error = error;
            }
            this.join_btn = false;
            this.join_loader = false;
            this.is_mic_allow = false;
          }
          let userAgentString = navigator.userAgent;
          let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
          if (!firefoxAgent) {
            setInterval(async () => {
              // console.log('connected-----', this.socket.connected);

              navigator.permissions
                .query({ name: "microphone" })
                .then((permissionObj) => {
                  if (permissionObj.state == "denied") {
                    this.is_mic_allow = false;
                    if ($(".DeniedModal").hasClass("show")) {
                    } else {

                      this.is_cam_allow = false;
                      this.is_mic_allow = false;
                      // $("#DeniedModal").modal("show");
                    }
                  } else if (permissionObj.state == "granted") {
                    this.is_mic_allow = true;
                    // $("#DeniedModal").modal("hide");
                  } else if (permissionObj.state == "prompt") {
                    this.is_mic_allow = false;
                    // $("#microphoneDeniedModal").modal("show");
                  } else {
                    this.is_mic_allow = false;
                  }
                  permissionObj.onchange = (e) => {
                    if (e.type === "change") {
                      const newState = e.target.state;

                      if (newState == "denied") {
                        this.is_mic_allow = false;
                        if ($(".DeniedModal").hasClass("show")) {
                        } else {
                          this.is_cam_allow = false;
                          this.is_mic_allow = false;
                          // $("#DeniedModal").modal("show");
                        }
                      } else if (newState == "granted") {
                        this.is_mic_allow = true;
                        // $("#microphoneDeniedModal").modal("hide");
                      } else if (newState == "prompt") {
                        this.is_mic_allow = false;
                        // $("#microphoneDeniedModal").modal("show");
                      } else {
                      }
                    }
                  };

                })
                .catch((error) => {
                  console.log("this.is_mic_allow :", this.is_mic_allow);
                  console.log("Got error :", error);
                });

              navigator.permissions
                .query({ name: "camera" })
                .then((permissionObj) => {
                  if (permissionObj.state == "denied") {
                    this.is_cam_allow = false;
                    if ($(".DeniedModal").hasClass("show")) {
                    } else {

                      this.is_cam_allow = false;
                      // $("#DeniedModal").modal("show");
                    }
                  } else if (permissionObj.state == "granted") {
                    // this.is_cam_allow = true;
                    if (this.deviceData.some((device) => device.kind === "videoinput")) {
                      this.is_cam_allow = true;
                    }
                    // $("#DeniedModal").modal("hide");
                  } else if (permissionObj.state == "prompt") {
                    this.is_cam_allow = false;
                    // $("#microphoneDeniedModal").modal("show");
                  } else {
                    this.is_cam_allow = false;
                  }
                  permissionObj.onchange = (e) => {
                    if (e.type === "change") {
                      const newState = e.target.state;

                      console.log("newState :", newState);

                      if (newState == "denied") {
                        this.is_cam_allow = false;
                        if ($(".DeniedModal").hasClass("show")) {
                        } else {
                          this.is_cam_allow = false;
                          this.is_cam_allow = false;
                          // $("#DeniedModal").modal("show");
                        }
                      } else if (newState == "granted") {
                        this.is_cam_allow = true;
                        // $("#microphoneDeniedModal").modal("hide");
                      } else if (newState == "prompt") {
                        this.is_cam_allow = false;
                        // $("#microphoneDeniedModal").modal("show");
                      } else {
                      }
                    }
                  };


                })
                .catch((error) => {
                  console.log("this.is_cam_allow :", this.is_cam_allow);
                  console.log("Got error :", error);
                });

              if (this.is_mic_allow && this.is_cam_allow) {
                $("#cameraMicDeniedModal").modal("hide");
              }
            }, 3000);
          }
        } else {
          await this.startWebinar(this.loggedUserData);
        }
      }
    },
    expand: function expand(ev) {
      let myScreen = document.getElementById("Screen");
      let screens = document.getElementsByClassName("Screen")[0];
      let _conference = document.getElementsByClassName("Conference")[0];

      if ($("#Screen").css("display") == "none") {
        document.getElementById("Screen").style.display = "flex";
      } else {
        document.getElementById("Screen").style.display = "none";
      }
      resize();
    },
    async startWebinar(userdetailsData) {
      // alert('Let\'s start');

      if (this.socketId == "") {
        const loc = window.location.pathname;
        console.log("starting loc value = " + loc);
        const locs =
          loc.lastIndexOf("/") == loc.length - 1
            ? loc.substring(0, loc.length - 1)
            : loc.substring(0, loc.lastIndexOf("/"));
        const companyname = locs.substring(locs.lastIndexOf("/") + 1);

        let urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("webinarid")) {
          this.roomId = this.webinarId;
        } else {
          this.roomId = companyname + window.location.pathname.split("/").pop();
        }
        console.log("companyname = " + companyname);

        console.log("Webinar name = " + window.location.pathname.split("/").pop());

        console.log("this.loggedUserData________", this.loggedUserData);
        console.log("this.roomId-----------------------", this.roomId);

        // console.log('LocalStream_storage-', document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV"));
        // if (document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV')) {
        //   document.body.removeChild(document.getElementById('LocalStream_storageId_STREAsM_VIDEO_DIV'))

        //   const peerSData = this.peerShow.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
        //   const peerData = this.peers.filter((peerData) => (peerData?.storageId !== 'LocalStream_storageId_STREAsM_VIDEO_DIV'))
        //   // this.peerShow = peerData;
        //   this.peers = peerData;
        //   this.peerShow = peerSData;
        // }

        this.socket = io(this.BASE_URL, { autoConnect: true });
        this.socket.on("connect", async () => {
          if (
            document.getElementById("LocalStream_storageId_STREAsM_VIDEO_DIV") != null
          ) {
            const peerSData = this.peerShow.filter(
              (peerData) => peerData?.storageId !== "LocalStream_storageId"
            );
            const peerData = this.peers.filter(
              (peerData) => peerData?.storageId !== "LocalStream_storageId"
            );
            // this.peerShow = peerData;
            this.peers = peerData;
            this.peerShow = peerSData;
          }
          this.socket.emit("getRTPCapabilites", async (response) => {
            // alert('socketId ', this.socketId);
            console.log("response-------------0", response);
            this.handleCapabilities(response?.capabilities);
            this.socketId = this.socket.id;
            this.loggedUserData = {
              ...userdetailsData,
              socketId: this.socket.id,
            };

            this.user_name = userdetailsData.firstName + " " + userdetailsData.lastName;
            // this.profileImg = userdetailsData.profileImg;
            const obj = {
              room: this.roomId,
              webinarUserType: this.role,
              user_name: userdetailsData.firstName + " " + userdetailsData.lastName,
              profileImg: this.profileImg,
            };
            console.log("obj________", obj);
            this.socket.emit("addUserCall", obj);
            if (this.role != "VIEWER") {
              // alert("Hi", this.role);
              await this.startStream();

              await this.startProducing();
            } else {
              // alert("By", this.role);
              this.viewers.push({
                socketId: this.socket.id,
                room: this.roomId,
                userDetails: {
                  user_name: this.user_name,
                  profileImg: this.profileImg,
                  webinarUserType: this.role,
                },
              });

              const objV = {
                room: this.roomId,
                webinarUserType: this.role,
                userDetails: {
                  user_name: userdetailsData.firstName + " " + userdetailsData.lastName,
                  profileImg: userdetailsData.profileImg,
                },
              };

              this.is_stream = false;
              this.socket.emit("addUserInViewerCall", objV);
            }
            console.log("-----------------");
            console.log(this.role);
            setInterval(async () => {
              // console.log('connected-----', this.socket.connected);

              if (this.socket.connected == false) {
                $("#reconnecting").modal("show");
                setInterval(async () => {
                  window.location.reload(true);
                }, 3000);
                // this.startWebinar(this.loggedUserData)
              } else {
                $("#reconnecting").modal("hide");
              }
            }, 3000);
          });
        });

        this.socket.on("stopRecordingNotify", (data) => {
          console.log('data-------', data);

          if (data.recordingUsers.length == 0) {
            this.topRecording = false;
          } else {
            this.topRecording = true;

          }
          this.recordType = '';

          let names = data.recordingUsers.map(x => x.username).join(", ");
          this.recorderUsers = names;
        });

        this.socket.on("recordingNotify", (data) => {

          if (data.recordingUsers.length != 0) {
            this.topRecording = true;
            let names = data.recordingUsers.map(x => x.username).join(", ");
            console.log('data-------', data);
            console.log('data-------', data.data.recordType);
            console.log('recordType-------', data.data.recordType ? 'If' : 'Else');

            if (data.data.recordType != undefined) {
              this.recordType = data.data.recordType;

            }
            this.recorderUsers = names;
            console.log('names-------', names);
          }
        });
        this.socket.on("userLeft", (socketId) => {
          console.log("userLeft--------", socketId);
          console.log("userLeft------PF--", this.peersRef);
          console.log("userLeft------P--", this.peers);
          console.log("userLeft------PS--", this.peerShow);

          const peeridx = this.peers.findIndex((peer) => peer?.socketId === socketId);
          if (peeridx !== -1) {
            const peersFilter = this.peers.filter((peer) => peer?.socketId !== socketId);
            this.peersRef = peersFilter;
            this.peers = peersFilter;
            this.peerShow = peersFilter;
            console.log("userLeft------PR--", peersFilter);
            if (this.recordingRef) {
              this.resetVideoRec();
            }
          }
          const peersPFilter = this.filterdParticipants.filter(
            (peer) => peer?.socketId !== socketId
          );
          this.filterdParticipants = peersPFilter;
        });

        this.socket.on("disconnect", () => {
          this.socketId = this.socket.id;
          console.log("disconnect--", this.socket.id); // undefined
        });

        this.socket.on("finalCloudFile", async (id) => {
          console.log("finalCloudFile--", id); // undefined
          var today = new Date();

          const hrs = this.hrs == 0 ? '' : this.hrs + ' Hrs ';
          const min = this.min == 0 ? '' : this.min + ' Min ';
          const sec = this.sec == 0 ? '' : this.sec + ' Sec ';
          this.isRecordLoader = false;
          this.recordType = '';

          var payLoad = {
            title: this.room_type == "JOIN_ROOM" ? 'Unity Webinar - ' + this.title + ' Recording' : 'Unity Webinar - Open Room Recording',
            date: this.date.toLocaleDateString("en-US") + ' ' + this.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            webinarId: this.roomId,
            recordingType: this.room_type,
            duration: hrs + min + sec,
            downloadLink: id,
          };

          const addToRecordings = await addToRecording(payLoad);
          console.log('payLoad---------', payLoad)
          console.log('addToRecordings---------', addToRecordings)

          if (addToRecordings.data.code == 200) {
            if (this.isActionForLeave) {
              this.isActionForLeave = false;
              window.close();
              window.location.href = window.origin;
            } else {
              $("#stopCloudRecordingModal").modal("hide");
            }
          }
        });

        this.socket.on("finalFile", (id) => {
          // this.downloadRecordFile(id)
          const a = document.createElement("a");
          var aRE = document.getElementById('RECORD' + id)

          console.log("a--1----", aRE); // undefined
          a.id = 'RECORD' + id;
          // a.hidden = true;
          a.href = this.BASE_URL + "api/users/v1/webinar/file/" + id;
          a.download = true;
          // a.target = '_blank';
          a.click();

          document.body.appendChild(a);
          this.isRecordLoader = false;
          $("#stopRecordingModal").modal("hide");
          var wrap = document.getElementById('RECORD' + id)
          console.log("this.isActionForLeave--", this.isActionForLeave); // undefined
          console.log("finalFile--", id); // undefined


          // if (this.isActionForLeave) {

          //   $.get(this.BASE_URL + "api/users/v1/webinar/file/" + id, function (response, status, xhr) {
          //     console.log("status--", status);

          //     if (xhr.getResponseHeader("content-type").indexOf("mp4") > -1) {
          //       // Text based stuff.
          //       console.log("Text based stuff--");
          //     } else {
          //       if (status == 'success') {
          //         console.log("Download based stuff--");
          //         window.close();
          //         window.location.href = window.origin;
          //       }


          //     }
          //   });

          // }
          this.recordType = ''

          $("#stopLocalRecordingModal").modal("hide");
        });

        this.socket.on("closeCamera", async ({ socketId, status }) => {
          console.log("closeCamera--", { socketId, status }); // undefined

          if (status == true) {
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
          } else {
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
          }
          if (status) {
            if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
              document.getElementById(socketId + "_PEER_IMAGE").style.display = "flex";
            }
          } else {
            if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
              document.getElementById(socketId + "_PEER_IMAGE").style.display = "none";
            }
          }
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              !this.mainScreenStreamRef.screenShare
            ) {
              this.mainScreenStreamRef.mutedVideo = status;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].mutedVideo = status;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].mutedVideo = status;
            }
          }
          if (this.peersRef.length > 0) {
            var objIndex = this.peersRef.findIndex(
              (user) => user?.socketId == socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peersRef[objIndex].mutedVideo = status;
            }
          }
        });

        this.socket.on("closeConsumer", async (storageId) => {
          console.log("close consumer", storageId);
          console.log("this.mainScreenStreamRef:  ", this.mainScreenStreamRef);
          await this.receiveTransport[storageId]?.close();
          this.receiveTransport[storageId] = null;
          this.consumers.delete(storageId);
          if (this.recordingRef) {
            this.resetVideoRec()
          }

          console.log("this.peers  cc ", this.peers);
          console.log("this.peerShow  cc ", this.peerShow);
          const idx = this.peers?.findIndex(
            (item) => item?.storageId === storageId && item?.screenShare === false
          );
          if (idx !== -1) {
            console.log("If 1 ");
            if (this.mainScreenStreamRef) {
              console.log("If 2 ");
              if (this.mainScreenStreamRef.screenShare == false) {
                if (this.mainScreenStreamRef.storageId == storageId) {
                  this.mainScreenStreamRef.mediaStream = null;
                  // this.mainScreenStreamRef.storageId = storageId;
                  this.mainScreenStreamRef.mutedVideo = true;
                  // this.updateIsShow(this.mainScreenStreamRef.storageId, true)
                }
              }
            }

            if (this.peers[idx]?.screenShare === false) {
              this.peers[idx].mediaStream = null;
              this.peers[idx].mutedVideo = true;
            } else {
              console.log("cc ELSE ");

              // this.peers?.splice(idx, 1)
            }
            const idx1 = this.peerShow?.findIndex(
              (item) => item?.storageId === storageId && item?.screenShare === false
            );
            if (this.peerShow[idx1]?.screenShare === false) {
              this.peerShow[idx1].mediaStream = null;
              this.peerShow[idx1].mutedVideo = true;
            } else {
              console.log("cc ELSE 33");

              // this.peerShow?.splice(idx, 1)
            }
          } else {
            console.log("DISPLAY NONE HTI");
            if (document.getElementById(storageId)) {
              document.body.removeChild(document.getElementById(storageId));

              if (this.recordingRef) {
                const item = this.sourceNodes.get(storageId);
                if (item) {
                  item?.node?.disconnect();
                  item?.mediaStream?.getTracks()?.forEach((track) => {
                    track?.stop();
                  });
                  this.sourceNodes.delete(storageId);
                }
              }
            }
            // if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
            //   document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
            // }
            if (this.peersRef?.some((item) => item?.storageId === storageId)) {
              const peerFilter = this.peersRef?.filter(
                (peer) => peer?.storageId !== storageId
              );
              this.peersRef = peerFilter;
              // setPeers(peerFilter)
            }

            const peerSData = this.peerShow.filter(
              (peerData) => peerData?.storageId !== storageId
            );
            const peerData = this.peers.filter(
              (peerData) => peerData?.storageId !== storageId
            );
            const guestUsersData = this.filterdParticipants.filter(
              (peerData) => peerData?.storageId !== storageId
            );
            // this.peerShow = peerData;
            this.peers = peerData;
            this.peerShow = peerSData;
            this.filterdParticipants = guestUsersData;

            console.log(peerSData);
            console.log(peerData);

            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.storageId == storageId) {
                console.log("S 1 ");
                this.mainScreenStreamRef = null;
                document.getElementById("Screen").style.display = "none";
              } else {
                if (
                  document.getElementById(
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  )
                ) {
                  console.log("ELSE");
                  // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
                  this.updateIsShow(this.mainScreenStreamRef.storageId, false);
                }
              }
            }

            const audioElementData = this.audioElement.filter(
              (audioData) => audioData !== storageId
            );
            this.audioElement = audioElementData;

            const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
            this.peerShow = peerFilter;
            //console.log("this.peers______", this.peers);
            console.log("this.peerShow______", this.peerShow);
            this.manageDiv();
          }
        });

        this.socket.on("speaking", (value) => {
          if (value) {
            if (this.globalTimeout) {
              clearTimeout(this.globalTimeout);
            }

            const myAudioIcon = document?.getElementById(this.socket.id)?.childNodes;
            myAudioIcon?.forEach((item) => {
              if (!item.classList.contains("animate-flicker")) {
                item.classList.add("animate-flicker");

                if (document.getElementById(this.socket.id + "peer-name-tag")) {
                  document
                    .getElementById(this.socket.id + "peer-name-tag")
                    .classList.add("name-tag-style");
                }
                if (document.getElementById(this.socket.id + "name-tag")) {
                  document
                    .getElementById(this.socket.id + "name-tag")
                    .classList.add("name-tag-style");
                }
              }
            });

            this.globalTimeout = setTimeout(() => {
              const audioIconItems = document?.getElementById(this.socket.id)?.childNodes;
              audioIconItems?.forEach((item) => {
                if (item.classList.contains("animate-flicker")) {
                  item.classList.remove("animate-flicker");

                  if (document.getElementById(this.socket.id + "name-tag")) {
                    document
                      .getElementById(this.socket.id + "name-tag")
                      .classList.remove("name-tag-style");
                  }
                  if (document.getElementById(this.socket.id + "peer-name-tag")) {
                    document
                      .getElementById(this.socket.id + "peer-name-tag")
                      .classList.remove("name-tag-style");
                  }
                }
              });
            }, 700);
          }
        });

        this.socket.on("closeConsumerOLD", async (storageId) => {
          console.log("close consumer", storageId);
          //console.log("this.peers______", this.peers);

          // console.log('peerData_________',peerData);
          // if (document.getElementById(storageId + "_SHARE_SCREEN_VIDEO") != null) {
          //   document.getElementById("Screen").style.display = "none";
          //   this.mainScreenStreamRef = null
          //   console.log('_SHARE_SCREEN_VIDEO---------------', storageId);
          // }
          if (document.getElementById(storageId + "_STREAM_VIDEO_DIV")) {
            console.log("DISPLAY NONE HTI");
            document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display =
              "flex";

            // var el = document.getElementById(storageId + "_STREAM_VIDEO_DIV");
            // var removedChild = el.remove();
            // document.body.removeChild(document.getElementById(storageId + "_STREAM_VIDEO_DIV"))
          }
          if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
            //ADDED IN DIV OR SCREEN SHARING OR
            document.getElementById("Screen").style.display = "none";
            console.log("_1---------------", storageId);
            // document.getElementById(storageId + "_STREAM_VIDEO_DIV").style.display = "flex";

            if (this.mainScreenStreamRef.storageId == storageId) {
              console.log("_2---------------", this.mainScreenStreamRef.storageId);
              console.log("if___3______");
              if (
                document.getElementById(
                  this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                ) != null
              ) {
                console.log("if___2______");
                const peerData = this.peers.filter(
                  (peerData) => peerData?.storageId !== this.mainScreenStreamRef.storageId
                );
                this.peers = peerData;
                console.log(
                  "removeChild____v_____",
                  this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                );
                document
                  .getElementById(
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  )
                  .remove();
                await this.receiveTransport[storageId]?.close();
                this.receiveTransport[storageId] = null;
                this.consumers.delete(storageId);
                // document.body.removeChild(document.getElementById(this.mainScreenStreamRef.storageId))
              }
            } else {
              console.log("AVVo");
            }
            this.mainScreenStreamRef = null;
          } else {
            const peerData = this.peers.filter(
              (peerData) => peerData?.storageId !== storageId
            );
            this.peers = peerData;

            await this.receiveTransport[storageId]?.close();
            this.receiveTransport[storageId] = null;
            this.consumers.delete(storageId);
            // if (this.peersRef.some((item) => item?.storageId === storageId)) {
            //   const peerFilter = this.peersRef.filter((peer) => peer?.storageId !== storageId)
            //   this.peersRef = peerFilter;
            //   this.peers = peerFilter;
            //   console.log('peerFilter_________', peerFilter);
            // }
            // setPeers(peerFilter)
            if (this.mainScreenStreamRef) {
              console.log("if___1______");
              if (this.mainScreenStreamRef.storageId == storageId) {
                const peerData = this.peers.filter(
                  (peerData) => peerData?.storageId !== storageId
                );
                this.peers = peerData;
                console.log("if___3______");
                if (
                  document.getElementById(
                    this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                  )
                ) {
                  console.log("if___2______");
                  console.log(
                    "removeChild____ifff_____",
                    this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                  );
                  document.body.removeChild(
                    document.getElementById(
                      this.mainScreenStreamRef.storageId + "_SHARE_SCREEN_VIDEO"
                    )
                  );
                }
              } else {
                console.log("AAVO");
              }
            } else {
              console.log("if___5______");
            }
          }
          if (document.getElementById(storageId)) {
            console.log("removeChild_________", storageId);
            document.body.removeChild(document.getElementById(storageId));
          }
          const audioElementData = this.audioElement.filter(
            (audioData) => audioData !== storageId
          );
          this.audioElement = audioElementData;
          console.log("this.peers", this.peers);
          this.manageDiv();
        });

        this.socket.on("transportCreated", (data) => {
          this.handleTransport(data);
        });
        this.socket.on("stopShareScreen", (data) => {
          console.log("_________stopShareScreen___________");
          this.ScreenShare(data);
        });

        this.socket.on("chat", (data) => {
          console.log("CHAT______", data);
          this.chatHistory.unshift(data);
        });
        this.socket.on("hideRaideHandForUser", (data) => {
          this.raiseHand();
        });
        this.socket.on("raise_hand", (data) => {
          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == data.socketId &&
              this.mainScreenStreamRef?.screenShare == false
            ) {
              this.mainScreenStreamRef.raiseHand = data.is_show;
            }
          }
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].raiseHand = data.is_show;
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].raiseHand = data.is_show;
            }
          }

          console.log("raise_hand______", data);

          // this.chatHistory.push(data);
        });

        this.socket.on("currentViewers", (producers) => {
          console.log("currentViewers:   ", producers);
          this.filterdParticipants = producers;
          // producers?.forEach((producer) => {
          //   this.startConsumeProducer(producer)
          // })
        });
        this.socket.on("currentProducers", (producers) => {
          producers?.forEach((producer) => {
            this.startConsumeProducer(producer);
          });
        });

        this.socket.on("producerResumed", async ({ storageId, socketId, kind }) => {
          //console.log("this.peers_______________", this.peers);
          console.log("producerResumed________", {
            storageId,
            socketId,
            kind,
          });
          if (kind === "video") {
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.socketId == socketId) {
                this.mainScreenStreamRef.mutedVideo = false;
              }
            }
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == storageId && user?.screenShare == false
              );

              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peers[objIndex].mutedVideo = false;
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.storageId == storageId && user?.screenShare == false
              );

              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peerShow[objIndex].mutedVideo = false;
              }
            }
            if (document.getElementById(storageId + "_STREAM_VIDEO_POSITION") != null) {
              document.getElementById(
                storageId + "_STREAM_VIDEO_POSITION"
              ).style.position = "relative";
              document.getElementById(storageId + "_STREAM_VIDEO_POSITION").style.width =
                "max-content";
            }
            if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
              document.getElementById(storageId + "_STREAM_VIDEO").style.visibility =
                "visible";
            }
            if (document.getElementById(storageId + "_VIDEO") != null) {
              document.getElementById(storageId + "_VIDEO").style.visibility = "hidden";
            }
            if (document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") != null) {
              document.getElementById(
                storageId + "_STREAM_VIDEO_ZOOM_ICON"
              ).style.visibility = "visible";
            }
            if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
              document.getElementById(
                storageId + "_STREAM_SCREEN_VIDEO"
              ).style.visibility = "visible";
              document.getElementById("Screen").style.background = "#black";
            }
            if (
              document.getElementById(
                "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
              ).style.background = "transparent";
            }
            if (document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") != null) {
              document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO").style.display =
                "none";
            }
            if (
              document.getElementById(
                "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
              ).style.display = "none";
            }
            if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
              document.getElementById(storageId + "_SCREEN_VIDEO").style.display = "none";
            }
            if (
              document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH") !=
              null
            ) {
              document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH")
                ? (document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "none")
                : "";
            }

            if (document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                ? (document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                ).style.display = "none")
                : "";
            } else {
              console.log("offlinedoes not exist! _OFFLINE_VIDEO_TOP_NOTIFY");
            }

            if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") != null) {
              document.getElementById(
                socketId + "_VIDEO_TOP_NOTIFY_SLASH"
              ).style.display = "none";
            }
            if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_VIDEO_TOP_NOTIFY").style.display =
                "inline";
            }
            if (document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null) {
              document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION").innerHTML =
                "Mute Camera";
            }
            if (document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") != null) {
              document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION").innerHTML =
                "Mute Camera";
            }
            if (socketId == this.socket.id) {
              this.currentVideoState = true;
            }
            //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
          } else {
            const index = this.audioMicClose.indexOf(socketId);
            if (index > -1) {
              // only splice array when item is found
              this.audioMicClose.splice(index, 1); // 2nd parameter means remove one item only
            }
            if (document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                ? (document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                ).style.display = "none")
                : "";
            }
            if (
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH") !=
              null
            ) {
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH")
                ? (document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "none")
                : "";
            }
            if (document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null) {
              document.getElementById(socketId + "_AUDIO_MUTE_ACTION").innerHTML =
                "Mute Mic";
            }
            if (document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") != null) {
              document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION").innerHTML =
                "Mute Mic";
            }
            if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_AUDIO_TOP_NOTIFY").style.display =
                "inline";
            }
            if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") != null) {
              document.getElementById(
                socketId + "_AUDIO_TOP_NOTIFY_SLASH"
              ).style.display = "none";
            }
            if (socketId == this.socket.id) {
              this.currentMicState = true;
            }

            if (this.recordingRef) {
              this.resetVideoRec()
            }
            //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
          }
        });

        this.socket.on("producerPaused", async ({ storageId, socketId, kind }) => {
          //console.log("this.peers_______________", this.peers);
          console.log("producerPaused________", {
            storageId,
            socketId,
            kind,
          });

          if (kind === "video") {
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.socketId == socketId) {
                this.mainScreenStreamRef.mutedVideo = true;
              }
            }
            if (this.peers.length > 0) {
              var objIndex = this.peers.findIndex(
                (user) => user?.storageId == storageId && user?.screenShare == false
              );

              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peers[objIndex].mutedVideo = true;
              }
            }
            if (this.peerShow.length > 0) {
              var objIndex = this.peerShow.findIndex(
                (user) => user?.storageId == storageId && user?.screenShare == false
              );

              //console.log("objIndex______", objIndex);
              if (objIndex >= 0) {
                //console.log("objIndex______", objIndex);
                this.peerShow[objIndex].mutedVideo = true;
              }
            }
            if (socketId == this.socket.id) {
              this.currentVideoState = false;
            }
            if (document.getElementById(storageId + "_STREAM_VIDEO") != null) {
              document.getElementById(storageId + "_STREAM_VIDEO").style.visibility =
                "hidden";
            }
            if (document.getElementById(storageId + "_STREAM_VIDEO_POSITION") != null) {
              document.getElementById(
                storageId + "_STREAM_VIDEO_POSITION"
              ).style.position = "";
              document.getElementById(storageId + "_STREAM_VIDEO_POSITION").style.width =
                "inherit";
            }
            if (document.getElementById(storageId + "_VIDEO") != null) {
              document.getElementById(storageId + "_VIDEO").style.visibility = "visible";
            }

            if (document.getElementById(storageId + "_STREAM_VIDEO_ZOOM_ICON") != null) {
              document.getElementById(
                storageId + "_STREAM_VIDEO_ZOOM_ICON"
              ).style.visibility = "hidden";
            }

            if (
              document.getElementById(
                "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                "localStream" + socketId + "_STREAM_SCREEN_VIDEO"
              ).style.background = "black";
            }
            if (document.getElementById(storageId + "_STREAM_SCREEN_VIDEO") != null) {
              document.getElementById("Screen").style.background = "black";
              document.getElementById(
                storageId + "_STREAM_SCREEN_VIDEO"
              ).style.visibility = "hidden";
            }
            if (document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO") != null) {
              document.getElementById(storageId + "_OFFLINE_SCREEN_VIDEO").style.display =
                "none";
            }
            if (
              document.getElementById(
                "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
              ) != null
            ) {
              document.getElementById(
                "localStream" + socketId + "_OFFLINE_SCREEN_VIDEO"
              ).style.display = "none";
            }
            if (document.getElementById(storageId + "_SCREEN_VIDEO") != null) {
              document.getElementById(storageId + "_SCREEN_VIDEO").style.display = "flex";
            }
            if (
              document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH") !=
              null
            ) {
              document.getElementById(socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH")
                ? (document.getElementById(
                  socketId + "_OFFLINE_VIDEO_TOP_NOTIFY_SLASH"
                ).style.display = "none")
                : "";
            }

            if (
              document.getElementById(storageId + "_OFFLINE_VIDEO_TOP_NOTIFY") != null
            ) {
              document.getElementById(storageId + "_OFFLINE_VIDEO_TOP_NOTIFY")
                ? (document.getElementById(
                  storageId + "_OFFLINE_VIDEO_TOP_NOTIFY"
                ).style.display = "none")
                : "";
            } else {
              console.log("offline does not exist!");
            }
            //DROP TOP LEFT HOVER ACTION
            if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY_SLASH") != null) {
              document.getElementById(
                socketId + "_VIDEO_TOP_NOTIFY_SLASH"
              ).style.display = "inline";
            }

            if (document.getElementById(socketId + "_VIDEO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_VIDEO_TOP_NOTIFY").style.display =
                "none";
            }
            //DROP DOWN REMOTE ACTION
            if (document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION") != null) {
              document.getElementById(socketId + "_VIDEO_UNMUTE_ACTION").innerHTML =
                "Unmute Camera";
            }
            if (document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION") != null) {
              document.getElementById(socketId + "_VIDEO_MAIN_UNMUTE_ACTION").innerHTML =
                "Unmute Camera";
            }
            //Select Remote Stream Container Element With SocketID And Display Picture And Name In Front Of Video. Video Goes To Back With ZIndex.
          } else {
            this.audioMicClose.push(socketId);
            if (document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY")
                ? (document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY"
                ).style.display = "none")
                : "";
            }
            if (
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH") !=
              null
            ) {
              document.getElementById(socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH")
                ? (document.getElementById(
                  socketId + "_OFFLINE_AUDIO_TOP_NOTIFY_SLASH"
                ).style.display = "none")
                : "";
            }
            if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY") != null) {
              document.getElementById(socketId + "_AUDIO_TOP_NOTIFY").style.display =
                "none";
            }
            if (document.getElementById(socketId + "_AUDIO_TOP_NOTIFY_SLASH") != null) {
              document.getElementById(
                socketId + "_AUDIO_TOP_NOTIFY_SLASH"
              ).style.display = "inline";
            }
            if (document.getElementById(socketId + "_AUDIO_MUTE_ACTION") != null) {
              document.getElementById(socketId + "_AUDIO_MUTE_ACTION").innerHTML =
                "Unmute Mic";
            }
            if (document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION") != null) {
              document.getElementById(socketId + "_AUDIO_MAIN_MUTE_ACTION").innerHTML =
                "Unmute Mic";
            }
            if (socketId == this.socket.id) {
              this.currentMicState = false;
            }

            if (this.recordingRef) {
              this.resetVideoRec()
            }
            //Select Remote Stream Container Element With SocketID And Display Mic Closed Icon On Top.
          }
        });

        this.socket.on("ConsumeTransportCreated", async (data) => {
          this.consume(data);
        });

        this.socket.on("consumerCreated", (data) => {
          this.handleConsumer(data);
        });

        this.socket.on("newProducer", (producer) => {
          this.startConsumeProducer(producer);
        });

        this.socket.on("renameUser", (data) => {
          if (document.getElementById(data.socketId + "_USER_MAIN_NAME") != null) {
            document.getElementById(data.socketId + "_USER_MAIN_NAME").innerHTML =
              data.name;
            console.log("renameUser________updated");
          }
          if (document.getElementById(data.socketId + "_USER_NAME") != null) {
            document.getElementById(data.socketId + "_USER_NAME").innerHTML = data.name;
            console.log("renameUser________updated");
          }
          if (document.getElementById(data.socketId + "_SCREEN_USER_NAME") != null) {
            document.getElementById(data.socketId + "_SCREEN_USER_NAME").innerHTML =
              data.name;
            console.log("renameUser________updated");
          }
          //console.log("this.peers______", this.peers);
          console.log("data______", data);

          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peers[objIndex].userDetails.user_name = data.name;
            }
            //Update object's name property.
            var objIndexScreen = this.peers.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == true
            );
            if (objIndexScreen >= 0) {
              console.log("objIndexScreen______", objIndexScreen);
              this.peers[objIndexScreen].userDetails.user_name = data.name;
            }
          }

          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              this.peerShow[objIndex].userDetails.user_name = data.name;
            }
            //Update object's name property.
            var objIndexScreen = this.peerShow.findIndex(
              (user) => user?.socketId == data.socketId && user?.screenShare == true
            );
            if (objIndexScreen >= 0) {
              console.log("objIndexScreen______", objIndexScreen);
              this.peerShow[objIndexScreen].userDetails.user_name = data.name;
            }
          }

          if (this.mainScreenStreamRef) {
            console.log("changes ______", this.mainScreenStreamRef);
            if (this.mainScreenStreamRef.socketId == data.socketId) {
              this.mainScreenStreamRef.userDetails.user_name = data.name;
              console.log("changes DONE______", this.mainScreenStreamRef);
            }
          }

          console.log("this.peers___NEW___", this.peers);
          if (this.socket.id == data.socketId) {
            console.log("HIII saME AS");
            this.user_name = data.name;
          }
        });

        this.socket.on("removeUser", () => {
          $("#remove_users").modal("show");

          setTimeout(function () {
            window.location.href = window.origin;
            // this.$router.push("/login");
          }, 2000);
        });

        this.socket.on("muteMic", ({ remote, action, userName }) => {
          console.log("remote---muteMic---", remote);
          if (action == "unmute") {
            this.requestUserName = userName;
            $("#requestMicModal").modal("show");
          } else {
            this.handleMic();
          }
        });

        this.socket.on("muteVideo", ({ socketId, remote, action, userName }) => {
          console.log("remote---muteVideo---", socketId, remote, action);
          console.log("is_cam_request-------", this.is_cam_request);

          if (action == "unmute") {
            this.requestUserName = userName;
            $("#requestCamModal").modal("show");
          } else {
            this.handleVideo();
          }
        });

        window.setInterval(() => {
          resize();
          var video = document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT");
          if (video) {
            video.videoHeight; // returns the intrinsic height of the video
            video.videoWidth; // returns the intrinsic width of the video
            if (video.videoHeight < video.videoWidth) {
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width != "100%"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width = "100%";
              }
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit !=
                "cover"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit =
                  "cover";
                console.log("video_landscape_: TRUE");
              }
            } else {
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit !=
                "contain"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.objectFit =
                  "contain";
              }
              if (
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width != "auto"
              ) {
                document.getElementById("ID_OF_YOUR_VIDEO_ELEMENT").style.width = "auto";
                console.log("video_landscape_: FALSE______");
              }
              /*OR*/
              /*video_portrait = "true"*/
            }
          }
        }, 100);
      }
    },
    async allowMicModal() {
      $("#requestMicModal").modal("hide");
      // this.requestUserName = "";
      this.handleMic();
    },
    async denyMicModal() {
      $("#requestMicModal").modal("hide");
      // this.requestUserName = "";
    },
    async allowCamModal(producer) {
      this.is_cam_request = true;
      $("#requestCamModal").modal("hide");
      // this.requestUserName = "";
      this.handleVideo();
      // this.is_cam_request = false;
    },
    async denyCamModal(producer) {
      $("#requestCamModal").modal("hide");
      // this.requestUserName = "";
    },
    async startConsumeProducer(producer) {
      console.log("HHHHHHHHHHHH");
      this.socket.emit("createConsumeTransport", producer);
    },
    async handleCapabilities(capabilities) {
      const cap = { routerRtpCapabilities: capabilities };
      const detectedHandler = detectDevice();
      let handlerName;
      // alert(detectedHandler)
      if (detectedHandler) {
        handlerName = detectedHandler;
      } else {
        handlerName = "Safari12";
      }
      try {
        if (handlerName !== "Firefox60") {
          cap.routerRtpCapabilities.headerExtensions = cap.routerRtpCapabilities.headerExtensions.filter(
            (ext) => ext.uri !== "urn:3gpp:video-orientation"
          );
        }
        this.mediasoupDevice = new Device({ handlerName: handlerName });
        // alert(handlerName)
        console.log("this.mediasoupDevice-----", this.mediasoupDevice);
      } catch (err) {
        console.log("err-----", err);
      }
      await this.mediasoupDevice?.load(cap);

      if (this.mediasoupDevice?.loaded) {
        console.log("selectedVideo --- ", this.selectedVideo);
        console.log("selectedAudio --- ", this.selectedAudio);
        console.log("loaded --- ");
      }
    },
    async startStream() {
      try {
        const localStream = await navigator?.mediaDevices?.getUserMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true,
          }
        });

        const audioTracks = localStream.getAudioTracks();
        if (audioTracks.length > 0) {
          const audioDeviceId = audioTracks[0].getSettings().deviceId;
          this.selectedAudio = audioDeviceId;
          this.is_mic_allow = true;
          console.log("Audio device ID:", audioDeviceId);
        } else {
          console.error("No video tracks found in the stream.");
        }

        // const videoTracks = localStream.getVideoTracks();
        // if (videoTracks.length > 0) {
        //   const videoDeviceId = videoTracks[0].getSettings().deviceId;
        //   this.selectedVideo = videoDeviceId
        //   console.log('Video device ID:', videoDeviceId);
        // } else {
        //   console.error('No video tracks found in the stream.');
        // }
        this.localStreamRef = localStream;

        console.log("localStream----222-------", this.localStreamRef);

        this.peers.push({
          is_show: true,
          socketId: this.socket.id,
          storageId: "LocalStream_storageId",
          screenShare: false,
          userDetails: {
            user_name: this.user_name,
            profileImg: this.profileImg,
            webinarUserType: this.role,
          },
          is_mute: false,
          is_ban: false,
          paused: true,
          raiseHand: false,
          mutedVideo: true,
          kind: "video",
          mediaStream: this.localStreamRef,
        });

        this.peersRef = [
          ...this.peersRef,
          {
            kind: "video",
            raiseHand: false,
            mutedVideo: true,
            is_show: true,
            paused: true,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
              webinarUserType: this.role,
            },
            socketId: this.socket.id,
            storageId: "LocalStream_storageId",
            screenShare: false,
            mediaStream: this.localStreamRef,
          },
        ];

        this.peerShow.push({
          is_show: true,
          socketId: this.socket.id,
          storageId: "LocalStream_storageId",
          screenShare: false,
          raiseHand: false,
          userDetails: {
            user_name: this.user_name,
            profileImg: this.profileImg,
            webinarUserType: this.role,
          },
          is_mute: false,
          is_ban: false,
          paused: true,
          mutedVideo: true,
          kind: "video",
          mediaStream: this.localStreamRef,
        });

        this.filterdParticipants = this.peerShow.filter(
          (a) => a.userDetails.webinarUserType == "HOST"
        );

        // this.audioPeersRef = [
        //   ...this.audioPeersRef,
        //   {
        //     socketId: this.socket.id,
        //     storageId: "LocalStream_storageId",
        //     mediaStream: this.localStreamRef,
        //     consumer: "consumer",
        //   },
        // ];
        // this.handleAudioLevel();
      } catch (error) {
        $("#errorModal").modal("show");
        this.running_error = error;
      }
      // this.audioElement.push('localStream' + this.socket.id)
      // const audioElem = document.createElement('audio')
      // audioElem.autoplay = true;
      // audioElem.srcObject = localStream;
      // audioElem.id = 'localStream' + this.socket.id;
      // this.audioElement.push('localStream' + this.socket.id)
      // document.body.appendChild(audioElem);
    },
    async openenterNameModal() {
      $("#enterNameModal").modal("show");
    },
    async manageDiv() {
      //console.log("this.peers.length__________", this.peers.length);

      if (this.peers.length == 1) {
        if (this.mainScreenStreamRef == null) {
          document.getElementById("ALL_VIDEOS").style.display = "flex";
        } else {
          if (this.mainScreenStreamRef.screenShare) {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          } else {
            document.getElementById("ALL_VIDEOS").style.display = "none";
          }
        }
      } else {
        if (this.mainScreenStreamRef) {
          if (this.peers.length == 0) {
            document.getElementById("ALL_VIDEOS").style.display = "none";
          } else {
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          }
        } else {
          document.getElementById("ALL_VIDEOS").style.display = "flex";
        }
      }
    },
    async startProducing() {
      this.socket.emit("createTransport", this.socket.id);
    },
    async handleTransport({ data }) {
      console.log("trans", data);
      this.produceTransport = await this.mediasoupDevice.createSendTransport(data);

      console.log("trans ---  this.produceTransport----", this.produceTransport);
      this.produceTransport.on("connect", ({ dtlsParameters }, callback, errback) => {
        console.log("trans ---  connect----");
        this.socket.emit("connectTransport", {
          dtlsParameters,
          id: this.socket.id,
        });
        this.socket.on("transportConnected", () => {
          console.log("trans ---  transportConnected----");
          callback();
        });
      });

      this.produceTransport.on(
        "produce",
        ({ kind, rtpParameters, appData }, callback, errback) => {
          console.log("trans ---  produce----", {
            kind,
            rtpParameters,
            appData,
          });
          this.socket.emit("produce", {
            kind,
            rtpParameters,
            id: this.socket.id,
            room: this.roomId,
            appData,
          });
          this.socket.on("producing", ({ producerId, kind, screenShare, appData }) => {
            console.log("trans ---  producing----", producerId, kind, screenShare);
            if (kind === "video") {
              if (screenShare) {
                this.videoProducerId2 = producerId;
              } else if (appData?.type === "record") {
                if (!this.recordIds?.includes(producerId)) {
                  this.recordIds?.push(producerId);

                  if (this.recordIds.length === 2) {
                    this.socket.emit("startRecording", this.recordIds);
                  }
                }
              } else {
                this.videoProducerId = producerId;
              }
            } else {
              if (screenShare) {
                this.audioProducerId2 = producerId;
              } else if (appData?.type === "record") {
                if (!this.recordIds?.includes(producerId)) {
                  this.recordIds?.push(producerId);
                  if (this.recordIds.length === 2) {
                    this.socket.emit("startRecording", this.recordIds);
                  }
                }
              } else {
                this.audioProducerId = producerId;
              }
            }

            callback(producerId);
          });
        }
      );
      try {
        this.produceTransport?.on(
          "producedata",
          ({ sctpStreamParameters }, callback, errback) => {
            this.socket.emit(
              "produceData",
              { params: sctpStreamParameters, roomId: this.roomId },
              (response) => {
                if (response?.type === "success") {
                  this.dataProducerId = response?.id;
                  console.log("response?.id-------------", response?.id);
                  callback(response?.id);
                } else {
                  console.log("response?.error-------------", response?.error);
                  errback(response?.error);
                }
              }
            );
          }
        );
        this.produceTransport.on("connectionstatechange", (state) => {
          console.log("state-----------", state);
          if (state == "connected") {
            let videoInput = this.deviceData.filter(
              (device) => device.kind === "videoinput"
            );
            if (videoInput.length > 0) {
              // this.selectedVideo = videoInput[0].deviceId;
            }
            let audioInput = this.deviceData.filter(
              (device) => device.kind === "audioinput"
            );
            if (audioInput.length > 0) {
              // this.selectedAudio = audioInput[0].deviceId;
            }
            let audioOutput = this.deviceData.filter(
              (device) => device.kind === "audiooutput"
            );
            if (audioOutput.length > 0) {
              const audioOutputDe = this.deviceData.filter(
                (device) =>
                  (device.kind === "audiooutput" && device.deviceId == "default") ||
                  device.kind === "audiooutput" ||
                  device.deviceId == "default"
              );
              this.selectedOuterAudio = audioOutput[0].deviceId;
            }
            $("#enterNameModal").modal("hide");
            this.join_loader = false;
          }
          switch ((state, err)) {
            case "connecting":
              console.log("connecting");
              break;
            case "connected":
              console.log("connected");
              break;
            case "failed":
              $("#errorModal").modal("show");
              this.running_error = error;
              console.log("failed");
              this.join_loader = false;
              // window.location.reload()
              break;
            default:
              break;
          }

          console.log("state------err-----", state);
        });

        try {
          console.log("this.localStreamRef----", this.localStreamRef);
          const Audiotracks = this.localStreamRef.getAudioTracks()[0];
          // const Videotracks = this.localStreamRef.getVideoTracks()[0];
          if (Audiotracks) {
            this.audioProducer = await this.produceTransport?.produce({
              track: Audiotracks,
            });
            // this.handleMic()
            this.socket.emit(
              "handleMicVideoProducer",
              {
                producerId: this.audioProducerId,
                state: true,
                kind: "audio",
                storageId: this.currentMicState,
                socketId: this.socket.id,
              },
              async (response) => {
                await this.audioProducer.pause();
                this.audio = false;
              }
            );
          }
          // if (Videotracks) {
          //   this.videoProducer = await this.produceTransport?.produce({
          //     track: Videotracks,
          //   });
          //   // this.handleVideo()
          //   this.audio = false;
          //   this.localStreamRef?.getVideoTracks()?.forEach((track) => {
          //     track?.stop();
          //   });

          //   if (this.peers.length > 0) {
          //     var objIndex = this.peers.findIndex(
          //       (user) =>
          //         user?.socketId == this.socket.id && user?.screenShare == false
          //     );

          //     //console.log("objIndex______", objIndex);
          //     if (objIndex >= 0) {
          //       const source = this.peers.find(
          //         (user) =>
          //           user?.socketId == this.socket.id &&
          //           user?.screenShare == false
          //       );
          //       //console.log("objIndex______", objIndex);
          //       if (source.mediaStream) {
          //         source.mediaStream.getVideoTracks().forEach(function (track) {
          //           console.log("PS :)  ", track.stop());
          //           track.stop();
          //         });
          //         this.peers[objIndex].mediaStream = null;
          //       }
          //       //console.log("objIndex______", objIndex);
          //       this.peers[objIndex].mutedVideo = true;
          //     }
          //   }
          //   if (this.peersRef.length > 0) {
          //     var objIndex = this.peersRef.findIndex(
          //       (user) =>
          //         user?.socketId == this.socket.id && user?.screenShare == false
          //     );
          //     //console.log("objIndex______", objIndex);
          //     if (objIndex >= 0) {
          //       const source = this.peersRef.find(
          //         (user) =>
          //           user?.socketId == this.socket.id &&
          //           user?.screenShare == false
          //       );
          //       //console.log("objIndex______", objIndex);
          //       if (source.mediaStream) {
          //         source.mediaStream.getVideoTracks().forEach(function (track) {
          //           console.log("PS :)  ", track.stop());
          //           track.stop();
          //         });
          //         this.peersRef[objIndex].mediaStream = null;
          //       }
          //       //console.log("objIndex______", objIndex);
          //       this.peersRef[objIndex].mutedVideo = true;
          //     }
          //   }
          //   if (this.peerShow.length > 0) {
          //     var objIndex = this.peerShow.findIndex(
          //       (user) =>
          //         user?.socketId == this.socket.id && user?.screenShare == false
          //     );
          //     //console.log("objIndex______", objIndex);
          //     if (objIndex >= 0) {
          //       const source = this.peerShow.find(
          //         (user) =>
          //           user?.socketId == this.socket.id &&
          //           user?.screenShare == false
          //       );
          //       //console.log("objIndex______", objIndex);
          //       if (source.mediaStream) {
          //         source.mediaStream.getVideoTracks().forEach(function (track) {
          //           console.log("PS :)  ", track.stop());
          //           track.stop();
          //         });
          //         this.peerShow[objIndex].mediaStream = null;
          //       }
          //       //console.log("objIndex______", objIndex);
          //       this.peerShow[objIndex].mutedVideo = true;
          //     }
          //   }
          //   if (
          //     document.getElementById(
          //       "localStream" + this.socket.id + "_SCREEN_VIDEO"
          //     ) != null
          //   ) {
          //     document.getElementById(
          //       "localStream" + this.socket.id + "_SCREEN_VIDEO"
          //     ).style.display = "flex";
          //   }

          //   if (
          //     document.getElementById(
          //       "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          //     ) != null
          //   ) {
          //     document.getElementById(
          //       "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
          //     ).style.background = "black";
          //   }
          //   this.socket.emit(
          //     "handleMicVideoProducer",
          //     {
          //       producerId: this.videoProducerId,
          //       state: true,
          //       kind: "video",
          //       storageId: this.currentVideoState,
          //       socketId: this.socket.id,
          //     },
          //     async (response) => {
          //       await this.videoProducer.pause();
          //     }
          //   );
          // }
        } catch (err) {
          $("#errorModal").modal("show");
          this.running_error = err;
          console.log(err);
        }
        // this.handleMic()
        // this.handleVideso()
        this.is_stream = false;
      } catch (e) {
        console.log("-----------e--------", e);
      }
    },
    async handleConsumer(data) {
      const {
        producerId,
        kind,
        id,
        paused,
        rtpParameters,
        screenShare,
        socketId,
        storageId,
        userDetails,
        muted,
      } = data;

      let codecOptions = {};
      let mutedAudio = false;
      const consumer = await this.receiveTransport[data?.storageId].consume({
        id,
        producerId,
        kind,
        rtpParameters,
        codecOptions,
      });
      this.consumers.set(data?.storageId, consumer);

      const mediaStream = new MediaStream();
      mediaStream.addTrack(consumer.track);
      console.log("-mediaStream", mediaStream);
      console.log("-consumer", consumer);
      console.log("-ppppppp", data);
      console.log("-this.peers", this.peers);

      if (this.peers.some((peer) => peer.socketId === socketId)) {
        if (kind === "video") {
          // if (this.recordingRef) {
          //   this.mixer?.appendStreams(mediaStream)
          // }
          if (screenShare) {
            var video = document.getElementById(socketId + "STREAM_SCR_SRC");
            this.screenShareLoader = true;
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.screenShare == false) {
                console.log("*****1*******");
                this.updateIsShow(this.mainScreenStreamRef.storageId, true);
              } else {
                this.mainScreenStreamRef = null;
              }
            } else {
            }
            this.mainScreenStreamRef = {
              socketId: socketId,
              storageId: storageId,
              is_autoplay: false,
              screenShare: screenShare,
              userDetails: userDetails,
              remoteScreen: true,
              paused: paused,
              mutedVideo: paused,
              raiseHand: false,
              kind: kind,
              mediaStream,
            };
            document.getElementById("Screen").style.display = "flex";
          } else {
            console.log();
            if (this.mainScreenStreamRef) {
              if (
                this.mainScreenStreamRef.socketId == socketId &&
                this.mainScreenStreamRef.screenShare == false
              ) {
                this.mainScreenStreamRef.mediaStream = mediaStream;
              } else {
                var video = document.getElementById(socketId + "STREAM_SRC");
                // video.srcObject = mediaStream;
                console.log("-----", mediaStream);
                this.updateCamStream(socketId, mediaStream);
                this.updateCamStatus(socketId, false);
                // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
                // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
              }
            } else {
              var video = document.getElementById(socketId + "STREAM_SRC");
              // video.srcObject = mediaStream;
              console.log("-----", mediaStream);
              this.updateCamStream(socketId, mediaStream);
              this.updateCamStatus(socketId, false);
              // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
              // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
            }
            // video.autoplay = true;
          }
        } else {
          if (muted == true) {
            this.audioMicClose.push(socketId);
          }
          const audioElem = document.createElement("audio");
          audioElem.autoplay = true;
          audioElem.srcObject = mediaStream;
          // audioElem.play();
          audioElem.id = data.storageId;
          this.audioElement.push(data.storageId);
          this.audioElementSocket.push({
            storageId: data.storageId,
            socketId: data.socketId,
          });
          console.log("*************************");
          console.log(this.selectedOuterAudio);
          console.log(this.role);
          console.log("*************************");
          document.body.appendChild(audioElem);
          // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "9" : '';
          // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
          if (!screenShare) {
            this.updateCamStatus(socketId, true);
          }
          this.audioPeersRef = [
            ...this.audioPeersRef,
            {
              socketId: socketId,
              storageId: storageId,
              mediaStream: mediaStream,
              consumer: consumer,
            },
          ];
          // if (this.role == "VIEWER") {
          // } else {
          //   this.changeOuterSpeaker(this.selectedOuterAudio);
          // }
        }
      } else {
        this.peerShow.push({
          is_show: true,
          socketId: socketId,
          is_autoplay: false,
          storageId: storageId,
          screenShare: screenShare,
          userDetails: userDetails,
          paused: paused,
          mutedVideo: paused,
          is_mute: false,
          is_ban: false,
          raiseHand: false,
          kind: kind,
          mediaStream,
        });

        this.peers.push({
          is_show: true,
          socketId: socketId,
          storageId: storageId,
          is_autoplay: false,
          screenShare: screenShare,
          userDetails: userDetails,
          raiseHand: false,
          is_mute: false,
          is_ban: false,
          paused: paused,
          mutedVideo: paused,
          kind: kind,
          mediaStream,
        });
        // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
        // this.peerShow = peerFilter;

        this.peersRef = [
          ...this.peersRef,
          {
            kind: kind,
            is_show: true,
            raiseHand: false,
            paused: paused,
            userDetails: userDetails,
            socketId: socketId,
            storageId: storageId,
            mutedVideo: paused,
            mediaStream,
          },
        ];

        if (kind === "video") {
          // if (this.recordingRef) {
          //   this.mixer?.appendStreams(mediaStream)
          // }
          if (screenShare) {
            var video = document.getElementById(socketId + "STREAM_SCR_SRC");
            this.screenShareLoader = true;
            if (this.mainScreenStreamRef) {
              if (this.mainScreenStreamRef.screenShare == false) {
                console.log("*****1*******");
                this.updateIsShow(this.mainScreenStreamRef.storageId, true);
              } else {
                this.mainScreenStreamRef = null;
              }
            } else {
            }
            this.mainScreenStreamRef = {
              socketId: socketId,
              storageId: storageId,
              is_autoplay: false,
              screenShare: screenShare,
              userDetails: userDetails,
              remoteScreen: true,
              paused: paused,
              mutedVideo: muted,
              raiseHand: false,
              kind: kind,
              mediaStream,
            };
            document.getElementById("Screen").style.display = "flex";
          } else {
            var video = document.getElementById(socketId + "STREAM_SRC");
            // document.getElementById(socketId + "STREAM_SRC") != null ? document.getElementById(socketId + "STREAM_SRC").style.zIndex = "10" : '';
            // document.getElementById(socketId + "_PEER_IMAGE") != null ? document.getElementById(socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
            // video.srcObject = mediaStream;
            this.updateCamStream(socketId, mediaStream);
            this.updateCamStatus(socketId, false);
            // video.autoplay = true;
          }

        } else {
          if (muted == true) {
            this.audioMicClose.push(socketId);
          }
          const audioElem = document.createElement("audio");
          audioElem.autoplay = true;
          audioElem.srcObject = mediaStream;
          // audioElem.play();
          audioElem.id = data.storageId;
          this.audioElement.push(data.storageId);
          this.audioElementSocket.push({
            storageId: data.storageId,
            socketId: data.socketId,
          });
          console.log("*************************");
          console.log(this.selectedOuterAudio);
          console.log(this.role);
          console.log("*************************");
          document.body.appendChild(audioElem);
          if (!screenShare) {
            this.updateCamStatus(socketId, true);
          }

          this.audioPeersRef = [
            ...this.audioPeersRef,
            {
              socketId: socketId,
              storageId: storageId,
              mediaStream: mediaStream,
              consumer: consumer,
            },
          ];
        }
      }

      if (this.recordingRef) {
        this.resetVideoRec()
      }

      this.filterdParticipants = this.peerShow.filter(
        (a) => a.userDetails.webinarUserType == "HOST"
      );
      this.handleAudioLevel();
      this.manageDiv();

      console.log("newConsume______r", consumer?.track, screenShare);
      console.log("this.peersRef___________", this.peersRef);
    },
    async handleAudioLevel() {
      // for (let i = 0; i < this.audioElement.length; i++) {
      //   const element = this.audioElement[i];
      //   var x = document.getElementById(element);
      //   x.volume = this.audioLevel / 10 ;
      //
      // }
      // document.getElementById("myAudio").volume = 0.2;
      console.log('handleAudioLevel----', this.audioLevel)
    },
    async playStream(storageId, socketId) {
      console.log("_____-__-----____", this.audioElementSocket);
      console.log("s_____________", { storageId, socketId });
      let obj = this.audioElementSocket.find((o) => o.socketId == socketId);
      console.log("obj", obj);

      if (obj.length != 0) {
        var x = document.getElementById(obj.storageId);
        x.autoplay = true;
        x.play();

        if (this.mainScreenStreamRef) {
          if (
            this.mainScreenStreamRef.socketId == socketId &&
            this.mainScreenStreamRef?.screenShare == false
          ) {
            this.mainScreenStreamRef.is_autoplay = true;
          }
        }
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );

          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].is_autoplay = true;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex = this.peerShow.findIndex(
            (user) => user?.socketId == socketId && user?.screenShare == false
          );

          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peerShow[objIndex].is_autoplay = true;
          }
        }
      }
      document.getElementById(socketId + "_PEER_PLAY").style.display = "none";
      if (document.getElementById(socketId + "_PEER_IMAGE") != null) {
        document.getElementById(socketId + "_PEER_IMAGE").style.display = "flex";
      }
    },
    async joinAsViewer() {
      this.is_disable = true;
      this.role = "VIEWER";
      for (let i = 0; i < this.audioElement.length; i++) {
        const element = this.audioElement[i];
        var x = document.getElementById(element);
        x.autoplay = true;
        x.load();
      }
      $("#DeniedModal").modal("hide");
      $("#enterNameModal").modal("hide");
      $("#joinByViewerNameModal").modal("hide");
      this.joinMeeting();
    },
    async closeMeetingModal() {
      this.$router.push("/login");
    },
    async consume(data) {
      console.log("consume---", data);
      this.receiveTransport[
        data?.storageId
      ] = await this.mediasoupDevice?.createRecvTransport(data.data);

      this.receiveTransport[data?.storageId].on(
        "connect",
        ({ dtlsParameters }, callback, errback) => {
          this.socket.emit("transportConnect", {
            dtlsParameters,
            storageId: data?.storageId,
          });
          this.socket.on("consumerTransportConnected", (storageId) => {
            if (storageId === data?.storageId) {
              callback();
            }
          });
        }
      );

      this.receiveTransport[data?.storageId].on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            console.log("Connecting To Stream!");
            break;
          case "connected":
            console.log("subscribed!");
            break;
          case "failed":
            console.log("Failed!");
            // window.location.reload()
            break;
          default:
            break;
        }
      });

      this.socket.emit("startConsuming", {
        roomId: this.roomId,
        rtpCapabilities: this.mediasoupDevice?.rtpCapabilities,
        storageId: data?.storageId,
        producerId: data?.producerId,
        socketId: data?.socketId,
        paused: false,
        screenShare: data?.screenShare,
      });
    },
    async stopCloudRecording() {

      this.isRecordLoader = true;

      const loc = window.location.pathname;
      console.log("starting loc value = " + this.roomId);
      const locs =
        loc.lastIndexOf("/") == loc.length - 1
          ? loc.substring(0, loc.length - 1)
          : loc.substring(0, loc.lastIndexOf("/"));
      const companyname = locs.substring(locs.lastIndexOf("/") + 1);

      this.socket.emit("stopRecording", { meetingId: this.roomId, socketId: this.socketId, isCloudRec: this.recordType == 'Cloud' ? true : false });

      console.log("stopRecording-------- " + this.roomId);

      this.socket.emit("stopRecordingNotify", { roomId: this.roomId, username: this.user_name, socketId: this.socketId });
      // if (this.recordType == 'Local') {
      //   $("#stopLocalRecordingModal").modal("hide");

      // } else {
      //   $("#stopCloudRecordingModal").modal("hide");

      // }
      document.querySelector(".hrs").innerText = "00";
      document.querySelector(".min").innerText = "00";
      document.querySelector(".sec").innerText = "00";
      this.recordingToaster = false;
      // $("#stopRecordingModal").modal("hide");
      // const $toast = useToast();
      // $toast.open({
      //   message: 'Your recording is converting is in progress. please wait a few minutes.',
      //   type: 'info',
      //   dismissible: false,
      //   duration: 30000
      //   // all of other options may go here
      // });
      clearInterval(this.startTimer);


    },
    async startRecording(type) {
      this.recordType = type;
      const loc = window.location.pathname;
      console.log("starting loc value = " + loc);
      const locs =
        loc.lastIndexOf("/") == loc.length - 1
          ? loc.substring(0, loc.length - 1)
          : loc.substring(0, loc.lastIndexOf("/"));
      const companyname = locs.substring(locs.lastIndexOf("/") + 1);
      this.socket.emit("startRecordingNotify", { roomId: this.roomId, username: this.user_name, recordType: this.recordType });

      if (this.room_type == 'OPEN_ROOM') {
        var url = `${this.FRONT_BASE_URL}record${window.location.pathname}`;
      } else {
        var url = `${this.FRONT_BASE_URL}record${window.location.pathname}/${window.location.search}`;
      }

      console.log('url----------', url)
      this.socket.emit("startRecording", { meetingId: this.roomId, roomType: this.room_type, url: url, roomName: window.location.pathname.split("/").pop(), socketId: this.socketId, isCloudRec: this.recordType == 'Cloud' ? true : false });

      this.recordingToaster = true;
      console.log("this.recordingToaster = " + this.recordingToaster);

      this.recordingRef = true;
      let hrs = 0;
      let min = 0;
      let sec = 0;
      let ms = 0;

      this.startTimer = setInterval(() => {
        sec++; //ms=ms+1;
        if (ms == 1000) {
          sec++;
          ms = 0;
        }
        if (sec == 60) {
          min++;
          sec = 0;
        }
        if (min == 60) {
          hrs++;
          min = 0;
        }
        this.updateDisplay(hrs, min, sec);
      }, 1000);


    },
    async stopAskModal() {

      if (this.recordType == 'Local') {
        $("#stopLocalRecordingModal").modal("show");

      } else {
        $("#stopCloudRecordingModal").modal("show");

      }
      // $("#stopRecordingModal").modal("show");
    },
    async returnToRoom() {
      $("#stopRecordingModal").modal("hide");
    },
    async handleData(e) {
      // console.log(e);
      try {
        if (e.data?.size === 0) return;
        this.savedChunks.push(e.data);

        this.downloadData();
        // if(this.isBrave){
        // }else{

        //   if (!this.processingRef) {
        //     this.processingRef = true;
        //     this.processChunks();
        //   }
        // }
        // if (this.isBrave) {
        //   // this.downloadData();
        // } else {
        // }

      } catch (error) {
        $("#errorModal").modal("show");
        this.running_error = error;
      }
      // chunksRef.current?.push(e.data)
      // if (!processingRef.current) {
      //   processingRef.current = true
      //   processChunks()

      // }
    },
    async resetVideoRec() {
      // let streamsArr = []
      // console.log('-----> resetVideoRec')

      // this.localStreamRef.getVideoTracks()?.forEach((track) => {
      //   const medStr = new MediaStream();
      //   medStr.addTrack(track);
      //   streamsArr.push(medStr)
      // })

      // this.localScreenStreamRef?.getVideoTracks()?.forEach((track) => {
      //   const medStr = new MediaStream();
      //   medStr?.addTrack(track);
      //   streamsArr.push(medStr)
      // });

      // this.peersRef?.forEach((item) => {
      //   item?.mediaStream?.getVideoTracks()?.forEach((track, idx) => {
      //     const medStr = new MediaStream();
      //     medStr.addTrack(track);
      //     streamsArr.push(medStr)
      //   });

      // });

      // this.localStreamRef.getAudioTracks()?.forEach((track) => {
      //   const medStr = new MediaStream();
      //   medStr.addTrack(track);
      //   const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
      //   this.sourceNodes.set("local", { mediaStream: medStr, node: streamSrc });
      //   streamSrc.connect(this.dest);
      // });

      // this.localScreenStreamRef?.getAudioTracks()?.forEach((track) => {
      //   const medStr = new MediaStream();
      //   medStr?.addTrack(track);
      //   const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
      //   this.sourceNodes.set("screen", { mediaStream: medStr, node: streamSrc });
      //   streamSrc?.connect(this.dest);
      // });

      // this.audioPeersRef?.length !== 0 &&
      //   this.audioPeersRef?.forEach((item) => {
      //     item?.mediaStream?.getAudioTracks()?.forEach((track) => {
      //       const medStr = new MediaStream();
      //       medStr.addTrack(track);
      //       const streamSrc = this.audioContextRef.createMediaStreamSource(medStr);
      //       this.sourceNodes.set(item?.storageId, {
      //         mediaStream: medStr,
      //         node: streamSrc,
      //       });
      //       streamSrc.connect(this.dest);
      //     });
      //   });

      // this.mixer?.resetVideoStreams(streamsArr);
    },
    async sendStrMessage(message) {
      const size = new Blob([message]).size;
      const limit = 200 * 1024;
      if (this.dataProducer?.bufferedAmount + size >= limit) {
        await new Promise((resolve, reject) => {
          this.dataProducer?.on("bufferedamountlow", async () => {
            await this.dataProducer?.send(message);
            resolve();
          });
        });
      } else {
        await this.dataProducer?.send(message);
      }
    },
    async updateDisplay(hrs, min, sec) {
      const phrs = hrs < 10 ? "0" + hrs : hrs;
      const pmin = min < 10 ? "0" + min : min;
      const psec = sec < 10 ? "0" + sec : sec;
      //Output
      this.hrs = phrs;
      this.min = pmin;
      this.sec = psec;
    },
    async goLive() {
      let body = {
        id: this.webinarId,
        childID: this.childId ?? undefined,
      };
      body = await liveWebinar(body);
      if (body.status == 200) {
        this.goLiveStatus = false;
      }

      // goLiveStatus
    },
    async ScreenShare() {
      console.log("__", this.mainScreenStreamRef);
      console.log("***********************");

      if (this.screenShareStarted) {
        const producerIds = this.audioProducerId2
          ? [this.videoProducerId2, this.audioProducerId2]
          : [this.videoProducerId2];
        this.socket.emit("closeScreenShare", producerIds, async (response) => {
          if (this.audioProducer2) {
            await this.audioProducer2?.close();
            this.audioProducer2 = null;
            this.audioProducerId2 = null;
          }
          if (this.videoProducer2) {
            await this.videoProducer2?.close();
            this.videoProducer2 = null;
            this.videoProducerId2 = null;
          }
          this.localScreenStreamRef?.getTracks()?.forEach((track) => {
            track.stop();
          });
          // this.mainScreenStreamRef = null
          console.log("_______R A J______", this.peers);
          console.log("_______this.socket.id______", this.socket.id);
          const peerFilter = this.peers.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          const peerSData = this.peerShow.filter(
            (peerData) => peerData?.storageId !== this.socket.id + "SCREEN"
          );
          this.peers = peerFilter;
          this.peerShow = peerSData;
          console.log("_______peerFilter______", peerFilter);
          console.log("_______peerSData______", peerSData);
          if (this.mainScreenStreamRef) {
            if (this.mainScreenStreamRef.screenShare == true) {
              document.getElementById("Screen").style.display = "none";
              this.mainScreenStreamRef = null;
            }
          }
          this.manageDiv();
        });

        this.screenShareStarted = false;
      } else {
        const checkUsername = (obj) => obj.screenShare == true;

        if (
          (this.mainScreenStreamRef != null &&
            this.mainScreenStreamRef.remoteScreen == true) ||
          this.peers.some(checkUsername) == true
        ) {
          swal({
            text: "This will let you take over from as ther main presenter.",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              let obj = this.peers.find((o) => o.screenShare == true);

              if (obj) {
                this.socket.emit("stopShareScreen", obj.socketId);
                console.log("___obj----", obj);
                console.log("___obj.storageId----", obj.storageId);
                if (
                  document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV") != null
                ) {
                  this.peers.push({ is_show: true, ...obj });
                  // document.getElementById(obj.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                }
              } else {
                console.log("this.mainScreenStreamRef----", this.mainScreenStreamRef);
                console.log(
                  "this.mainScreenStreamRef.storageId----",
                  this.mainScreenStreamRef.storageId
                );
                this.socket.emit("stopShareScreen", this.mainScreenStreamRef.socketId);
                this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
                if (
                  document.getElementById(
                    this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV"
                  ) != null
                ) {
                  // document.getElementById(this.mainScreenStreamRef.storageId + "_STREAM_VIDEO_DIV").style.display = "flex";
                }

                console.log("socketId----", this.mainScreenStreamRef.socketId);
              }

              const localScreenStream = await navigator?.mediaDevices?.getDisplayMedia({
                audio: {
                  noiseSuppression: true,
                  echoCancellation: true,
                },
                video: true,
              });
              this.localScreenStreamRef = localScreenStream;
              // if (!this.localScreenStreamRef) return;

              // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
              const Audiotracks = this.localScreenStreamRef.getAudioTracks()[0];
              const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
              if (Audiotracks) {
                this.audioProducer2 = await this.produceTransport?.produce({
                  track: Audiotracks,
                  appData: {
                    type: "screen",
                  },
                  // encodings: [
                  //   {
                  //     rid: "r0",
                  //     scaleResolutionDownBy: 4,
                  //     maxBitrate: 500000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  //   {
                  //     rid: "r1",
                  //     scaleResolutionDownBy: 2,
                  //     maxBitrate: 750000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  //   {
                  //     rid: "r2",
                  //     scaleResolutionDownBy: 1,
                  //     maxBitrate: 1000000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  // ],
                });
              }
              if (Videotracks) {
                this.videoProducer2 = await this.produceTransport?.produce({
                  track: Videotracks,
                  appData: {
                    type: "screen",
                  },
                  // encodings: [
                  //   {
                  //     rid: "r0",
                  //     scaleResolutionDownBy: 4,
                  //     maxBitrate: 500000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  //   {
                  //     rid: "r1",
                  //     scaleResolutionDownBy: 2,
                  //     maxBitrate: 750000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  //   {
                  //     rid: "r2",
                  //     scaleResolutionDownBy: 1,
                  //     maxBitrate: 1000000,
                  //     scalabilityMode: "L1T3",
                  //   },
                  // ],
                });
              }
              if (this.mainScreenStreamRef == null) {
                document.getElementById("Screen").style.display = "flex";
                this.screenShareLoader = true;
              }
              if (this.mainScreenStreamRef) {
                this.updateIsShow(this.mainScreenStreamRef.storageId, true);
                document.getElementById("ALL_VIDEOS").style.display = "flex";
              }
              this.mainScreenStreamRef = {
                storageId: this.socket.id + "SCREEN",
                mediaStream: localScreenStream,
                remoteScreen: false,
                screenShare: true,
                socketId: this.socket.id,
                userDetails: {
                  user_name: this.user_name,
                  profileImg: this.profileImg,
                },
              };
              this.localScreenStreamRef
                .getVideoTracks()[0]
                ?.addEventListener("ended", () => this.ScreenShare());
              this.manageDiv();
              this.screenShareStarted = true;
              this.screenShareLoader = false;
            }
          });
        } else {
          const localScreenStream = await navigator?.mediaDevices?.getDisplayMedia({
            audio: {
              noiseSuppression: true,
              echoCancellation: true,
            },
            video: true,
          });
          this.localScreenStreamRef = localScreenStream;
          this.localScreenStreamRef.getVideoTracks()[0].contentHint = "motion";
          // if (!this.localScreenStreamRef) return;
          // this.$refs.localScreenStreamElemRef.srcObject = this.localScreenStreamRef
          const Audiotracks = this.localScreenStreamRef.getAudioTracks()[0];
          const Videotracks = this.localScreenStreamRef.getVideoTracks()[0];
          if (Audiotracks) {
            this.audioProducer2 = await this.produceTransport?.produce({
              track: Audiotracks,
              appData: {
                type: "screen",
              },
            });
          }
          if (Videotracks) {
            this.videoProducer2 = await this.produceTransport?.produce({
              track: Videotracks,
              appData: {
                type: "screen",
              },
            });
          }
          if (this.mainScreenStreamRef == null) {
            document.getElementById("Screen").style.display = "flex";
            this.screenShareLoader = true;
          }
          if (this.mainScreenStreamRef) {
            this.updateIsShow(this.mainScreenStreamRef.storageId, true);
            document.getElementById("ALL_VIDEOS").style.display = "flex";
          }
          this.mainScreenStreamRef = {
            storageId: this.socket.id + "SCREEN",
            mediaStream: localScreenStream,
            remoteScreen: false,
            screenShare: true,
            socketId: this.socket.id,
            userDetails: {
              user_name: this.user_name,
              profileImg: this.profileImg,
            },
          };

          this.localScreenStreamRef
            .getVideoTracks()[0]
            ?.addEventListener("ended", () => this.ScreenShare());
          this.screenShareStarted = true;
          this.screenShareLoader = false;
          // this.manageDiv()
        }
      }
    },
    async handleMic(state) {
      const stateVar = state ? state : this.currentMicState;
      console.log("this.currentMicState-----------", this.currentMicState);

      try {
        let userAgentString = navigator.userAgent;
        let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

        this.socket.emit(
          "handleProducer",
          {
            producerId: this.audioProducerId,
            state: stateVar,
          },
          async (response) => {
            if (this.currentMicState) {
              await this.audioProducer.pause();
              this.currentMicState = false;
              this.audio = false;
            } else {
              await this.audioProducer.resume();
              this.currentMicState = true;
              this.audio = true;
            }
          }
        );
      } catch (error) {
        if (error.name == "NotAllowedError") {
          $("#microphoneDeniedModal").modal("show");
        } else {
          this.is_cam_allow = false;
          $("#errorModal").modal("show");
          this.running_error = error;
        }
      }
    },
    async handleVideo(state) {
      const stateVar = state ? state : this.currentVideoState;
      console.log("this.currentVideoState-----------", this.currentVideoState);

      try {

        if (stateVar) {
          console.log("Video permissions:");

          this.socket.emit("closeProducer", this.videoProducerId, async (response) => {
            await this.videoProducer?.close();
            this.videoProducer = null;
            this.videoProducerId = null;

            // this.localStreamRef?.getTracks()?.forEach((track) => {
            //   track?.stop();
            // });
            // this.localStreamRef?.getVideoTracks()?.forEach((track) => {
            //   track?.stop();
            //   this.localStreamRef?.removeTrack(track);
            // });
          });
          //   this.socket.emit('handleProducer', {
          //   producerId: this.videoProducerId,
          //   state: true
          // }, async (response) => {
          //     await this.videoProducer.pause()
          // console.log('this.videoProducer==============',this.videoProducer);
          // })
          console.log("closeProducer-----------", this.localStreamRef);
          const tracks1 = this.localStreamRef.getTracks();
          console.log("tracks-----------", tracks1);
          this.localStreamRef?.getVideoTracks()?.forEach((track) => {
            track?.stop();
          });
          if (this.peerShow.length > 0) {
            var objIndex = this.peerShow.findIndex(
              (user) => user?.socketId == this.socket.id && user?.screenShare == false
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              const source = this.peerShow.find(
                (user) => user?.socketId == this.socket.id && user?.screenShare == false
              );
              //console.log("objIndex______", objIndex);
              if (source.mediaStream) {
                source.mediaStream.getVideoTracks().forEach(function (track) {
                  console.log("PS :)  ", track.stop());
                  track.stop();
                });
              }
              this.peerShow[objIndex].mediaStream = null;
              this.peerShow[objIndex].mutedVideo = true;
            }
          }
          this.currentVideoState = false;
          const socketId = this.socket.id;

          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              this.mainScreenStreamRef.screenShare == false
            ) {
              //console.log("objIndex______", objIndex);
              if (this.mainScreenStreamRef.mediaStream) {
                this.mainScreenStreamRef.mediaStream
                  .getVideoTracks()
                  .forEach(function (track) {
                    console.log("MS :)  ", track.stop());
                    track.stop();
                  });
              }
              this.mainScreenStreamRef.mediaStream = null;
              this.mainScreenStreamRef.mutedVideo = true;
            }
          }

          if (this.peers.length > 0) {
            const idx = this.peers?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );

            if (idx !== -1) {
              const source = this.peers.find(
                (user) => user?.socketId == this.socket.id && user?.screenShare == false
              );
              //console.log("objIndex______", objIndex);
              if (source.mediaStream) {
                source.mediaStream.getVideoTracks().forEach(function (track) {
                  console.log("P :)  ", track.stop());
                  track.stop();
                });
              }
              this.peers[idx].mediaStream = null;
              this.peers[idx].storageId = "LocalStream_storageId";
              this.peers[idx].mutedVideo = true;
            }
          }

          if (this.peersRef.length > 0) {
            const idx1 = this.peersRef?.findIndex(
              (peer) => peer?.socketId === socketId && peer?.screenShare === false
            );
            if (idx1 !== -1) {
              const source = this.peersRef.find(
                (user) => user?.socketId == this.socket.id && user?.screenShare == false
              );
              //console.log("objIndex______", objIndex);
              if (source.mediaStream) {
                source.mediaStream.getVideoTracks().forEach(function (track) {
                  track.stop();
                });
              }
              this.peersRef[idx1].mediaStream = null;
              this.peersRef[idx1].storageId = "LocalStream_storageId";
              this.peersRef[idx1].mutedVideo = true;
            }
          }
          this.socket.emit("closeCamera", {
            roomId: this.roomId,
            socketId: this.socket.id,
            status: true,
          });

          console.log({
            roomId: this.roomId,
            socketId: this.socket.id,
            status: false,
          });
          this.video = false;
          if (this.recordingRef) {
            this.resetVideoRec()
          }
          // videoElem.current.innerHTML = "Video On";
        } else {
          console.log("Video permissions:");
          let userAgentString = navigator.userAgent;
          let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

          if (this.selectedVideo != undefined) {
            const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
              video: {
                deviceId: { exact: this.selectedVideo },
              },
            });

            this.localStreamRef = localStreamRef;
          } else {
            const localStreamRef = await navigator?.mediaDevices?.getUserMedia({
              video: true,
            });
            const videoTracks = localStreamRef.getVideoTracks();
            if (videoTracks.length > 0) {
              this.is_cam_allow = true;

              const videoDeviceId = videoTracks[0].getSettings().deviceId;
              this.selectedVideo = videoDeviceId;
              console.log("Video device ID:", videoDeviceId);
            } else {
              console.log("No video tracks found in the stream.");
            }

            this.localStreamRef = localStreamRef;
          }

          this.videoProducer = await this.produceTransport?.produce({
            track: this.localStreamRef.getVideoTracks()[0],
            // encodings: [
            //   {
            //     rid: "r0",
            //     scaleResolutionDownBy: 4,
            //     maxBitrate: 500000,
            //     scalabilityMode: "L1T3",
            //   },
            //   {
            //     rid: "r1",
            //     scaleResolutionDownBy: 2,
            //     maxBitrate: 750000,
            //     scalabilityMode: "L1T3",
            //   },
            //   {
            //     rid: "r2",
            //     scaleResolutionDownBy: 1,
            //     maxBitrate: 1000000,
            //     scalabilityMode: "L1T3",
            //   },
            // ],
          });

          const socketId = this.socket.id;

          if (this.mainScreenStreamRef) {
            if (
              this.mainScreenStreamRef.socketId == socketId &&
              this.mainScreenStreamRef.screenShare == false
            ) {
              this.mainScreenStreamRef.mediaStream = this.localStreamRef;
              this.mainScreenStreamRef.mutedVideo = false;
            }
          }
          const idx = this.peers?.findIndex(
            (peer) => peer?.socketId === socketId && peer?.screenShare === false
          );

          if (idx !== -1) {
            this.peers[idx].mediaStream = this.localStreamRef;
            this.peers[idx].storageId = "LocalStream_storageId";
            this.peers[idx].mutedVideo = false;
          }
          const idx1 = this.peerShow?.findIndex(
            (peer) => peer?.socketId === socketId && peer?.screenShare === false
          );

          if (idx1 !== -1) {
            this.peerShow[idx1].mediaStream = this.localStreamRef;
            this.peerShow[idx1].storageId = "LocalStream_storageId";
            this.peerShow[idx1].mutedVideo = false;
          }
          this.socket.emit("closeCamera", {
            roomId: this.roomId,
            socketId: this.socket.id,
            status: false,
          });
          if (this.recordingRef) {
            this.resetVideoRec()
          }
          this.video = true;
          this.currentVideoState = true;
        }
      } catch (error) {
        if (error.name == "NotAllowedError") {
          $("#cameraDeniedModal").modal("show");

        } else if (error.name == "NotFoundError") {
          this.stremErr = "NotFoundError";
          $("#DeniedModalCamera").modal("show");
        } else {
          this.is_cam_allow = false;
          $("#errorModal").modal("show");
          this.running_error = error;
        }
        console.log("error.name", error.name + ": " + error.message);

        console.log(error);
      }
    },
    async handleLeave() {
      if (this.recordingToaster) {
        if (this.recordType == 'Local') {
          $("#LeaveLocalRecordingModal").modal("show");

        } else {
          this.stopCloudRecording();
          this.isActionForLeave = true;
        }
      } else {
        window.close();
        window.location.href = window.origin;
      }
    },
    async leaveWithStopLocalRec() {

      if (this.isActionForLeave) {
        window.close();
        window.location.href = window.origin;
      } else {
        this.stopCloudRecording();
        this.isActionForLeave = true;

      }
    },
    async microphoneDeniedModal() {
      $("#microphoneDeniedModal").modal("hide");
    },
    async closeerrorModalModal() {
      $("#closeerrorModalModal").modal("hide");
    },
    async DismissModal(name) {
      if (name == 'DeniedModal') {
        this.is_disable = true;
        this.role = "VIEWER";
      }

      if (name == 'LeaveLocalRecordingModal') {
        this.isActionForLeave = false;
      }
      this.isRecordLoader = false;
      $("#" + name).modal("hide");
    },
    async cameraDeniedModal() {
      $("#cameraDeniedModal").modal("hide");
    },
    async DeniedModal() {
      window.location.reload();
      // $("#DeniedModal").modal("hide");
    },
    async closeDeniedModal() {
      window.location.reload();
    },
    async gotToLogin() {
      this.$router.push("/login");
    },
    async closeNotReadableErrorModal() {
      window.location.reload();
      $("#NotReadableError").modal("hide");
    },
    async closeTypeErrorModal() {
      document.getElementById("BtnTypeError").innerHTML = "Please wait..";
      window.location.reload();
      $("#TypeError").modal("hide");
    },
    async removeUser(socketId) {
      this.socket.emit("removeUser", socketId);
    },
    async renameSubmitUser(socketId) {
      console.log("___socketId_", socketId);
      if (this.edit_name != "") {
        this.socket.emit("renameUser", {
          name: this.edit_name,
          socketId: socketId,
          roomId: this.roomId,
        });

        this.edit_socketId = "";
        this.edit_name = "";
        $("#RenamePopup").modal("hide");
      } else {
        this.edit_name_error = "Please enter new name.";
        this.edit_join_btn = true;
      }
    },
    async renameUser(peer, socketId) {
      console.log("_____2___", peer, socketId);
      console.log("___this.edit_socketId_____", this.edit_socketId);

      if (socketId == this.socket.id) {
        this.edit_name = this.user_name;
        this.edit_socketId = this.socket.id;
      } else {
        this.edit_socketId = socketId;
        this.edit_name = peer.userDetails ? peer.userDetails.user_name : peer.user_name;
      }

      console.log("___3_____", peer, socketId);
      $("#RenamePopup").modal("show");
    },
    async closeRenameModal() {
      this.edit_socketId = "";
      this.edit_name = "";
      $("#RenamePopup").modal("hide");
    },
    async clickToMiniMiseStream(peer) {
      // console.log("__", peer);
      // console.log("this.peerShow", this.peerShow);

      if (this.mainScreenStreamRef) {
        if (this.mainScreenStreamRef.screenShare == true) {
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.storageId == this.mainScreenStreamRef.storageId
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              // this.peers[objIndex].is_show = false;
            } else {
              this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
              // this.peerShow.push({ is_show: true, ...this.mainScreenStreamRef })
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex1 = this.peerShow.findIndex(
              (user) => user?.storageId == this.mainScreenStreamRef.storageId
            );

            //console.log("objIndex______", objIndex1);
            if (objIndex1 >= 0) {
              //console.log("objIndex______", objIndex1);
              // this.peers[objIndex].is_show = false;
            } else {
              // this.peers.push({ is_show: true, ...this.mainScreenStreamRef })
              this.peerShow.push({
                is_show: true,
                ...this.mainScreenStreamRef,
              });
            }
          }
        }
      }
      this.updateIsShow(this.mainScreenStreamRef.storageId, true);

      // this.mainScreenStreamRef = {...peer, mediaStream: video.srcObject};

      if (peer.screenShare == false) {
        if (this.peers.length > 0) {
          var objIndex = this.peers.findIndex(
            (user) => user?.socketId == peer.socketId && user?.screenShare == false
          );

          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peers[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
          }
        }
        if (this.peerShow.length > 0) {
          var objIndex = this.peerShow.findIndex(
            (user) => user?.socketId == peer.socketId && user?.screenShare == false
          );

          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peerShow[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
          }
        }
        if (this.peersRef.length > 0) {
          var objIndex = this.peersRef.findIndex(
            (user) => user?.socketId == peer.socketId && user?.screenShare == false
          );

          //console.log("objIndex______", objIndex);
          if (objIndex >= 0) {
            //console.log("objIndex______", objIndex);
            this.peersRef[objIndex].mediaStream = this.mainScreenStreamRef.mediaStream;
          }
        }
      }
      this.mainScreenStreamRef = null;

      // const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true)
      // this.peerShow = peerFilter;
      // console.log("this.peerShow", this.peerShow);

      this.manageDiv();
      document.getElementById("Screen").style.display = "none";

      if (peer.screenShare == false) {
        if (peer.mutedVideo == true && peer.screenShare == false) {
          console.log("IFFFFFF", peer.socketId);
          // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "9" : '';
          // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "10" : '';
        } else {
          console.log("ELSEEEEEEE", peer.socketId);

          // document.getElementById(peer.socketId + "STREAM_SRC") != null ? document.getElementById(peer.socketId + "STREAM_SRC").style.zIndex = "10" : '';
          // document.getElementById(peer.socketId + "_PEER_IMAGE") != null ? document.getElementById(peer.socketId + "_PEER_IMAGE").style.zIndex = "9" : '';
        }
      }
    },
    async clickToBigStream(peer) {
      this.manageDiv();

      if (peer.screenShare == true) {
        var video = document.getElementById(peer.socketId + "STREAM_SCR_SRC");
      } else {
        var video = document.getElementById(peer.socketId + "STREAM_SRC");
      }
      // console.log('video.srcObject----------', video.srcObject);
      //console.log("__this.mainScreenStreamRef_____", this.mainScreenStreamRef);
      //console.log("__", peer);
      if ($("#" + peer.storageId + "_VIDEO").css("visibility") == "hidden") {
        var visible = true;
        document.getElementById("Screen").style.background = "#black";

        // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
        //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
        // }
      } else {
        document.getElementById("Screen").style.background = "black";
        var visible = false;
        // if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
        //   document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display = "none";
        // }
      }

      if (
        document.getElementById(
          "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
        ) != null
      ) {
        document.getElementById(
          "localStream" + this.socket.id + "_STREAM_SCREEN_VIDEO"
        ).style.visibility = "visible";
      }

      if (this.mainScreenStreamRef) {
        if (visible == false) {
          if (
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
            ) != null
          ) {
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
            ).style.display = "flex";
          }
          // console.log("_____B____245____");
        } else {
          if (
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
            ) != null
          ) {
            document.getElementById(
              this.mainScreenStreamRef.storageId + "_SCREEN_VIDEO"
            ).style.display = "none";
          }
        }
        if (this.mainScreenStreamRef.screenShare == true) {
          if (this.peers.length > 0) {
            var objIndex = this.peers.findIndex(
              (user) => user?.storageId == this.mainScreenStreamRef.storageId
            );

            //console.log("objIndex______", objIndex);
            if (objIndex >= 0) {
              //console.log("objIndex______", objIndex);
              // this.peers[objIndex].is_show = false;
            } else {
              this.peers.push({ is_show: true, ...this.mainScreenStreamRef });
            }
          }
          if (this.peerShow.length > 0) {
            var objIndex1 = this.peerShow.findIndex(
              (user) => user?.storageId == this.mainScreenStreamRef.storageId
            );

            //console.log("objIndex______", objIndex1);
            if (objIndex1 >= 0) {
              //console.log("objIndex______", objIndex1);
              // this.peerShow[objIndex].is_show = false;
            } else {
              this.peerShow.push({
                is_show: true,
                ...this.mainScreenStreamRef,
              });
            }
          }
        }
        if (
          document.getElementById(
            this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
          ) != null
        ) {
          document.getElementById(
            this.mainScreenStreamRef.storageId + "_STREAM_SCREEN_VIDEO"
          ).style.visibility = "visible";
        }
        this.updateIsShow(this.mainScreenStreamRef.storageId, true);
        this.updateIsShow(peer.storageId, false);

        //console.log("AHIYA AAVYU 1");
      } else {
        if (document.getElementById(peer.storageId + "_SCREEN_VIDEO") != null) {
          document.getElementById(peer.storageId + "_SCREEN_VIDEO").style.display =
            "none";
        }
        //console.log("AHIYA AAVYU 2");
        if (document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null) {
          //console.log("AHIYA AAVYU 3222");
          // document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV").style.display = "none";
        }
      }

      this.updateIsShow(peer.storageId, false);

      // video.srcObject = mediaStream;
      this.mainScreenStreamRef = peer;

      if (document.getElementById(peer.storageId + "_STREAM_SCREEN_VIDEO") != null) {
        //console.log("AHIYA AAVYU 33333222fff");
        console.log(" exist 2", $("#Screen").css("display"));
      } else {
        //console.log("AHIYA AAVYU 4rrr");
        document.getElementById("Screen").style.display = "flex";
        if (document.getElementById(peer.storageId + "_STREAM_VIDEO_DIV") != null) {
          //console.log("AHIYA AAVYU 3rrrr");
        }
        //console.log(" offline slash does not exist! ", $("#Screen").css("display"));
      }
      if (document.getElementById("LOCAL_STREAM_VIDEO_DIV") != null) {
        document.getElementById("LOCAL_STREAM_VIDEO_DIV").style.display = "flex";
        document.getElementById("ALL_VIDEOS").style.display = "flex";
      }
      // this.screenShareStarted = true;
      // this.socket.emit('muteMic', socketId);
      this.manageDiv();
    },
    async updateIsShow(storageId, value) {
      //console.log("storageId,value______", storageId, value);
      if (this.peers.length > 0) {
        var objIndex = this.peers.findIndex((user) => user?.storageId == storageId);

        //console.log("objIndex______", objIndex);

        if (objIndex >= 0) {
          //console.log("objIndex______", objIndex);
          this.peers[objIndex].is_show = value;
        }
      }
      const peerFilter = this.peers.filter((peerData) => peerData?.is_show == true);
      //console.log("this.peers______", this.peers);
      // console.log("peerFilter______", peerFilter);
      this.peerShow = peerFilter;
      this.manageDiv();
    },
    async updateCamStream(socketId, stream) {
      if (this.mainScreenStreamRef) {
        if (
          this.mainScreenStreamRef.socketId == socketId &&
          !this.mainScreenStreamRef.screenShare
        ) {
          this.mainScreenStreamRef.mediaStream = stream;
        }
      }
      if (this.peers.length > 0) {
        var objIndex = this.peers.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        console.log("objIndex__::____", objIndex);
        if (objIndex >= 0) {
          console.log("objIndex__::2____", objIndex);
          this.peers[objIndex].mediaStream = stream;
        }
      }
      if (this.peerShow.length > 0) {
        var objIndex = this.peerShow.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        console.log("objIndex__::____", objIndex);
        if (objIndex >= 0) {
          console.log("objIndex__::3____", objIndex, stream);
          this.peerShow[objIndex].mediaStream = stream;
        }
      }
      if (this.peersRef.length > 0) {
        var objIndex = this.peersRef.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        //console.log("objIndex______", objIndex);
        if (objIndex >= 0) {
          console.log("objIndex__::4____", objIndex, stream);
          this.peersRef[objIndex].mediaStream = stream;
        }
      }
    },
    async updateCamStatus(socketId, status) {
      if (this.mainScreenStreamRef) {
        if (
          this.mainScreenStreamRef.socketId == socketId &&
          !this.mainScreenStreamRef.screenShare
        ) {
          this.mainScreenStreamRef.mutedVideo = status;
        }
      }
      if (this.peers.length > 0) {
        var objIndex = this.peers.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        //console.log("objIndex______", objIndex);
        if (objIndex >= 0) {
          //console.log("objIndex______", objIndex);
          this.peers[objIndex].mutedVideo = status;
        }
      }
      if (this.peerShow.length > 0) {
        var objIndex = this.peerShow.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        //console.log("objIndex______", objIndex);
        if (objIndex >= 0) {
          //console.log("objIndex______", objIndex);
          this.peerShow[objIndex].mutedVideo = status;
        }
      }
      if (this.peersRef.length > 0) {
        var objIndex = this.peersRef.findIndex(
          (user) => user?.socketId == socketId && user?.screenShare == false
        );

        //console.log("objIndex______", objIndex);
        if (objIndex >= 0) {
          //console.log("objIndex______", objIndex);
          this.peersRef[objIndex].mutedVideo = status;
        }
      }
    },
    async muteMic(socketId, remote, action) {
      console.log("muteMic______", socketId, remote);
      const userName = this.user_name;
      if (socketId == this.socket.id) {
        this.handleMic();
      } else {
        this.socket.emit("muteMic", { socketId, remote, action, userName });
      }
    },
    async muteVideo(socketId, remote, action) {
      console.log("muteVideo______", socketId, remote, action);

      const userName = this.user_name;
      if (socketId == this.socket.id) {
        this.handleVideo();
      } else {
        this.socket.emit("muteVideo", { socketId, remote, action, userName });
      }
    },
    async stopMicVoice() {
      this.audio = false;
      this.publisher.publishAudio(false);
    },
    async startMicVoice() {
      this.audio = true;
      this.publisher.publishAudio(true);
    },
    async stopVideo() {
      this.video = false;
      this.publisher.publishVideo(false);
    },
    async startVideo() {
      console.log("startVideo---", this.video);
      this.publisher.publishVideo(true);
      this.video = true;
    },
    async changeMic(deviceId) {
      console.log("deviceData-------", this.deviceData);
      console.log("deviceId-------", deviceId);
      // const devices = await navigator.mediaDevices.enumerateDevices()
      //Here We Get The First Device's Device Id
      const device = deviceId.deviceId;
      //Get That Device's Stream
      if (deviceId.kind === "videoinput" || deviceId.kind === "video") {
        try {
          const stream = await navigator?.mediaDevices?.getUserMedia({
            video: {
              // deviceId: device
              //This Will Get The Device If Available, If Not Then It Will Get Some other Device
              //To Get Only The Device With Device Id Use,
              deviceId: { exact: device },
            },
          });
          this.selectedVideo = deviceId.deviceId;
          //Sending New Track To Mediasoup
          await this.videoProducer?.replaceTrack({
            track: stream.getVideoTracks()[0],
            // encodings: [
            //   {
            //     rid: "r0",
            //     scaleResolutionDownBy: 4,
            //     maxBitrate: 500000,
            //     scalabilityMode: "L1T3",
            //   },
            //   {
            //     rid: "r1",
            //     scaleResolutionDownBy: 2,
            //     maxBitrate: 750000,
            //     scalabilityMode: "L1T3",
            //   },
            //   {
            //     rid: "r2",
            //     scaleResolutionDownBy: 1,
            //     maxBitrate: 1000000,
            //     scalabilityMode: "L1T3",
            //   },
            // ],
          });

          //Stop And Remove Previous Track From Local Stream
          this.localStreamRef?.getVideoTracks()?.forEach((track) => {
            track?.stop();
            this.localStreamRef?.removeTrack(track);
          });
          //Add New Track In Local Stream
          this.localStreamRef?.addTrack(stream.getVideoTracks()[0]);

          //Set That New Track In Video Element
          // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
        } catch (e) {
          if (error.name == "TypeError") {
            document.getElementById("BtnTypeError").innerHTML = "Try again";
            $("#TypeError").modal("show");
          }

          if (error.name == "NotReadableError") {
            document.getElementById("BtnNotReadableError").innerHTML = "Dismiss";
            $("#NotReadableError").modal("show");
            // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
          }
        }
      } else {
        try {
          const stream = await navigator?.mediaDevices?.getUserMedia({
            audio: {
              deviceId: device,
            },
            //This Will Get The Device If Available, If Not Then It Will Get Some other Device
            //To Get Only The Device With Device Id Use,
            //deviceId: {exact: device }
          });

          //Sending New Track To Mediasoup
          await this.audioProducer.replaceTrack({
            track: stream.getAudioTracks()[0],
          });

          //Stop And Remove Previous Track From Local Stream
          this.localStreamRef?.getAudioTracks()?.forEach((track) => {
            track?.stop();
            this.localStreamRef?.removeTrack(track);
          });
          //Add New Track In Local Stream
          this.localStreamRef?.addTrack(stream.getAudioTracks()[0]);
          this.selectedAudio = deviceId.deviceId;
          //Set That New Track In Video Element
          // this.$refs.localStreamElemRef.srcObject = this.localStreamRef;
        } catch (e) {
          if (error.name == "TypeError") {
            document.getElementById("BtnTypeError").innerHTML = "Try again";
            $("#TypeError").modal("show");
          }

          if (error.name == "NotReadableError") {
            document.getElementById("BtnNotReadableError").innerHTML = "Dismiss";
            $("#NotReadableError").modal("show");
            // swal("Another app is using your camera", 'We cannot access your camera. Close any apps (like Zoom or Teams) that might be using your camera. then refresh the page.');
          }
        }
      }
    },
    async changeOuterSpeaker(deviceId) {
      console.log("deviceData-------", this.deviceData);
      console.log("deviceId-------", deviceId);
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((s) => {
          console.log("s-------", s);
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
              console.log("device.label :", device);
            });
          });
        })
        .catch((error) => {
          console.log("Error :", error);
        });

      //Get That Device's Stream
      const sinkId = deviceId;
      if (!navigator.mediaDevices.selectAudioOutput) {
        console.log("selectAudioOutput() not supported.");
        // return;
      }
      // console.log('element-------', element);
      console.log("this.audioElement-------", this.audioElement);
      this.selectedOuterAudio = deviceId;

      if (this.audioElement.length > 0) {
        this.audioElement.forEach((item) => {
          const element = document.getElementById(item);
          console.log("element-------", element);
          console.log("element.sinkId-------", element.sinkId);
          if (typeof element.sinkId !== "undefined") {
            element
              .setSinkId(sinkId)
              .then(() => {
                console.log(`Success, audio output device attached: ${sinkId}`);
              })
              .catch((error) => {
                let errorMessage = error;
                if (error.name === "SecurityError") {
                  errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }
                console.log(errorMessage);
                // Jump back to first output device in the list as it's the default.
                // audioOutputSelect.selectedIndex = 0;
              });
          } else {
            console.warn("Browser does not support output device selection.");
          }
        });
      }
    },
    searchInputHandler(e) {
      console.error(`EEEEEEEEEEEEEEEEEEE: `, e);
      // e.preventDefault();
      return false;
    },
    async getFilterParticipate(type) {
      if (type == "VIEWER") {
        this.socket.emit("fetchViewerUser", { room: this.roomId });
        this.filterdParticipants = this.viewers;
      } else {
        this.filterdParticipants = this.peerShow.filter(
          (a) => a.userDetails.webinarUserType == type
        );
      }
      console.log("type-------", type);
      console.log("this.peerShow-------", this.peerShow);
    },
    async MuteUser(email, mute, currentTab, userid) {
      document.getElementById("HANDLE_BUTTON_" + userid).style.display = "none";
      document.getElementById("UPDATING_" + userid).style.display = "flex";

      const body = {
        webinarId: this.webinarId,
        is_edit: 1,
        email: email,
        is_mute: mute,
      };
      await listupdateParticipants(body);

      var webinarParticipate = await webinarParticipateUserList(this.webinarId);

      if (webinarParticipate.data.code == 200) {
        this.participants = webinarParticipate.data.result.userId;
        this.filterdParticipants = webinarParticipate.data.result.userId.filter(
          (a) => a.webinarUserType == currentTab
        );
        document.getElementById("UPDATING_" + userid).style.display = "none";
        document.getElementById("HANDLE_BUTTON_" + userid).style.display = "block";
        // $('#UPDATING_' + userid).removeClass('box');
      }
    },
    async BannedUser(socketId) {
      // $('#UPDATING_' + userid).addClass('box');
      this.socket.emit("removeUser", socketId);
      // document.getElementById('HANDLE_BUTTON_' + userid).style.display = "none";
      // document.getElementById('UPDATING_' + userid).style.display = "flex";
      // const body = {
      //   webinarId: this.webinarId,
      //   is_edit: 1,
      //   email: email,
      //   is_ban: ban
      // }

      // await listupdateParticipants(body);

      // var webinarParticipate = await webinarParticipateUserList(this.webinarId);

      // if (webinarParticipate.data.code == 200) {
      //   this.participants = webinarParticipate.data.result.userId
      //   this.filterdParticipants = webinarParticipate.data.result.userId.filter(a => a.webinarUserType == currentTab);
      //   // $('#UPDATING_' + userid).removeClass('box');
      //   document.getElementById('UPDATING_' + userid).style.display = "none";
      //   document.getElementById('HANDLE_BUTTON_' + userid).style.display = "block";

      // }
    },
    chatInputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.sendMessage();
      }
    },
    hideRaiseHand(socketId) {
      this.socket.emit("hideRaideHandForUser", socketId);
    },
    raiseHand() {
      if (this.isRaiseHand) {
        this.isRaiseHand = false;
      } else {
        this.isRaiseHand = true;
      }

      var daTa = {
        user: this.user_name,
        roomId: this.roomId,
        socketId: this.socket.id,
        is_show: this.isRaiseHand,
      };
      this.socket.emit("raise_hand", daTa);
    },
    sendMessage() {
      var rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
      this.chatMessage = this.chatMessage.replace(
        rexp,
        "<a href='$1' target='_blank'>$1</a>"
      );

      var daTa = {
        message: this.chatMessage,
        profileImg: this.profileImg,
        user: this.user_name,
        roomId: this.roomId,
        socketId: this.socket.id,
      };
      this.socket.emit("chat", daTa);

      this.chatMessage = "";
    },
    makeid(length) {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
  },
};
</script>
<style>
/* Absolute Center Spinner */

.outer-circle {
  margin-bottom: 13px;
  width: 80px;
  height: 80px;
  border: solid 5px white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: rotateCircle 1s linear infinite;
  padding: 2px;
  display: table;

  .inner-circle {
    height: 80px;
    width: 80px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    animation: rotateCircle 1s linear infinite reverse;
    display: table;
    text-align: center;
  }

  .text {
    vertical-align: middle;
    display: table-cell;
    text-align: center;
  }
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  color: white;
  border: 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.presentingScreen {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

#fixed_popup {
  position: absolute;
  transform: translate3d(404px, 536px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}

#loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #444444;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.audio-icon-cont {
  position: relative;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.audio-icon {
  margin: auto 0;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  transform-origin: bottom;
  border-radius: 10px;
}

.audio-icon:nth-of-type(2) {
  animation-delay: -1s;
}

.audio-icon:nth-of-type(3) {
  animation-delay: -1.5s;
}

.animate-audio-icon {
  height: 100% !important;
  animation: bounce 1.2s ease infinite alternate;
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
  }

  30% {
    transform: scaleY(1);
  }

  60% {
    transform: scaleY(0.5);
  }

  80% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.6);
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker {
  /* -webkit-animation: flickerAnimation 0.5s infinite;
  -moz-animation: flickerAnimation 0.5s infinite;
  -o-animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite; */

  border: 3px solid #2d8cff !important;
}

.RecordSec {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 7px;
}

.recordAnimation {
  animation: record 1.5s ease infinite normal;
}

@keyframes record {
  10% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.disableTopIcon {
  pointer-events: none;
  cursor: no-drop;
  color: #9ca3af9c !important;
  background: #80808024;
  border-radius: 9px;
}

.disableTopIconMain {
  cursor: no-drop !important;
  /* background: #80808024; */
  border-radius: 9px;
}

.disableBottomIcon {
  pointer-events: none;
  cursor: no-drop;
}

.disableTopIconText {
  background: #9ca3af9c;
}

.disableTopIconSVG {
  fill: #9ca3af9c !important;
}

.play-button {
  cursor: pointer;
  z-index: 999999;
  background: black;
  border-radius: 50%;
}

.topRecordPanel {
  border-radius: 8px;
  margin: 5px;
  padding: 6px;
  border: 2px solid red;
  color: white;
}

.closeIconModal {
  border: 2px solid black;
  position: absolute;
  right: 12px;
  border-radius: 68px;
  padding: 4px 10px;
  margin: 0;
  cursor: pointer;
}

.modal-open {
  overflow: visible !important;
}

.mySpin {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}
</style>